import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CashPostingService } from '../service/cash-posting.service';
import { LedgerService } from '../service/ledger.service';
import { SharedService } from 'app/services/shared.service';
import { CashPosting } from '../model/cash-posting';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { combineLatest, Observable, of } from 'rxjs'; 
import { CustomerService } from 'app/customer/customer.service';
import { BusinessDate } from '../model/business-date';
import swal from 'sweetalert';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { PaymentService } from 'app/payment/payment.service';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { PaymentRequestVm } from 'app/payment/models/payment-request-vm';
import Swal from 'sweetalert2'
import { DatePipe,Location } from '@angular/common';
import { AccountClosures } from '../model/AccountClosures';

@Component({
  selector: 'app-account-closure',
  templateUrl: './account-closure.component.html',
  styleUrls: ['./account-closure.component.scss']
})
export class AccountClosureComponent implements OnInit {

  selectedOption: string;
  customerIdPayment: number;
  customerAccountId: number;
   currentBalance: number;
  isvalid: boolean = true;
  isBtnValid: boolean = false;
  form: FormGroup; 
  BDGenerateCode: string = "";
  customerCode: string = "";
  accountNo: any;
  AutoCompleate: any[] = [];
  leadId: any = "";
  customerInfo_cashPayment: any = "";
  customerInfo_cashReceive: any = "";
  customerInfo_cashFundTransfer1: any = "";
  customerInfo_cashFundTransfer2: any = ""; 
  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: CashPosting[] = [];
  model = new CashPosting(); 
  formControl = new FormControl();
  suggestions: any[] = [];
   hideShow: string = "Show";
  searchType: string = "";
  isAuto: boolean = false;
  applyDate: any;
  dueAmount: number;
  paymentName: string = "";
  AccountNoList: Observable<any[]>;
  AccountNoList1: Observable<any[]>;
  totalAdd: number;
  totalMoney: number;
  countryList$: Observable<any[]>;
  model2 = new AccountClosures();
  selectedTransactionType: string = 'cash';  
  netPayable: number;
  profit: number;
  taxonprofit: number;
  VAT: number;
  closingCharge: number;
  exciseDuty:number; 
  constructor(
    private router: Router,
    private _service: CashPostingService,
    private _LedgerService: LedgerService,
    private _CountryInfoService: CountryInfoService,
    private _CustomerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private _loanProductService: LoanProductService,
    private paymentService: PaymentService,
    private location: Location,
    private datePipe: DatePipe
  ) {
    {
      this.frmGroup = new FormGroup({
        id: new FormControl(),
        customerCode: new FormControl(),
        lienAmount: new FormControl(),
        provisionedProfit: new FormControl(),
        profit: new FormControl(),
        taxonprofit: new FormControl(),
        closingCharge: new FormControl(),
        VAT: new FormControl(),
        exciseDuty: new FormControl(),
        netPayable: new FormControl(),
        transactionType: new FormControl(),
        amount: new FormControl(),
      });
      this.frmGroup = this.fb.group({
       
        customerCode: [0, Validators.required],      
        lienAmount: [0, Validators.required],
        provisionedProfit: [0, Validators.required],
        profit: [0, Validators.required],
        taxonprofit: [0, Validators.required],
        closingCharge: [0, Validators.required],        
        VAT: [0, Validators.required],
        exciseDuty: [0, Validators.required],
        netPayable: [0, Validators.required],
        transactionType: ["cash", Validators.required],
         amount: [0, Validators.required],     
       
      });
       
    }
  } 


  ngOnInit(): void {
    this.GetCustomerAccountNo();
    this.loadLedgerList();
    this.calculateNetPayable(); 
    this.formControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((value) =>
          this._LedgerService.getAutocompleteSuggestions(value)
        )
      )
      .subscribe((data) => {
        this.suggestions = data; 
      });

    this.hideShow = "Show";
    this.GetBusinessDatebyActiveStatus();
    this.loadCountryList();
    this.countryList$ = this._CountryInfoService.getAllGetCurrencyInfo();

      this.currentBalance = 0;
      this.netPayable = 0;
     
  }

  GetCustomerAccountNo() {
    this._CustomerService.GetAllCustomerAccountNo().subscribe((res) => {
      this.AccountNoList = of(res);
    });
  }

  selectedSuggestion: any = null;
  gLGenerateId: any = null;
  AccountName: any = null;
  glAccountName: any;
  

  hideAfterSelectValue(value: any) {
    this.hideShow = value;
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  LedgerList: Observable<any[]>;
  loadLedgerList() {
    this._LedgerService.getMFSLedgerRelation().subscribe((res) => {
      this.LedgerList = of(res);
    });
  }

  countryList: Observable<any[]>;
  loadCountryList() {
    this._CountryInfoService.getAllGetCurrencyInfo().subscribe((res) => {       
      this.countryList =res;   
      console.log(this.countryList);    
    });
  }

  onsubmit() {
    debugger;
    if (this.isEdit) {
      this.model2.id = this.frmGroup.value.id;
    } else {
      this.model2.id = 0;
    }
   
    this.model2.customerAccountId = this.customerAccountId;
    this.model2.lienAmount = this.frmGroup.value.lienAmount;
    this.model2.provisionedProfit = this.frmGroup.value.provisionedProfit;
    this.model2.profit = this.frmGroup.value.profit;
    this.model2.taxonprofit = this.frmGroup.value.taxonprofit;
    this.model2.closingCharge = this.frmGroup.value.closingCharge;
    this.model2.VAT = this.frmGroup.value.VAT;
    this.model2.exciseDuty = this.frmGroup.value.exciseDuty;
    this.model2.netPayable = this.frmGroup.value.netPayable;
    this.model2.transactionType = this.frmGroup.value.transactionType;
    this.model2.amount = this.frmGroup.value.amount;
    

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to add Account Closure!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,Account Closure!",
    }).then((res) => {
      if (res.isConfirmed) {
        debugger;
          if (this.model2.id == 0) {
            this._service.SaveCustomerClosure(this.model2).subscribe((res: number) => {
              if (res > 0) {
                this.shared.ShowAlert("Success", "Save Successfully", "success");
                window.location.reload();
              }

            });
          } else {
            

            this._service.SaveCustomerClosure(this.model2).subscribe((res: number) => {
              window.location.reload();

            });
          }
        }
     
    });
  }

  formatNumber(value: string): string {
    const formattedValue = value
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  }

   
  refreshAc() {
    this.currentBalance = 0;
     
    this.customerInfo_cashPayment = ""; 
    this.customerIdPayment = 0;
    this.customerAccountId = 0;
    this.isBtnValid = false;
  }
  

  CheckBalance() {
    var inputAmount = parseInt(
      this.frmGroup.value.exchangeAmount.replace(/,/g, ""),
      10
    );

    this._CustomerService
      .getTransactionLimitByAccountId(this.accountNo)
      .subscribe((res) => {
        var inputValues = inputAmount;
        this.totalAdd = res.monthlyTRCount;

        this.totalMoney = res.monthlyTRTotalAmount;

        if (res.monthlyTRMaxAmount > inputValues) {
          var amounts = res.monthlyTRMaxAmount;
          this.shared.ShowAlert(
            "Warning",
            "Amount must be more than " + amounts,
            "warning"
          );
        } else if (res.monthlyTRTotalAmount < inputValues) {
          this.shared.ShowAlert(
            "Warning",
            "Amount is more than the transaction limit.",
            "warning"
          );
        }
      });

    this._CustomerService
      .getTransactionCountBalanceId(this.accountNo, 2)
      .subscribe((res) => {
        var inputValues = inputAmount;

        if (this.totalMoney > res.totalAmount) {
          this.shared.ShowAlert(
            "Warning",
            "Transaction limit is over.",
            "warning"
          );
        }

        if (this.totalAdd === res.transactionCount) {
          this.shared.ShowAlert(
            "Warning",
            "You already cross your add money as per declearation.",
            "warning"
          );
        }

        // else if (res.TotalAmount < inputValues) {
        //   this.shared.ShowAlert("Warning", "Amount is more than the transaction limit.", "warning");
        // }
      });
  }

  loadUserDataByAccountNoCashPayment(accountId: number) {
    this._CustomerService
      .GetCustomerInfoByAccountNoById(accountId)
      .subscribe((res) => {
        console.log(res)
        const applyDate = this.datePipe.transform(res.applyDate, 'yyyy-MM-dd');
        if (res != null) {
          if (res.glAccountName == null) {
            if(res.currentBalance != null){
              this.currentBalance = res.currentBalance;
              this.netPayable = res.currentBalance;
            }
            else{
              this.currentBalance = 0;
              this.netPayable = 0;
            }
             
            this.customerInfo_cashPayment =
              res.customerNameEng + "; " + res.mobileNo + "; " + res.nidNo;
            this.customerIdPayment = res.customersId;
            this.customerAccountId = res.id;
            this.isBtnValid = true;
            this.glAccountName = "";
            this.applyDate= applyDate;
            this.leadId = res.leadId; 
            this.calculateNetPayable(); 
            this. ResetForm2();
          } else {
            this.glAccountName = res.glAccountName;
            this.applyDate= res.applyDate;
         
            this.customerInfo_cashPayment = "";
            this.isBtnValid = true;
          }
        } else {
          this.refreshAc();
          swal("Warning", "Invalid Account No", "warning");
        }
      });
   
  }

  ResetForm() {
    this.frmGroup.reset();

    this.customerInfo_cashPayment = "";
    //this.customerInfo_cashReceive = '';
    //this.customerInfo_cashFundTransfer1 = '';
    //this.customerInfo_cashFundTransfer2 = '';
  }


  ResetForm2() {
     this.profit = 0;
    this.taxonprofit = 0;
    this.closingCharge =0;
    this.VAT =0;
    this.exciseDuty =0;
    this.netPayable =0; 
  }
  //#endregion
  GetBusinessDatebyActiveStatus() {
    this._LedgerService.GetBusinessDatebyActiveStatus().subscribe((res) => {
      if (res != null) {
        this.isAuto = true;
        this.frmGroup.patchValue({
          businessDateId: res.id,
          businessDate: this.datePipe.transform(
            res.business_Date,
            "yyyy-MM-dd"
          ),
        });
      } else {
        this.frmGroup = this.formBuilder.group({
          businessDateId: "",
        });
      }
    });
  }
  goBack() {
    this.location.back();
  }


  showChequeNo: boolean = false;

  cheque() {
    this.showChequeNo = true;  
  }

  cash() {
    this.showChequeNo = false;  
  }
  onVoucherAmountInput(event: any): void {
    let inputValue = event.target.value;
     let numericValue = inputValue.replace(/,/g, '');  
     let formattedValue = this.formatNumberWithCommas(numericValue);
    this.frmGroup.get('voucherAmount')?.setValue(formattedValue, { emitEvent: false });
  }
  
  formatNumberWithCommas(value: string): string {
    let num = value.replace(/,/g, ''); // Remove commas if any
    let [integer, decimal] = num.split('.'); // Split into integer and decimal part
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
    return decimal ? `${integer}.${decimal}` : integer; // Reattach decimal part if any
  }
   

  calculateNetPayable(): void {
    const profit = this.frmGroup.get('profit')?.value || 0;     
    const taxonprofit = this.frmGroup.get('taxonprofit')?.value || 0;
    const closingCharge = this.frmGroup.get('closingCharge')?.value || 0;
    const VAT = this.frmGroup.get('VAT')?.value || 0;
    const exciseDuty = this.frmGroup.get('exciseDuty')?.value || 0;

    const deductions = taxonprofit + closingCharge + VAT + exciseDuty;
    const netPayable = this.currentBalance + profit - deductions;

    this.frmGroup.get('netPayable')?.setValue(netPayable, { emitEvent: false });
  }
  
}
