import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;


@Injectable({
  providedIn: 'root'
})
export class DistrictService {


  constructor(private http: HttpClient) { }

  save(model: any) {


    return this.http.post(dcbsUrl + '/api/District/SaveDistrict', model);

  }

  getlist(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/District/GetAllDistrict');
  }
  getDistrictNamelist(districtName:string): Observable<any> {

    return this.http.get<any>(dcbsUrl + '/api/District/GetAllDistrictByDistrictName?districtName=' + districtName);

  }

  getAllDistrictWithPaginationSearch(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/District/GetAllDistrictWithPaginationSearch?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }
  GetAllCountry(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/Country/GetAllCountry');
  }
  GetDivisionListByCountryId(cId: number) {

    return this.http.get(dcbsUrl + '/api/Division/GetDivisionListByCountryId?cId=' + cId);
  }
  delete(id:any){

    return this.http.delete(dcbsUrl + '/api/District/Delete/' + id);
  }

  getDistrictByDivisionId(divId:number){
    return this.http.get(dcbsUrl+ '/api/District/GetDistrictsByDivisionId?divId=' + divId);
  }
  getDistrictByDivisionId2(divId:number){
    return this.http.get(dcbsUrl+ '/api/District/GetDistrictsByDivisionId?divId=' + divId);
  }

}
