import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;


@Injectable({
  providedIn: 'root'
})
export class DivisionService {

  constructor(private http: HttpClient) { }

  save(model: any) {

    return this.http.post(dcbsUrl + '/api/Division/SaveDivision', model);

  }

  getlist(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/Division/GetAllDivision');
  }

  GetAllCountry(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/Country/GetAllCountry');
  }
  getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/Division/GetAllDivisionWithPaginationSearch?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }

  delete(id: any) {

    return this.http.delete(dcbsUrl + '/api/Division/Delete/' + id);
  }

  saveSectorInfo(model: any){

    return this.http.post(dcbsUrl + '/api/SectorInfo/saveupdatesectorInfo',model);
  }
  GetAllSectorInfolist(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/SectorInfo/GetAllSectorInfolist?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }

  GetAllCustomerTypelist(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/CustomerType/GetAllCustomerTypelist?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  } 
  saveCustomerInfo(model: any){

    return this.http.post(dcbsUrl + '/api/CustomerType/saveCustomerType',model);
  }

  GetAllSectorInfo():Observable<any>
  {
       return this.http.get(dcbsUrl + '/api/SectorInfo/GetAllSectorInfo');
  }

  DeletesectorInfoById(Id:any){

    return this.http.delete(dcbsUrl +'/api/SectorInfo/DeletesectorInfoById?Id=' +Id);
  }

  ////


  saveupdateCustomerClass(model: any){

    return this.http.post(dcbsUrl + '/api/CustomerClass/saveupdateCustomerClass',model);
  }
  GetAllCustomerClasslist(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/CustomerClass/GetAllCustomerClasslist?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }



  GetAllCustomerClassInfo():Observable<any>
  {
       return this.http.get(dcbsUrl + '/api/CustomerClass/GetAllCustomerClassInfo');
  }

  DeleteCustomerClassById(Id:any){

    return this.http.delete(dcbsUrl +'/api/CustomerClass/DeleteCustomerClassById?Id=' +Id);
  }

  saveCustomerType(model: any){

    return this.http.post(dcbsUrl + '/api/CustomerType/saveCustomerType',model);
  }
  DeleteCustomerTypeById(Id:any){

    return this.http.delete(dcbsUrl +'/api/CustomerType/DeleteCustomerTypeById?Id=' +Id);
  }
  // GetAllSectorInfo():Observable<any>
  // {
  //      return this.http.get(dcbsUrl + '/api/SectorInfo/GetAllSectorInfo');
  // }


  GetAllCustomerTypeInfo():Observable<any>
  {
       return this.http.get(dcbsUrl + '/api/CustomerType/GetAllCustomerType');
  }
  //#region InstitutionType
  SaveInstitutionTypeInfo(model: any){

    return this.http.post(dcbsUrl + '/api/InstitutionType/saveupdateInstitutionType',model);
  }
  GetAllInstitutionTypeList(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/InstitutionType/GetAllInstitutionTypeList?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }



  GetAllInstitutionType():Observable<any>
  {
       return this.http.get(dcbsUrl + '/api/InstitutionType/GetAllInstitutionType');
  }

  DeleteInstitutionTypeById(Id:any){

    return this.http.delete(dcbsUrl +'/api/InstitutionType/DeleteInstitutionTypeById?Id=' +Id);
  }

  //#endregion

  //#region Currency Info
  SaveCurrencyInfo(model: any){

    return this.http.post(dcbsUrl + '/api/CurrencyInfo/saveupdateCurrencyInfo',model);
  }
  GetAllCurrencyInfoList(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/CurrencyInfo/GetAllInstitutionTypeList?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }

  GetAllCurrencyInfo():Observable<any>
  {
       return this.http.get(dcbsUrl + '/api/CurrencyExchangeRate/GetAllCurrencyInfo');
  }

  DeleteCurrencyInfoById(Id:any){

    return this.http.delete(dcbsUrl +'/api/CurrencyInfo/DeleteCurrencyInfoById?Id=' +Id);
  }

  //#endregion

  //institute type in fo
  saveInstituteInfo(model: any){

    return this.http.post(dcbsUrl + '/api/InstituteInfo/saveInstituteInfo',model);
  }

  GetAllInstituteInfolist(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/InstituteInfo/GetAllInstituteInfolist?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }

  GetAllInstituteInfo():Observable<any>
  {
       return this.http.get(dcbsUrl + '/api/InstituteInfo/GetAllInstituteInfo');
  }

  GetInstitutionInfoById(id: number): Observable<any> {
    return this.http.get(dcbsUrl + "/api/InstituteInfo/GetInstituteInfoById?id=" + id
    );
  }
  GetInstitutionInfoCode(id: number): Observable<any> {
    return this.http.get(dcbsUrl + "/api/InstituteInfo/GetInstituteCode?id=" + id
    );
  }
  GetAllInstituteType():Observable<any>
  {
       return this.http.get(dcbsUrl + '/api/InstitutionType/GetAllInstitutionType');
  }

  DeleteInstituteInfoById(Id:any){

    return this.http.delete(dcbsUrl +'/api/InstituteInfo/DeleteInstituteInfoById?Id=' +Id);
  }
  //end region

}
