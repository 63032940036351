<div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <h5 class="card-title" style="color: black">Currency Exchange Rate</h5>
          <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary" (click)="onClickShowSaveForm('saveList')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
        </div>
      </div>
      <hr>
      <form [formGroup]="frmGroup">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label for="" class="col-sm-4">Currency</label>
              <div class="col-sm-8">
                <select class="form-control" formControlName="CurrencyId" (change)="getCurrencyById($event.target.value)">
                    <option value="">Select</option>
                    <option *ngFor="let item of CurrencyInfosList" [value]="item.id">{{item.name}}</option>
                  </select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Base Currency Code</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="BaseCurrencyCode" [(ngModel)]="CurrencyCode" readonly>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">Rate </label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="Rate">
                  </div>
                </div>
              </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="" class="col-sm-4 col-form-label">Date</label>
              <div class="col-sm-8">
                <input type="date" class="form-control" formControlName="Date" placeholder="Date">
              </div>
            </div>
          </div>
          
        </div>
        <button data-toggle="tooltip" title="Save" type="button" (click)="onsubmit()" class="btn btn-success" style="float:right; margin-top:5px;">Save </button>
      </form>
    </div>
  </div>
  <section>
    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveList'">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title" style="color: black">Currency Exchange Rate List</h5>
            <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary" (click)="onClickShowSaveForm('saveForm')" style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control" style="width:16%">
              <option value="5">5</option>
              <option selected value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="form-group col-md-6" style="text-align: right">
            <input type="text" class="form-control" [(ngModel)]="searchControl" placeholder="Search" (keyup)="onSearch()">
          </div>
        </div>
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th>SL No.</th>
              <th>Currency</th>
              <th>Base Currency Code </th>
              <th>Rate</th>
              <th>Date </th>
              <th style="width: 20%;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of list | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
              <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
              <td>{{item.currency.name }}</td>
              <td>{{item.baseCurrencyCode}}</td>
              <td>{{item.rate}} %</td>
              <td>{{item.date | date:'yyyy-MM-dd' }}</td>
              <td style="width: 20%;">
                <button class="btn btn-primary" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                <button [disabled]="isEdit" class="btn btn-danger" style="margin-left: 5px;" (click)="delete(item.id)">
                  <i class="fa fa-trash-o 3x"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-6" style="text-align: left">
            <span>Showing {{currentItems}} of {{totalItems}} entries</span>
          </div>
          <div class="col-md-6" style="text-align: right">
            <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </section>