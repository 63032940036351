import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CustommeraccountService } from '../custommeraccount.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/internal/Observable';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { Customer } from 'app/customer/model/customer.model';
import { ProductBuilder } from 'app/models/deposit/product-builder/product-builder';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { PaymentService } from 'app/payment/payment.service';
import { PaymentRequestVm } from 'app/payment/models/payment-request-vm';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { OccupationService } from 'app/master-data/basic/basic-service/occupation.service';
import { Occupation } from 'app/master-data/basic/basic-model/occupation';
import { Customeraccount } from '../model/customeraccount';
import { LayoutService } from 'app/shared/services/layout.service';
import { th } from 'date-fns/locale';
import { RelationServiceService } from 'app/master-data/basic/basic-service/relation-service.service';
import { id } from '@swimlane/ngx-datatable';
import { CustomerService } from 'app/customer/customer.service';
import { LeadGenerationServiceService } from 'app/loan-application/lead-generation-service.service';
import { DocumentMetadata } from 'app/loan-application/model/document-metadata';
import { environment } from 'environments/environment';
import { Location } from "@angular/common";
import { OpenAccount } from 'app/customer/model/openAccount.model';
import { turn } from 'core-js/core/array';
import { FiscalyearService } from 'app/master-data/basic/basic-service/fiscalyear.service';
const apiUrlDoc = environment.baseUrl + '/api/LoanApplication/';
@Component({
  selector: "app-customer-account",
  templateUrl: "./customer-account.component.html",
  styleUrls: ["./customer-account.component.scss"],
})
export class CustomerAccountComponent implements OnInit {
  isMinor: boolean = false;
  dueAmount: number = 500.0;
  totalShare: number = 0;
  productId: any;
  accountTypeId: any;
  IncomeSourceId: any = 1;
  selectedValue: any;
  customerlist: Customer[] = [];
  accountTypelist: any[] = [];
  productlist: any[] = [];
  IncomeSourceList: Observable<any[]>;
  model: any = {};
  isEdit: boolean = false;
  frmGroup: FormGroup;
  nomineeGroup: FormGroup;
  IncomeGroup: FormGroup;
  chargeGroup: FormGroup;
  nolineeList: any[] = [];
  IncomeList: any[] = [];
  occupationList: Occupation[] = [];
  declearationList: any[] = [];
  decSelectList1: any[] = [];
  decSelectList2: any[] = [];
  riskGradingList: any[] = [];
  nominee: any = {};
  customerAccountNo: any;
  ledgerRelationId: any;
  ledgerId: any;
  ledgerRelationName: string;
  relationName: string;
  relationId: number;
  accountNo: string = "";
  closeResult: string;
  //relation: any;
  CurrencyInfosList: any[] = [];
  selectedIdentifier: string = "1";
  nomineeNID: string = "";
  nomineeBirthIdentity: string = "";
  nomineePassport: string = "";
  nomineeETin: string = "";
  sourceId: number;
  sourceName: string;
  frequency: number;
  monthlyIncomeSource: number;
  vActive = "top"; // Vertical Pills
  active = 1; // Basic Navs
  kActive = "Customer Info"; // Keep content
  disabled = true;
  photo: string = "";
  tabs = [1, 2, 3, 4, 5];
  counter = this.tabs.length + 1;

  step1: boolean = false;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  step5: boolean = false;

  dataId: any[] = [];
  dataType: any[] = [];
  dataNumber: any[] = [];
  dataAmount: any[] = [];
  dataMax: any[] = [];
  relationList: Observable<any[]>;

  isAdmin: boolean = false;
  // currentUser: any;
  res: number = 0;

  message = "";
  backPreview = "";
  progress = 0;
  selectedFiles?: FileList;
  currentFile?: File;

  leadId: any = 0;
  LeadDocumentTypeList: any[] = [];
  list: any[] = [];

  incomeSource: any = {};

  /////////////////////////////////
  loader: boolean = false;
  AccountCharges: any;

  customerAccNo: string;
  chargeInfo: any[] = [];
  bankingCharge: any[] = [];
  //model = new OpenAccount();

  constructor(
    private _Fiscalservice: FiscalyearService,
    private _service: CustommeraccountService,
    private _productService: ProductBuilderService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private shared: SharedService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private paymentService: PaymentService,
    private _occupationService: OccupationService,
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private relationSvc: RelationServiceService,
    private _customerService: CustomerService,
    private location: Location,
    private _leadGennarationService: LeadGenerationServiceService
  ) {
    this.nomineeGroup = this.formBuilder.group({
      photo: [""],
    });
  }

  ngOnInit(): void {
    this.GetBankingService();
    this.populateForm();
    this.populateNomineeForm();
    this.populateChargeForm();
    this.getCustomerListInfo();
    this.getAccountTypeList();
    //this.getProductList();
    this.getDeclearationList();
    this.getRiskGradingList();
    //this.loadLedgerList();
    //this.getCurrentUserRole();
    this.LoadRelationList();
    //this.GetAccountNo(1);
    this.getIncomeSourceList();
    this.populateIncomeForm();
    this.GetAllCurrencyInfosList(); 
  }

  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      CustomersInfoId: [null],
      CustomersInfoNumber: [null],
      ProductBuilderId: ["0"],
      IncomeSource: [""],
      NomineeInfoSId: [null],
      //OpeningBalance: [null],
      OpeningBalance: [
        "",
        [Validators.required, Validators.pattern("^[0-9,.]*$")],
      ],
      AccountTilte: [""],
      AccountTilteBn: [""],
      ledgerRelationId: [null],
      ledgerId: [null],
      customerAccountNo: [null],
      ledgerRelationName: [null],
      customersInfo: [null],
      CustomersId: [null],
      accountCurrency: [null],
      accountPurpose: [""],
      accountMinor: [""],
      accountMinorName: [""],
      accountMinorDOB: [""],
      accountMinorGuardian: [""],
      accountMinorGuardianRelation: [""],
      // riskGrading1: [''],
      //monthlyIncome: [null],
      accountTypeId: ["0"], // Default value to show "Select"

      monthlyIncome: ["", [Validators.pattern("^[0-9,.]*$")]],
      monthlyIncomeSource: [
        "",
        [Validators.required, Validators.pattern("^[0-9,.]*$")],
      ],
      sourceName: [null],

      relationName: [null],
    });
  }
  populateChargeForm() {
    this.chargeGroup = this.formBuilder.group({
      Id: [0],
      customerAccNo: [""],
      //accountTypeId: [""],
      chargeTypeId: ["null"],
      customerId: ["null"],
      customerNameEn: [""],
      chargeInfo: this.chargeInfo,
      bankingCharge: this.bankingCharge
    });
  }

  ///////////////////
  // GetChargeMasters() {
  //   this._customerService
  //     .GetChargeMasters(this.model.accountTypeId)
  //     .subscribe((res) => {
  //       this.AccountCharges = res;
  //     });
  // }

  // GetChargeDetails(event: Event): void {
  //      const selectedProductId = (event.target as HTMLSelectElement).value;
  //      this.frmGroup.patchValue({ ProductBuilderId: selectedProductId });
  //      this.productId = selectedProductId;
  //      alert(this.productId);
  //   this._customerService.GetChargeDetails(this.productId).subscribe((res) => {
  //     this.chargeDetails = res;
  //     this.model.chargeDetails = res;
  //   });
  //   this.GetAccountNo(this.productId);
  // }


  GetAllCurrencyInfosList() {
    this._Fiscalservice.GetAllCurrencyInfos().subscribe((res: any) => {
      this.CurrencyInfosList = res;
    
    })
  }




  addCurrencyList: any[] = [];
  isExists:boolean=false;
  selectedCurrencyId: number = null;
  onCurrencyChange(event: any) {
   this.selectedCurrencyId = event.target.value; 
 } 
 addCurrency() {
  // Convert selectedCurrencyId to a number if it's a string
  const currencyIdAsNumber = +this.selectedCurrencyId; // or parseInt(this.selectedCurrencyId, 10);
  
  // Check if a currency is selected
  if (!currencyIdAsNumber) {
    Swal.fire({
      icon: 'warning',
      title: 'Oops...',
      text: 'Please select a allowed currency',
      confirmButtonText: 'OK'
    });
    return;
  }

  // Check if the currency is already added
  const isCurrencyExist = this.addCurrencyList.some(item => item.id === currencyIdAsNumber);
  if (isCurrencyExist) {
    Swal.fire({
      icon: 'warning',
      title: 'Oops...',
      text: 'This allowed currency is already added',
      confirmButtonText: 'OK'
    });
    return;
  }

  // Find selected currency from CurrencyInfosList
  const selectedCurrency = this.CurrencyInfosList.find(item => item.id === currencyIdAsNumber);
  if (selectedCurrency) {
    this.addCurrencyList.push({
      order: this.addCurrencyList.length + 1,
      id: selectedCurrency.id,
      name: selectedCurrency.code
    });
  }

 
  this.selectedCurrencyId = 0;
}



 removeCurrency(item: any) {
   this.addCurrencyList = this.addCurrencyList.filter(i => i !== item);
  
 }



 



  GetChargeDetails(event: Event): void {
    const selectedProductId = (event.target as HTMLSelectElement).value;
    this.frmGroup.patchValue({ ProductBuilderId: selectedProductId });
    this.productId = selectedProductId;
    this._customerService.GetProductCharges(this.productId).subscribe((res) => {

      this.chargeInfo = res;
       this.model.chargeInfo = res;
    });
    this.GetAccountNo(this.productId);
  }
  GenerateCustomerAccNo() {
    
    this._customerService.GenerateCustomerAccNoinfo().subscribe((res) => {
      this.customerAccNo = res?.accNo;
      this.model.customerAccNo = res?.accNo;
      console.log(this.customerAccNo);
    });
  }

  ////////////////////////////

  getCurrentUserRole() {
    this._service.GetUserRole().subscribe((userData: any) => {
      if (userData.roles.includes("Admin")) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
        (this.accountNo = userData.userName),
          // customersInfo:res.customerRCode+", "+res.customerNameEng+' - '+res.nidNo,
          this.CheckCustomerAccountByuser();
      }
      // Display username in an alert
      //alert("Logged in as: " + userData.userName);
    });
  }

  CheckCustomerAccountByuser() {
    this._service.CheckCustomerAccount().subscribe((resss: any) => {
      if (resss === 1) {
        this.res = resss;
        this.shared.ShowAlert(
          "Warning",
          "Your saving acoount already created !! Can not open more then one saving account.",
          "warning"
        );
        return;
      } else {
        this.GetAllCustomersByMobileNo();
      }
    });
  }
  GetAllCustomersByMobileNo() {
    this._service.GetAllCustomersByMobileNo(this.accountNo).subscribe((res) => {
      if (res != null) {
        //console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", res);
        this.frmGroup.patchValue({
          customersInfo:
            res.customerRCode + ", " + res.customerNameEng + " - " + res.nidNo,
             
          CustomersId: res.id,
          CustomersInfoId: res.id,          
          CustomersInfoNumber: this.accountNo,
          AccountTilte: res.customerNameEng,
          AccountTilteBn:
            res.customerNameBn == null
              ? res.CustomerNameEng
              : res.CustomerNameBn,
        });
      } else {
        swal("Warning", "Invalid Account No", "warning");
      }
    });
  }

  GetAccountNo(pId: any): void {
    this._service.GetAccountNo1(pId).subscribe((res: any) => {
      //this.accountsCode = res.accountCode;
      this.frmGroup.patchValue({
        customerAccountNo: res.code,
      });
    });

    this._productService.getLedgerByProductId(pId).subscribe((res22: any) => {
      this.frmGroup.patchValue({
        ledgerRelationId: res22.ledger?.groupId,
        ledgerRelationName:
          res22.ledger?.accountCode + "-" + res22.ledger?.accountName,
        ledgerId: res22.ledgerId,
        // ledgerRelationId: res22.accountGroupId,
        // ledgerRelationName: res22.accountGroup?.groupCode + '-' + res22.accountGroup?.groupName,
        accountCurrency: res22.currency?.currency,
      });
    });
  }

  getCustomerListInfo() {
    this._service.getCustomerList().subscribe((res) => {
      this.customerlist = res;
    });
  }
  GetBankingService() {
    this._customerService.GetBankingService().subscribe((res) => {
      this.bankingCharge = res;

     
    });
  }
  getOccupationList() {
    this._occupationService.getlist().subscribe((res) => {
      this.occupationList = res;
    });
  }

  getAccountTypeList() {
    this._productService.getAccountTypeInfoList2().subscribe({
      next: (res) => {
        this.accountTypelist = res;
        // Ensure accountTypeId is reset
        this.accountTypeId = "0";
      },
      error: (err) => {
        console.error("Failed to fetch account types:", err);
        // Optionally, show a user-friendly error message
      },
    });
  }

  // GetProductByAccountTypeId(event: Event) {
  //   const selectedValue = (event.target as HTMLSelectElement).value;
  //   this.accountTypeId = selectedValue;
  //   alert(this.accountTypeId);
  //   this._productService
  //     .GetProductByAccountTypeId(this.accountTypeId)
  //     .subscribe((res) => {
  //       this.productlist = res;
  //       // this.productlist = res.filter(
  //       //   (product) =>
  //       //     product.productCategoryId === 1 && product.id === this.productId
  //       // );
  //       // this.productId = this.productlist[0].id;
  //     });
  // }

  GetProductByAccountTypeId(event: Event): void {
    const selectedAccountTypeId = (event.target as HTMLSelectElement).value;
    this.frmGroup.patchValue({ accountTypeId: selectedAccountTypeId });
    this.accountTypeId = selectedAccountTypeId;
    if (selectedAccountTypeId) {
      this._productService
        .GetProductByAccountTypeId(this.accountTypeId)
        .subscribe((res) => {
          this.productlist = res;
        });
    } else {
      this.productlist = [];
      this.frmGroup.patchValue({ ProductBuilderId: "0" }); // Reset ProductBuilderId
    }
  }

  getProductList() {
    this._productService.getList().subscribe((res) => {
      this.productlist = res.filter(
        (product) =>
          product.productCategoryId === 1 && product.id === this.productId
      );
      this.productId = this.productlist[0].id;
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent, t: number) {
    if (t === 0 && changeEvent.nextId === 1) {
      this.kActive = "Customer Info";
    } else if (t === 0 && changeEvent.nextId === 2 && this.step1) {
      this.kActive = "Product Info";
    } else if (t === 0 && changeEvent.nextId === 3 && this.step2) {
      this.kActive = "Account Info";
    } else if (t === 0 && changeEvent.nextId === 4 && this.step3) {
      this.kActive = "Nominee Info";
    } else if (t === 0 && changeEvent.nextId === 5 && this.step4) {
      this.kActive = "Account Charge";
    }
    // else if (t === 0 && changeEvent.nextId === 5 && this.step4) {
    //   this.kActive = "Declearation";
    // }
    else if (
      t === 1 &&
      this.frmGroup.value.AccountTilte.length > 1 &&
      this.frmGroup.value.AccountTilteBn.length > 1 &&
      this.frmGroup.value.CustomersInfoId > 0 &&
      (this.IncomeGroup.value.sourceId || this.IncomeList.length > 0)
    ) {
      this.step1 = true;
      this.active = 2;
      this.kActive = "Product Info";
    } else if (
      t === 1 &&
      this.frmGroup.value.AccountTilte.length < 1 &&
      this.frmGroup.value.AccountTilteBn.length < 1 &&
      (this.frmGroup.value.CustomersInfoId < 1 ||
        this.frmGroup.value.CustomersInfoId === null) &&
      (this.step1 || this.IncomeList.length < 0)
    ) {
      this.step1 = false;
      swal("Warning", "Fill up all required feild", "warning");
    } else if (
      t === 2 &&
      (this.step2 ||
        (this.frmGroup.value.accountPurpose.length > 1 &&
          this.frmGroup.value.ProductBuilderId > 0))
    ) {
      this.step2 = true;
      this.active = 3;
      this.kActive = "Account Info";
    } else if (
      t === 2 &&
      (this.frmGroup.value.accountPurpose.length < 1 ||
        this.frmGroup.value.ProductBuilderId === "")
    ) {
      this.step2 = false;
      swal("Warning", "Fill up all required feild", "warning");
    } else if (
      t === 3 &&
      (this.step3 ||
        (this.frmGroup.value.OpeningBalance.replace(/,/g, "") > 1 &&
          !this.isMinor) ||
        (this.isMinor &&
          this.frmGroup.value.accountMinorName.length > 1 &&
          this.frmGroup.value.accountMinorDOB.length > 1 &&
          this.frmGroup.value.accountMinorGuardianRelation.length > 1 &&
          this.frmGroup.value.OpeningBalance.replace(/,/g, "") > 1))
    ) {
      this.step3 = true;
      this.active = 4;
      this.kActive = "Nominee Info";
    } else if (
      t === 3 &&
      ((!this.isMinor &&
        (this.frmGroup.value.OpeningBalance.replace(/,/g, "") === null ||
          this.frmGroup.value.OpeningBalance.replace(/,/g, "") < 1)) ||
        (this.isMinor &&
          (this.frmGroup.value.accountMinorName.length < 1 ||
            this.frmGroup.value.accountMinorDOB.length < 1 ||
            this.frmGroup.value.accountMinorGuardian.length < 1 ||
            this.frmGroup.value.accountMinorGuardianRelation.length < 1 ||
            this.frmGroup.value.OpeningBalance.replace(/,/g, "") === null ||
            this.frmGroup.value.OpeningBalance.replace(/,/g, "") < 1)))
    ) {
      this.step3 = false;
      swal("Warning", "Fill up all required feild", "warning");
    }

    // else if (
    //   t === 4 &&
    //   (this.step4 || this.nolineeList.length > 0) &&
    //   this.totalShare === 100
    // ) {
    //   this.active = 5;
    //   this.kActive = "Declearation";
    // }
    else if (
      t === 4 &&
      (this.step4 || this.nolineeList.length > 0) &&
      this.totalShare === 100
    ) {
      this.active = 5;
      this.kActive = "Account Charge";
    } else if (t === 4 && this.nolineeList.length < 1) {
      this.step4 = false;
      swal("Warning", "Fill up all required feild", "warning");
    } else if (t === 4 && this.totalShare != 100) {
      this.step4 = false;
      this.shared.ShowAlert(
        "Warning",
        "Total nominee shares should be 100",
        "warning"
      );
    } else {
      changeEvent.preventDefault();
    }
  }

  refresh() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

  editAccount(item: any) {
    this.isEdit = true;
    this.frmGroup.patchValue({
      id: item.Id,
      CustomersInfoId: item.CustomersInfoId,
      NomineeInfoSId: item.NomineeInfoSId,
      ProductBuilderId: item.ProductBuilderId,
      AccountTilte: item.AccountTilte,
      OpeningBalance: item.OpeningBalance,
      ledgerRelationId: item.ledgerRelationId,
      ledgerId: item.ledgerId,
      customerAccountNo: item.customerAccountNo,
    });
  }

  checkMinorOrNot(event: Event) {
    const target = event.target as HTMLInputElement;
    this.step3 = false;
    this.isMinor = target.checked;
  }

  //Nominee
  populateNomineeForm() { 
    this.nomineeGroup = this.formBuilder.group({
       
      nomineeNameSl: [0],
      nomineeName: [""], 
      nomineeDOB: [""],
      nomineePresentAddress: [""], 
      nomineeOccupation: [""],
      nomineeNID: [""], 
      nomineePassport: [""],
      nomineeBirthIdentity: [""],
      nomineeETin: [""], 
      nomineeShare: [
        null,
        [Validators.required, Validators.min(1), Validators.max(100)],
      ],
      relationId: [""],
      relationName: [""],
      relationName2: [""],

      photo: [""],
      backPreview: [""], 
    });
  }

  addNominee() {
    if (
      this.nomineeGroup.valid &&
      this.nomineeGroup.value.nomineeNameSl === 0 &&
      this.nomineeGroup.value.nomineeDOB &&
      (this.nomineeGroup.value.nomineePassport != null ||
        this.nomineeGroup.value.nomineeNID != null ||
        this.nomineeGroup.value.nomineeBirthIdentity != null ||
        this.nomineeGroup.value.nomineeETin != null ||
        this.nomineeGroup.value.nomineeDriveLinc != null)
    ) {
      let index = this.nolineeList.findIndex(
        (obj) => obj.nomineeNameSl === this.nomineeGroup.value.nomineeNameSl
      );

      if (
        this.nomineeGroup.value.nomineeBirthIdentity ||
        this.nomineeGroup.value.nomineePassport ||
        this.nomineeGroup.value.nomineeETin ||
        (this.nomineeGroup.value.nomineeNID &&
          (this.nomineeGroup.value.nomineeNID.length === 10 ||
            this.nomineeGroup.value.nomineeNID.length === 13 ||
            this.nomineeGroup.value.nomineeNID.length === 17))
      ) {
        this.totalShare =
          this.totalShare + this.nomineeGroup.value.nomineeShare;
        if (this.totalShare > 100) {
          this.totalShare =
            this.totalShare - this.nomineeGroup.value.nomineeShare;
          this.shared.ShowAlert(
            "Warning",
            "Total nominee shares should be 100",
            "warning"
          );
          return false;
        } else {
          this.nomineeGroup.value.nomineeNameSl = this.nolineeList.length + 1;
          this.nominee.nomineeName = this.nomineeGroup.value.nomineeName; 
          this.nominee.nomineeDOB = this.nomineeGroup.value.nomineeDOB;
          this.nominee.nomineePresentAddress =
            this.nomineeGroup.value.nomineePresentAddress; 
          this.nominee.nomineeOccupation =
            this.nomineeGroup.value.nomineeOccupation;
          this.nominee.nomineeNID = this.nomineeGroup.value.nomineeNID;
          this.nominee.nomineePassport =
            this.nomineeGroup.value.nomineePassport;
          this.nominee.nomineeBirthIdentity =
            this.nomineeGroup.value.nomineeBirthIdentity;
          this.nominee.nomineeETin = this.nomineeGroup.value.nomineeETin; 
          this.nominee.nomineeShare = this.nomineeGroup.value.nomineeShare;
          this.nominee.relationName = this.relationNameNew;
          this.nominee.relationId = this.nomineeGroup.value.relationId;
          this.nominee.photo = this.backPreview;
          this.nomineeGroup.value.relationName = this.relationNameNew;

          this.nolineeList.splice(index, 0);
          this.nolineeList.push(this.nomineeGroup.value);
          this.populateNomineeForm(); 
          this.backPreview = "";
        }
      } else {
        this.shared.ShowAlert(
          "Warning",
          " Nominee BirthId/PassportNo/ETin/nid Field Required. Nominee nid should be 10 or 13 or 17 digit",
          "warning"
        );
        return false;
      }
    } 
    
    else if (
      this.nomineeGroup.value.nomineeNameSl > 0 &&
      this.nomineeGroup.valid &&
      (this.nomineeGroup.value.nomineePassport != null ||
        this.nomineeGroup.value.nomineeNID != null ||
        this.nomineeGroup.value.nomineeBirthIdentity != null ||
        this.nomineeGroup.value.nomineeETin != null ||
        this.nomineeGroup.value.nomineeDriveLinc != null)
    ) {
      let index = this.nolineeList.findIndex(
        (obj) => obj.nomineeNameSl === this.nomineeGroup.value.nomineeNameSl
      );
      
      if (index !== -1) {
        this.nolineeList[index] = { ...this.nomineeGroup.value };
      }
    
          this.nominee.nomineeNameSl = this.nomineeGroup.value.nomineeNameSl;
          this.nominee.nomineeName = this.nomineeGroup.value.nomineeName; 
          this.nominee.nomineeDOB = this.nomineeGroup.value.nomineeDOB;
          this.nominee.nomineePresentAddress =
          this.nomineeGroup.value.nomineePresentAddress; 
          this.nominee.nomineeOccupation =
          this.nomineeGroup.value.nomineeOccupation;
          this.nominee.nomineeNID = this.nomineeGroup.value.nomineeNID;
          this.nominee.nomineePassport =
          this.nomineeGroup.value.nomineePassport;
          this.nominee.nomineeBirthIdentity =
          this.nomineeGroup.value.nomineeBirthIdentity;
          this.nominee.nomineeETin = this.nomineeGroup.value.nomineeETin; 
          this.nominee.nomineeShare = this.nomineeGroup.value.nomineeShare;
          this.nominee.relationName = this.relationNameNew;
          this.nominee.relationId = this.nomineeGroup.value.relationId;
          this.nominee.photo = this.backPreview;
          this.nomineeGroup.value.relationName = this.relationNameNew;  
          this.populateNomineeForm(); 
          this.backPreview = "";
    }
    
    else {
      swal("Warning", "Input All Required Feild", "warning");
    }
  }

  editNominee(obj: any) { 
    this.selectedIdentifier = obj.nomineePassport
    ? '3'
    : obj.nomineeBirthIdentity
    ? '2'
    : obj.nomineeETin
    ? '4'
    : obj.nomineeNID
    ? '1'
    : '';
     
    this.nomineeGroup.patchValue({
      nomineeNameSl: obj.nomineeNameSl,
      nomineeName: obj.nomineeName, 
      nomineeDOB: obj.nomineeDOB,
      nomineePresentAddress: obj.nomineePresentAddress,  
      nomineeShare: obj.nomineeShare,
      nomineeNID: obj.nomineeNID,
      nomineePassport: obj.nomineePassport,
      nomineeBirthIdentity: obj.nomineeBirthIdentity,
      relation: obj.relation,
      photo: obj.photo,
      nomineeETin:obj.nomineeETin, 
      relationId: obj.relationId, 
      relationName : obj.relationName,  
    });
    this.backPreview = obj.photo || ""; 
  }


  selectNidBackPic(event: any): void {
    this.message = "";
    this.backPreview = "";
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.backPreview = "";
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.backPreview = e.target.result  as string;
          if (this.nomineeGroup?.controls?.photo) {
            this.nomineeGroup.patchValue({ photo: this.backPreview });
          }
         // this.nomineeGroup.patchValue({ photo: this.backPreview }); 
          this.changeDetectorRef.detectChanges();
          const div = document.getElementById("imageBackPreview");
          // div.style.width = 90 + '%';
          // div.style.height = 90 + '%';
        };
        reader.readAsDataURL(this.currentFile);
      }
    }
  }
   

  deleteNominee(obj: any) {
    var liIndex = this.nolineeList.findIndex(
      (li) => li.nomineeNameSl === obj.nomineeNameSl
    );
    this.totalShare = this.totalShare - this.nolineeList[liIndex].nomineeShare;
    this.nolineeList.splice(
      this.nolineeList.findIndex(
        (li) => li.nomineeNameSl === obj.nomineeNameSl
      ),
      1
    );
  }


  // getNomineeIdentifier(value: string) {
  //   this.selectedIdentifier = value;
  // }


  getNomineeIdentifier(value: string) {
    this.selectedIdentifier = value;
  
     
    if (value === '1') {
      this.nomineeGroup.patchValue({
        nomineeBirthIdentity: '',
        nomineePassport: '',
        nomineeETin: '',
      });
    } else if (value === '2') {
      this.nomineeGroup.patchValue({
        nomineeNID: '',
        nomineePassport: '',
        nomineeETin: '',
      });
    } else if (value === '3') {
      this.nomineeGroup.patchValue({
        nomineeNID: '',
        nomineeBirthIdentity: '',
        nomineeETin: '',
      });
    } else if (value === '4') {
      this.nomineeGroup.patchValue({
        nomineeNID: '',
        nomineePassport: '',
        nomineeBirthIdentity: '',
      });
    }
  }
  
  //End Nominee
  // Delearation
  getDeclearationList() {
    this._service.GetDeclearationList().subscribe((res) => {
      res.forEach((element) => {
        this.declearationList.push(element);
      });
      res.forEach((element1) => {
        element1.depositNumber1 = 0;
        element1.depositAmount1 = 0;
        element1.depositMaxAmount1 = 0;
        this.decSelectList1.push(element1);
      });
      res.forEach((element2) => {
        element2.depositNumber2 = 0;
        element2.depositAmount2 = 0;
        element2.depositMaxAmount2 = 0;
        this.decSelectList2.push(element2);
      });
    });
  }
  updateDeclearationList(id: number, p: number, event: any) {
    let index = this.decSelectList1.findIndex((obj) => obj.id === id);
    const updatedValue = event.target.value;
    const fullObject = this.decSelectList1[index];
    if (p === 1) {
      fullObject.depositNumber1 = updatedValue;
      fullObject.depositAmount1 =
        fullObject.depositAmount1 == undefined ? 0 : fullObject.depositAmount1;
      fullObject.depositMaxAmount1 =
        fullObject.depositMaxAmount1 == undefined
          ? 0
          : fullObject.depositMaxAmount1;
    } else if (p === 2) {
      fullObject.depositAmount1 = updatedValue;
      fullObject.depositNumber1 =
        fullObject.depositNumber1 == undefined ? 0 : fullObject.depositNumber1;
      fullObject.depositMaxAmount1 =
        fullObject.depositMaxAmount1 == undefined
          ? 0
          : fullObject.depositMaxAmount1;
    } else if (p === 3) {
      fullObject.depositMaxAmount1 = updatedValue;
      fullObject.depositAmount1 =
        fullObject.depositAmount1 == undefined ? 0 : fullObject.depositAmount1;
      fullObject.depositNumber1 =
        fullObject.depositNumber1 == undefined ? 0 : fullObject.depositNumber1;
    }
    this.decSelectList1.splice(index, 1);
    this.decSelectList1.push(fullObject);
  }
  updateDeclearationList1(id: number, p: number, event: any) {
    let index = this.decSelectList2.findIndex((obj) => obj.id === id);
    const updatedValue = event.target.value;
    const fullObject = this.decSelectList2[index];
    if (p === 1) {
      fullObject.depositNumber2 = updatedValue;
      fullObject.depositAmount2 =
        fullObject.depositAmount2 == undefined ? 0 : fullObject.depositAmount2;
      fullObject.depositMaxAmount2 =
        fullObject.depositMaxAmount2 == undefined
          ? 0
          : fullObject.depositMaxAmount2;
    } else if (p === 2) {
      fullObject.depositAmount2 = updatedValue;
      fullObject.depositNumber2 =
        fullObject.depositNumber2 == undefined ? 0 : fullObject.depositNumber2;
      fullObject.depositMaxAmount2 =
        fullObject.depositMaxAmount2 == undefined
          ? 0
          : fullObject.depositMaxAmount2;
    } else if (p === 3) {
      fullObject.depositMaxAmount2 = updatedValue;
      fullObject.depositAmount2 =
        fullObject.depositAmount2 == undefined ? 0 : fullObject.depositAmount2;
      fullObject.depositNumber2 =
        fullObject.depositNumber2 == undefined ? 0 : fullObject.depositNumber2;
    }
    this.decSelectList2.splice(index, 1);
    this.decSelectList2.push(fullObject);
  }
  showDetails(id: number) {
    window.open("/customeraccount/accountDetail/" + id, "_blank");
  }
  // Delearation
  getRiskGradingList() {
    this._service.GetRiskGradingList().subscribe((res) => {
      res.forEach((element) => {
        if (element.type === 1) {
          this.riskGradingList.push(element);
        }
      });
    });
  }
  //End Delearation
  // Modal
  openNomineeModal(content) {
    this.totalShare -= this.nomineeGroup.value.nomineeShare;
    this.getOccupationList();
    this.modalService.open(content, { size: "lg" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }
  // End Modal
  ///////////////////////////Save///////////////////////////////
  onsubmit() {
   
    
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Create Account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Create Account!",
    }).then((res) => {
      if (res.isConfirmed) {
        if (this.totalShare != 100) {
          this.shared.ShowAlert(
            "Warning",
            "Total nominee shares should be 100",
            "warning"
          );
          return false;
        }
        if (this.isEdit) {
          this.model.Id = this.frmGroup.value.id;
        } 
         this.model.CustomersInfoId = this.frmGroup.value.CustomersInfoId;
        this.model.ProductBuilderId = this.frmGroup.value.ProductBuilderId;
        this.model.NomineeInfoSId = this.frmGroup.value.NomineeInfoSId;
        this.model.AccountTilte = this.frmGroup.value.AccountTilte;
        this.model.AccountTilteBn = this.frmGroup.value.AccountTilteBn;
        this.model.OpeningBalance = this.frmGroup.value.OpeningBalance.replace(
          /,/g,
          ""
        );
        this.model.ledgerRelationId = this.frmGroup.value.ledgerRelationId;
        this.model.ledgerId = this.frmGroup.value.ledgerId;
        this.model.customerAccountNo = this.frmGroup.value.customerAccountNo;
        this.model.reason = this.frmGroup.value.reason;
        this.model.depositDeclearation = this.decSelectList1;
        this.model.withdrowDeclearation = this.decSelectList2;
        // this.model.riskGrading1=this.frmGroup.value.riskGrading1;
        //this.model.monthlyIncomes=this.frmGroup.value.monthlyIncome.replace(/,/g, '');
        //this.model.amount=this.frmGroup.value.amount.replace(/,/g, '');
        if (this.isMinor === false) {
          this.model.accountMinor = false;
        } else {
          this.model.accountMinor = true;
        }
        this.model.accountMinorName = this.frmGroup.value.accountMinorName;
        this.model.accountMinorDOB = this.frmGroup.value.accountMinorDOB;
        this.model.accountMinorGuardian =
          this.frmGroup.value.accountMinorGuardian;
        this.model.accountMinorGuardianRelation =
          this.frmGroup.value.accountMinorGuardianRelation;
        this.model.nominees = this.nolineeList;
        this.model.incomeSource = this.IncomeList;
        this.model.chargeInfo = this.chargeInfo;
        this.model.bankingCharge = this.bankingCharge;
        this.model.addCurrencyList = this.addCurrencyList; 
        if(this.model.chargeInfo.length > 0){
          this._service.save(this.model).subscribe((res) => {
            
            if(res == 1){
                  //this.checkPayment();

                  this.shared.ShowAlert("Success", "Save Successful", "success");
                  //this.getCustomerAccountListInfo();

                  this.router.navigate(["/customeraccount/customerAcclist"]);
            }
            else{
                  this.shared.ShowAlert("Warning", "Failed Save", "warning");
                  return false;
            }
             
          });
        }
        else{
          this.shared.ShowAlert("Warning", "Account charge amount not found", "warning");
        }
 
        
        // this.changeDetectorRef.detectChanges();
        // this.checkPayment();
      } else if (res.isDismissed) {
        this.router.navigate(["/customeraccount/customeraccount"]);
      }
    });
  }
  setStatus(value: boolean, c: any) {
    c.status = value ? 1 : 0;
  }

  getStatus(c: any): boolean {
    return c.status === 1;
  }
  formatNumber(value: string): string {
    const formattedValue = value
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  }

  checkPayment() {
    this.dueAmount = this.frmGroup.value.OpeningBalance.replace(/,/g, "");
    if (this.dueAmount > 0) {
      const paymentRequest: PaymentRequestVm = {
        name: [this.frmGroup.value.customerAccountNo],
        price: [this.dueAmount],
        url: ["url1"],
        qty: [1],
        pname: "Account Opening Fee",
        purl: "Account Opening Fee Url",
        pprice: this.dueAmount,
        customer_account: this.frmGroup.value.customerAccountNo,
        card_no: "",
        valueType: "customerAccount",
      };
      this.paymentService.checkoutPayment(paymentRequest).subscribe(
        (response) => {
          if (response.gatewayPageURL) {
            // Use the navigateByUrl method to navigate to the specified URL
            window.location.href = response.gatewayPageURL;
          } else {
            console.error("No gatewayPageURL found in the response");
          }
        },
        (error) => {
          console.error("Payment request failed", error);
        }
      );
    }
  }

  initiatePayment() {
    const backendUrl = "https://localhost:44395/api/Payment/InitiatePayment";
    // Replace '100.00' with the actual payment amount from your Angular component
    const paymentData = {
      total_amount: "100.00", // Replace this with the actual payment amount
      currency: "BDT", // Replace this with the appropriate currency code if needed
      // Add more payment data as needed
    };

    this.http.post(backendUrl, paymentData).subscribe(
      (response: any) => {
        // Handle the response from the backend, e.g., redirect to the payment gateway URL
        if (response && response.GatewayPageURL) {
          window.location.href = response.GatewayPageURL;
        } else {
          // Handle the case when the payment initiation failed
          console.error("Payment initiation failed");
        }
      },
      (error) => {
        // Handle the error if the HTTP request fails
        //console.error("Error initiating payment:", error);
      }
    );
  }
  //End Save
  //Reporting Start
  PrintKyc(id: number) {
    this.pdfService.OpenKycPdf(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }
  //Reporting End
  //Extra
  onKeyPress(event: any) {
    const inputValue = event.key;
    const currentValue = this.nomineeGroup.get("nomineeShare").value || 0;
    const newValue = currentValue * 10 + parseInt(inputValue);

    if (newValue > 100) {
      event.preventDefault();
    }
  }

  validateNIDLength(control: any): { [key: string]: boolean } | null {
    const nid = control.value;
    if (nid && (nid.length === 10 || nid.length === 13 || nid.length === 17)) {
      return null; // Valid length
    } else {
      return { invalidNIDLength: true }; // Invalid length
    }
  }

  //End Extra

  //#regionNID

 
  //IncomeSource

  incomeSourceNew: string = "";
  getincomeSource(value: string) {
    this.incomeSource = value;

    this.relationSvc
      .GetIncomeSourceById(this.IncomeGroup.value.sourceId)
      .subscribe((res: any) => {
        this.sourceName = res.sourceName;
      });
  }
  getIncomeSourceList() {
    this._productService.getIncomeSourceList().subscribe((res) => {
      this.IncomeSourceList = res;
      this.changeDetectorRef.detectChanges;
    });
  }

  populateIncomeForm() {
    this.IncomeGroup = this.formBuilder.group({
      IncomeListSl: [0],
      sourceId: [""],
      sourceName: [""],
      //monthlyIncomeSource:['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],
      monthlyIncome: [0],
    });
  }

  addIncomeSource() {
    let index = this.IncomeList.findIndex(
      (obj) => obj.IncomeListSl == this.IncomeGroup.value.IncomeListSl
    );

    this.IncomeGroup.value.IncomeListSl = this.IncomeList.length + 1;
    this.incomeSource.sourceId = this.IncomeGroup.value.sourceId;
    // this.incomeSource.sourceName =this.IncomeGroup.value.sourceName;
    //this.getincomeSource(this.incomeSource.sourceName);
    //this.incomeSource.value.monthlyIncome = this.IncomeGroup.value.monthlyIncome;
    this.incomeSource.monthlyIncome = this.IncomeGroup.value.monthlyIncome;
    this.IncomeGroup.value.sourceName = this.sourceName;

    this.relationSvc
      .GetIncomeSourceById(this.incomeSource.sourceId)
      .subscribe((res: any) => {
        this.incomeSource.sourceName = res.sourceName;
      });

    this.IncomeList.splice(index, 0);
    this.IncomeList.push(this.IncomeGroup.value);
    this.populateIncomeForm();
  }

  relation: string = "select";
  relationNameNew: string = "";
  getRelation(value: string) {
    this.relation = value;

    this.relationSvc
      .GetRelationById(this.nomineeGroup.value.relationId)
      .subscribe((res: any) => {
        this.relationNameNew = res.name;
      });
  }

  LoadRelationList() {
    this.relationSvc.getRelationList().subscribe((res) => {
      this.relationList = res;
    });
  }

   
  goBack() {
    this.location.back();
  }


  onKeyUp(event: KeyboardEvent, charge: any) {
    charge.Charge.Amount = parseFloat((event.target as HTMLInputElement).value) || 0;
   }


  //#endregion
}


// addNominee(){

//   alert(this.nomineeGroup.value.nomineeShare)
//   this.totalShare= this.totalShare+this.nomineeGroup.value.nomineeShare;
//   if(this.totalShare>100){
//     this.totalShare= this.totalShare - this.nomineeGroup.value.nomineeShare;
//     this.shared.ShowAlert("Warning", "Total nominee shares should be 100", "warning");
//     return false;
//   }
//   else {

//   if (this.nomineeGroup.value.nomineeBirthIdentity || this.nomineeGroup.value.nomineePassport || this.nomineeGroup.value.nomineeETin || (this.nomineeGroup.value.nomineeNID && (this.nomineeGroup.value.nomineeNID.length === 10 || this.nomineeGroup.value.nomineeNID.length === 13 || this.nomineeGroup.value.nomineeNID.length === 17))) {

//     if (this.nomineeGroup.valid && this.nomineeGroup.value.nomineeNameSl===0 && this.nomineeGroup.value.nomineeDOB && (this.nomineeGroup.value.nomineePassport!=null || this.nomineeGroup.value.nomineeNID!=null || this.nomineeGroup.value.nomineeBirthIdentity!=null || this.nomineeGroup.value.nomineeETin!=null || this.nomineeGroup.value.nomineeDriveLinc!=null)){
//       let index = this.nolineeList.findIndex((obj) => obj.nomineeNameSl === this.nomineeGroup.value.nomineeNameSl);
//       this.nomineeGroup.value.nomineeNameSl =this.nolineeList.length+1;
//       this.nominee.nomineeName =this.nomineeGroup.value.nomineeName;
//       //this.nominee.nomineeFather =this.nomineeGroup.value.nomineeFather;
//       //this.nominee.nomineeMother =this.nomineeGroup.value.nomineeMother;
//       //this.nominee.nomineeSpouse =this.nomineeGroup.value.nomineeSpouse;
//       this.nominee.nomineeDOB =this.nomineeGroup.value.nomineeDOB;

//       this.nominee.nomineePresentAddress =this.nomineeGroup.value.nomineePresentAddress;
//       //this.nominee.nomineePermanentAddress =this.nomineeGroup.value.nomineePermanentAddress;
//       this.nominee.nomineeOccupation =this.nomineeGroup.value.nomineeOccupation;
//       this.nominee.nomineeNID =this.nomineeGroup.value.nomineeNID;
//       this.nominee.nomineePassport =this.nomineeGroup.value.nomineePassport;
//       this.nominee.nomineeBirthIdentity =this.nomineeGroup.value.nomineeBirthIdentity;
//       this.nominee.nomineeETin =this.nomineeGroup.value.nomineeETin;
//       //this.nominee.nomineeDriveLinc =this.nomineeGroup.value.nomineeDriveLinc;
//       this.nominee.nomineeShare =this.nomineeGroup.value.nomineeShare;
//       this.nominee.relationName =this.relationNameNew;
//       this.nominee.relationId =this.nomineeGroup.value.relationId;

//       this.nominee.photo = this.backPreview;
//       this.nomineeGroup.value.relationName = this.relationNameNew;
//       // console.log(this.nominee);
//       // this.relationSvc.GetRelationById(this.nomineeGroup.value.relationId).subscribe((res: any) => {
//       //   console.log(res);
//       //   this.nominee.relationName = res.name;
//       //   this.nomineeGroup.patchValue({
//       //    relationId:res.id,
//       //   // id:res.id,
//       //     relationName: res.name,
//       //   })
//       // });

//       this.nolineeList.splice(index,0);
//       this.nolineeList.push(this.nomineeGroup.value);
//       this.populateNomineeForm();
//       console.log("this.nolineeList");
//       console.log(this.nolineeList);
//     }
//     // else if (this.nomineeGroup.value.nomineeNameSl>0 && this.nomineeGroup.valid && (this.nomineeGroup.value.nomineePassport!=null || this.nomineeGroup.value.nomineeNID!=null || this.nomineeGroup.value.nomineeBirthIdentity!=null || this.nomineeGroup.value.nomineeETin!=null || this.nomineeGroup.value.nomineeDriveLinc!=null)){
//     //   let index = this.nolineeList.findIndex((obj) => obj.nomineeNameSl === this.nomineeGroup.value.nomineeNameSl);
//     //   this.nominee.nomineeNameSl =this.nomineeGroup.value.nomineeNameSl;
//     //   this.nominee.nomineeName =this.nomineeGroup.value.nomineeName;
//     //   // this.nominee.nomineeFather =this.nomineeGroup.value.nomineeFather;
//     //   // this.nominee.nomineeMother =this.nomineeGroup.value.nomineeMother;
//     //   // this.nominee.nomineeSpouse =this.nomineeGroup.value.nomineeSpouse;
//     //   this.nominee.nomineeDOB =this.nomineeGroup.value.nomineeDOB;
//     //   this.nominee.nomineePresentAddress =this.nomineeGroup.value.nomineePresentAddress;
//     //   // this.nominee.nomineePermanentAddress =this.nomineeGroup.value.nomineePermanentAddress;
//     //   this.nominee.nomineeOccupation =this.nomineeGroup.value.nomineeOccupation;
//     //   this.nominee.nomineeNID =this.nomineeGroup.value.nomineeNID;
//     //   this.nominee.nomineePassport =this.nomineeGroup.value.nomineePassport;
//     //   this.nominee.nomineeBirthIdentity =this.nomineeGroup.value.nomineeBirthIdentity;
//     //   this.nominee.nomineeETin = this.nomineeGroup.value.nomineeETin;
//     //   this.nominee.relationId =this.nomineeGroup.value.relationId;
//     //   this.nominee.relationName =this.nomineeGroup.value.relationName;
//     //   this.nominee.photo = this.backPreview;
//     //   this.nominee.name =this.nomineeGroup.value.name;

//     //   this.relationSvc.GetRelationById(this.nomineeGroup.value.relationId).subscribe((res: any) => {
//     //    // console.log(res.relation.name);
//     //   //  alert(res.name)
//     //     //this.accountsCode = res.accountCode;
//     //     this.nomineeGroup.patchValue({

//     //       //id:res.id,
//     //       relationName2: res.name
//     //     })
//     //   });

//     //   // this.nominee.nomineeETin =this.nomineeGroup.value.nomineeETin;
//     //   // this.nominee.nomineeDriveLinc =this.nomineeGroup.value.nomineeDriveLinc;
//     //   this.nominee.nomineeShare =this.nomineeGroup.value.nomineeShare;
//     //   this.nolineeList.splice(index, 1);
//     //   this.nolineeList.push(this.nomineeGroup.value, 1);

//     //   this.populateNomineeForm();
//     // }
//     else{
//       swal('Warning', 'Input All Required Feild', 'warning');
//     }
//   }

//   else {
//     this.shared.ShowAlert("Warning", " nominee nid  should be 10 or 13 or 17 digit", "warning");
//     return false;

//   }

//   }

// }
