import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CashPostingService } from '../service/cash-posting.service';
import { LedgerService } from '../service/ledger.service';
import { SharedService } from 'app/services/shared.service';
import { CashPosting } from '../model/cash-posting';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { combineLatest, Observable, of } from 'rxjs'; 
import { CustomerService } from 'app/customer/customer.service';
import { BusinessDate } from '../model/business-date';
import swal from 'sweetalert';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { PaymentService } from 'app/payment/payment.service';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { PaymentRequestVm } from 'app/payment/models/payment-request-vm';
import Swal from 'sweetalert2'
import { DatePipe,Location } from '@angular/common';


@Component({
  selector: 'app-withdraw-money',
  templateUrl: './withdraw-money.component.html',
  styleUrls: ['./withdraw-money.component.scss']
})
export class WithdrawMoneyComponent implements OnInit {

  selectedOption: string;
  customerIdPayment: number;
  customerAccountId: number;
  customerAccountId2: number;
  currentBalance: number;
  isvalid: boolean = true;
  isBtnValid: boolean = false;
  form: FormGroup;
  //BDGenerateCode: string;

  BDGenerateCode: string = "";
  customerCode: string = "";
  accountNo: any;
  accountNoRecieve: string = "";
  accountNoFund1: string = "";
  accountNoFund2: string = "";
  AutoCompleate: any[] = [];
  leadId: any = "";
  customerInfo_cashPayment: any = "";
  customerInfo_cashReceive: any = "";
  customerInfo_cashFundTransfer1: any = "";
  customerInfo_cashFundTransfer2: any = "";

  //BDGenerateCode: string = '';
  //customerCode: string = '';

  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: CashPosting[] = [];
  model = new CashPosting();
  model2: any = {};
  formControl = new FormControl();
  suggestions: any[] = [];
  //hideShow: any;
  hideShow: string = "Show";
  searchType: string = "";
  isAuto: boolean = false;

  dueAmount: number;
  paymentName: string = "";
  AccountNoList: Observable<any[]>;
  AccountNoList1: Observable<any[]>;
  totalAdd: number;
  totalMoney: number;
  countryList$: Observable<any[]>;
  constructor(
    private router: Router,
    private _service: CashPostingService,
    private _LedgerService: LedgerService,
    private _CountryInfoService: CountryInfoService,
    private _CustomerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private _loanProductService: LoanProductService,
    private paymentService: PaymentService,
    private location: Location,
    private datePipe: DatePipe
  ) {
    {
      this.frmGroup = new FormGroup({
        id: new FormControl(),
        customerCode: new FormControl(),
        transactionType: new FormControl(),
        customerId: new FormControl(),
        //voucherNo: new FormControl(),
        //postingType: new FormControl(),
        remarks: new FormControl(),
        currencyId: new FormControl(),
        voucherAmount: new FormControl(),
        BDGenerateCode: new FormControl(),
        businessDateId: new FormControl(),
        businessDate: new FormControl(),
        gLGenerateId: new FormControl(),
        leadId: new FormControl(),
        collectionAmount: new FormControl(),

        ledgerRelationId: new FormControl(),
      });
      this.frmGroup = this.fb.group({
        voucherAmount: [
          "",
          [Validators.required, Validators.pattern("^[0-9,.]*$")],
        ],
        exchangeAmount: [
          "",
          [Validators.required, Validators.pattern("^[0-9,.]*$")],
        ],
        //exchangeAmount: [{ value: '', disabled: true }],
        customerCode: ["", Validators.required],
        AccountName: ["", Validators.required],
        customerAccountId: [0, Validators.required],
        voucherNo: ["", Validators.required],
        remarks: ["", Validators.required],
        chequeNo: ["", Validators.required],
        currencyId: [0, Validators.required],
        customerId: [0, Validators.required],
        gLGenerateId: [0, Validators.required],
        businessDateId: ["", Validators.required],
        businessDate: ["", Validators.required],
        // remarks: ['', Validators.required],
      });
      this.form = this.fb.group({
        BDGenerateCode: [""],
        customerCode: [""],
        customerAccountId: [0],
        voucherNo: [""],
        remarks: [""],
        currencyId: [0],
      });
    }
  }

  ngOnInit(): void {
    this.GetCustomerAccountNo();
    this.loadLedgerList();
     
    //this.AutoCode();
    this.formControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((value) =>
          this._LedgerService.getAutocompleteSuggestions(value)
        )
      )
      .subscribe((data) => {
        this.suggestions = data;
        // console.log(this.suggestions)
      });

    this.hideShow = "Show";
    this.GetBusinessDatebyActiveStatus();
    this.loadCountryList();
    this.countryList$ = this._CountryInfoService.getAllGetCurrencyInfo();

    // Call the calculation function to update exchangeAmount on any value change
    this.calculateExchangeAmount();
    this.currentBalance = 0;
  }

  GetCustomerAccountNo() {
    this._CustomerService.GetAllCustomerAccountNo().subscribe((res) => {
      this.AccountNoList = of(res);
    });
  }

  selectedSuggestion: any = null;
  gLGenerateId: any = null;
  AccountName: any = null;
  glAccountName: any;

  hideAfterSelectValue(value: any) {
    this.hideShow = value;
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  LedgerList: Observable<any[]>;
  loadLedgerList() {
    this._LedgerService.getMFSLedgerRelation().subscribe((res) => {
      this.LedgerList = of(res);
    });
  }

  countryList: Observable<any[]>;
  loadCountryList() {
    this._CountryInfoService.getAllGetCurrencyInfo().subscribe((res) => {       
      //this.countryList =res;     
    });
  }

  onsubmit() {
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    } else {
      this.model.id = 0;
    }

    const paymentRequest: PaymentRequestVm = {
      name: [this.paymentName],
      price: [this.frmGroup.value.voucherAmount.replace(/,/g, "")],
      url: ["url1"],
      qty: [1],
      pname: "Add Money",
      purl: "Add Money",
      pprice: this.frmGroup.value.voucherAmount.replace(/,/g, ""),
      customer_account: this.paymentName,
      card_no: this.frmGroup.value.accountNo,
      valueType: "add-money",
    };

    this.model.transactionType = 1;
    this.model.customerCode = this.frmGroup.value.customerCode;
    this.model.customerAccountId = this.customerAccountId;
    this.model.voucherNo = this.frmGroup.value.voucherNo; 
    this.model.ledgerRelationId = 2629;
    this.model.chequeNo = this.frmGroup.value.chequeNo;
    this.model.remarks = this.frmGroup.value.remarks;
    this.model.currencyId = this.frmGroup.value.currencyId;
    this.model.businessDateId = this.frmGroup.value.businessDateId;

     this.model.voucherAmount = this.frmGroup.value.exchangeAmount.replace(
      /,/g,
      ""
    );
     
    if(this.currentBalance == 0){
      this.shared.ShowAlert("Warning", "This Account have no Balance", "warning");
      return;
    }
    if(this.model.businessDateId == null || this.model.businessDateId == "" || this.model.businessDateId == 0){
      this.shared.ShowAlert("Warning", "Business Date not found", "warning");
      return;
    }
    
    if(this.model.chequeNo == null || this.model.chequeNo == ""){
      this.shared.ShowAlert("Warning", "ChequeNo not found", "warning");
      return;
    }
     
    if(this.model.currencyId == null || this.model.currencyId == 0){
      this.shared.ShowAlert("Warning", "currency not found", "warning");
      return;
    }
    if(this.model.voucherAmount == null || this.model.voucherAmount == 0 ){
      this.shared.ShowAlert("Warning", "Exchange Amount not found", "warning");
      return;
    }
    if(this.model.voucherAmount > this.currentBalance){
      this.shared.ShowAlert("Warning", "You don't have enough money", "warning");
      return;
    }
    if(this.model.voucherAmount < 10000){
      this.shared.ShowAlert("Warning", "Amount must be more than 10000", "warning");
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to add money!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, add money!",
    }).then((res) => {
      if (res.isConfirmed) {
        if (this.model.voucherAmount > 0 && this.model.currencyId > 0) {
          if (this.model.id == 0) {
            this._service.save(this.model).subscribe((res: number) => {
              if (res > 0) {
                this.ResetForm();

                this.shared.ShowAlert("Success", "Save Successfully", "success");
                this.isEdit = false;
              }
              else {
                this.shared.ShowAlert("Warning", "Failed to Save", "warning");
                this.isEdit = false;
              } 
            });
          } else {
            this.CheckBalance();

            this._service.save(this.model).subscribe((res: number) => {
              

              if (res > 0) {
                this.paymentService.checkoutPayment(paymentRequest).subscribe(
                  (response) => {
                    if (response.gatewayPageURL) {
                      window.location.href = response.gatewayPageURL;
                    } else {
                      console.error("No gatewayPageURL found in the response");
                    }
                  },
                  (error) => {
                    console.error("Payment request failed", error);
                  }
                );
              } else {
                this.shared.ShowAlert("Error", "Failed to save.", "error");
              }
            });
          }
        }
      } else if (res.isDismissed) {
        this.refreshAc();
        this.router.navigate(["/accounting/withdraw-money"]);
      }
    });
  }

  formatNumber(value: string): string {
    const formattedValue = value
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  }

   
  refreshAc() {
    this.currentBalance = 0;
    this.customerInfo_cashPayment = ""; 
    this.customerIdPayment = 0;
    this.customerAccountId = 0;
    this.isBtnValid = false;
  }

   

  loadUserDataByAccountNoCashPayment(accountId: number) {
    this._CustomerService
      .GetCustomerInfoByAccountNoById(accountId)
      .subscribe((res) => {
        console.log("datas", res)
        if (res != null) {
          if (res.glAccountName == null) {
             
            if(res.currentBalance != null){
              this.currentBalance = res.currentBalance;
            }
            else{
              this.currentBalance = 0;
            }
            this.customerInfo_cashPayment =
              res.customerNameEng + "; " + res.mobileNo + "; " + res.nidNo;
            this.customerIdPayment = res.customersId;
            this.customerAccountId = res.id;
            this.isBtnValid = true;
            this.glAccountName = "";
            this.leadId = res.leadId;
            this._CustomerService.getCurrencyByCustomerAccountId(res.id).subscribe(res =>{
          
              this.countryList = res;
          })
          } else {
            this.glAccountName = res.glAccountName;
            this.customerInfo_cashPayment = "";
            this.isBtnValid = true;
          }
        } else {
          this.refreshAc();
          swal("Warning", "Invalid Account No", "warning");
        }
      });
    this._CustomerService
      .GetCustomerAccountNoForChange(accountId)
      .subscribe((res) => {
        this.AccountNoList1 = of(res);
      });
  }

  ResetForm() {
    this.frmGroup.reset();

    this.customerInfo_cashPayment = ""; 
  }
  //#endregion
  GetBusinessDatebyActiveStatus() {
    this._LedgerService.GetBusinessDatebyActiveStatus().subscribe((res) => {
      if (res != null) {
        this.isAuto = true;
        this.frmGroup.patchValue({
          businessDateId: res.id,
          businessDate: this.datePipe.transform(
            res.business_Date,
            "yyyy-MM-dd"
          ),
        });
      } else {
        this.frmGroup = this.formBuilder.group({
          businessDateId: "",
        });
      }
    });
  }
  goBack() {
    this.location.back();
  }


  showChequeNo: boolean = false;

  cheque() {
    this.showChequeNo = true;  
  }

  cash() {
    this.showChequeNo = false;  
  }
  onVoucherAmountInput(event: any): void {
    let inputValue = event.target.value;
    // Remove any non-numeric characters (including commas)
    let numericValue = inputValue.replace(/,/g, '');
  
    // Format the number with commas for display
    let formattedValue = this.formatNumberWithCommas(numericValue);
  
    // Set the formatted value back to the form control
    this.frmGroup.get('voucherAmount')?.setValue(formattedValue, { emitEvent: false });
  }
  
  formatNumberWithCommas(value: string): string {
    let num = value.replace(/,/g, ''); // Remove commas if any
    let [integer, decimal] = num.split('.'); // Split into integer and decimal part
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
    return decimal ? `${integer}.${decimal}` : integer; // Reattach decimal part if any
  }


  CheckBalance() {
    var inputAmount = parseInt(
      this.frmGroup.value.exchangeAmount.replace(/,/g, ""),
      10
    );

    this._CustomerService
      .getTransactionLimitByAccountId(this.accountNo)
      .subscribe((res) => {
        var inputValues = inputAmount;
        this.totalAdd = res.monthlyTRCount;

        this.totalMoney = res.monthlyTRTotalAmount;

        if (res.monthlyTRMaxAmount > inputValues) {
          var amounts = res.monthlyTRMaxAmount;
          this.shared.ShowAlert(
            "Warning",
            "Amount must be more than " + amounts,
            "warning"
          );
        } else if (res.monthlyTRTotalAmount < inputValues) {
          this.shared.ShowAlert(
            "Warning",
            "Amount is more than the transaction limit.",
            "warning"
          );
        }
      });

    this._CustomerService
      .getTransactionCountBalanceId(this.accountNo, 2)
      .subscribe((res) => {
        var inputValues = inputAmount;

        if (this.totalMoney > res.totalAmount) {
          this.shared.ShowAlert(
            "Warning",
            "Transaction limit is over.",
            "warning"
          );
        }

        if (this.totalAdd === res.transactionCount) {
          this.shared.ShowAlert(
            "Warning",
            "You already cross your add money as per declearation.",
            "warning"
          );
        }

       
      });
  }

  calculateExchangeAmount(): void {
    combineLatest([
      this.countryList$.pipe(startWith([])),
      this.frmGroup.valueChanges.pipe(startWith(this.frmGroup.value)),
    ])
      .pipe(
        map(([countryList, formValues]) => {
          const selectedCurrency = countryList.find(
            (item) => item.id === +formValues.currencyId
          );
          const amount = +formValues.voucherAmount.replace(/,/g, ''); // Remove commas for calculation
  
          if (selectedCurrency && !isNaN(amount)) {
            const exchangeAmount = (amount * selectedCurrency.exchangeRate).toFixed(2);
            return this.formatNumberWithCommas(exchangeAmount); // Format with commas for display
          }
          return ''; // Return an empty string if invalid input
        })
      )
      .subscribe((exchangeAmount) => {
        this.frmGroup.get('exchangeAmount')?.setValue(exchangeAmount, { emitEvent: false });


        
      });
  }
  
}
