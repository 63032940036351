


<div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
    <div class="card-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-9">
                    <h5 class="card-title page-heading" style="color: black">{{isEdit ? 'Update' : 'Create'}} Account Charge </h5>

                </div>
               
               
                <div class="col-md-2">
                    <button type="button" data-toggle="tooltip" title="Go To List"
                        class="btn btn-secondary btn-gotolist" (click)="onClickShowSaveForm('saveList')"
                        style="float:right; margin-right:5px;margin-left: 8px;">Go To List</button>
                </div>
                <div class="col-md-1">
                    <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;">
                        <i class="icon-arrow-left" style="font-size: 17px;"></i>
                    </button>
                </div>
            </div>


            <hr>
            <form [formGroup]="frmGroup">
                <div asp-validation-summary="All" class="text-danger"></div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="desicode" class="col-sm-4 col-form-label">Code <span
                                    style="color: red;">*</span></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="code" >
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="desicode" class="col-sm-4 col-form-label">Name <span style="color: red;">*</span></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="name">
                            </div>
                        </div>
                    </div>
                    

                </div>

                <div class="row mt-2">

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label">Account Type</label>
                        <div class="col-sm-8">
                            <select class="form-control" formControlName="accountTypeId">
                                <option value="0">Select</option>
                                <option *ngFor="let item of accountTypelist" [value]="item.Id">
                                    {{item.typeName}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="sortorder" class="col-sm-4 col-form-label">Is Default</label>
                            <div class="col-sm-8">
                                <select class="form-control" formControlName="isDefault">
                                    <option value="">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
               
                
            </form>
        </div>
    </div>
    <div class="card-body">
        <div class="container">
            <h5 class="card-title page-heading" style="color: black">Charge Detail</h5>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="desicode" class="col-sm-4 col-form-label"> Code<span style="color: red;">*</span></label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control"  [(ngModel)]="code" placeholder="Code" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="desicode" class="col-sm-4 col-form-label"> Name<span
                                style="color: red;">*</span></label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control"  [(ngModel)]="name"
                                placeholder="Name" />
                        </div>
                    </div>
                </div>


                

            </div>


            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="name" class="col-sm-4 col-form-label">Frequency<span style="color: red;">*</span></label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" min="0" [(ngModel)]="frequency" placeholder="Frequency"
                               
                                onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46" />
                          
                        </div>
                    </div>
                
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="amount" class="col-sm-4 col-form-label"> Amount</label>
                        <div class="col-sm-8">
                            <input type="number" min="0" class="form-control" [(ngModel)]="amount"
                                placeholder="Amount"
                                onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46" />
                        </div>
                    </div>
                </div>
                

            </div>

            <div class="row">
                
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="sortorder" class="col-sm-4 col-form-label">Status</label>
                        <div class="col-sm-8">
                            <select class="form-control" formControlName="status">
                                <option value="">Select</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                    </div>
                </div>
            
                <div class="col-md-6">
                    <button type="button" (click)="addDetails()" data-toggle="tooltip" title="Add"
                        class="btn btn-primary btn-inneradd">{{addbtn ? 'Add' : 'Update'}}</button>
                </div>
            
            </div>

        </div>
      
        <div class="container">
            <table class="table">
                <thead>
                    <tr>
                        <th>Sl.</th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Frequency</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of chDetails; let i = index">
                        <td>{{ i+1 }}</td>
                        <td>{{ item.code}}</td>
                        <td>{{ item.name}}</td>
                        <td>{{ item.frequency}}</td>
                        <td style="text-align: right;">{{ item.amount |number}}</td>
              
                        <td>
                            <button type="button" class="btn btn-info btn-sm mr-1" (click)="editChargeDetails(i)"><i class="fa fa-edit"></i></button>
                            <button type="button" class="btn btn-danger btn-sm" (click)="deleteChargeDetails(i)"><i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="container">
            <button  data-toggle="tooltip" title="Save" (click)="onsubmit()" type="submit"
                value="Submit" class="btn btn-success btn-add" style="float:right; margin-top:5px;">
                <span>{{ isEdit ? 'Update' : 'Save' }}</span>
            </button>
            <button type="button" data-toggle="tooltip" title="Refresh" (click)="reset()" class="btn btn-secondary"
                style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
        </div>
    </div>
</div>

<!---------------------------------LIST----------------------------------------------->

<div class="card" *ngIf="showSaveForm==='saveList'">
    <div class="card-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-9">
                    <h5 class="card-title page-heading" style="color: black">Account Charge </h5>
                </div>
                <div class="col-md-2">
                    <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary btn-gotoadd"
                        (click)="onClickShowSaveForm('saveForm')" style="float:right; margin-right:5px;margin-left: 8px;">Add
                        New</button>
                </div>
                <div class="col-md-1">
                    <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float: right">
                        <i class="icon-arrow-left" style="font-size: 17px"></i>
                    </button>
                </div>
            </div>
            <hr style="height: 10%" />

            
        </div>
    </div>
    <div class="card-body">
        <div class="container-fluid">
            <div class="row" style="overflow: scroll; max-height: 645px">
                <table style="width: 100%" class="table table-bordered table-striped">
                    <thead>
                        <tr style="background-color: #eef5e9">
                            <th class="text-center">Code</th>
                            <th class="text-center">Name</th>
                            <th class="text-center">Account Type</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody style="background-color: white">
                        <tr *ngFor="let item of customerCharge">
                          
                            <td class="text-center">{{ item.code }}</td>
                            
                            <td class="text-left">{{ item.name }}</td>
                            <td class="text-center">{{ item.accountType?.typeName }}</td>
                            
                            <td class="text-center">
                                <button class="btn btn-primary btn-sm mr-1" (click)="edit(item)">
                                    <i class="fa fa-edit 3x"></i>
                                </button>
                                <a href="javascript:void(0)" class="btn btn-add btn-sm" (click)="OpenModal(requestBodyModal, item)">Details</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #requestBodyModal let-c="close" let-d="dismiss">
    <div class="modal-dialog modal-lg" id="myModal">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title page-heading">Charge Details</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <table style="width: 100%" class="table table-bordered table-striped">
                        <thead>
                            <tr style="background-color: #eef5e9">
                                <th class="text-center">Code</th>
                                <th class="text-center">Name</th>
                                <th class="text-center">Amount</th>
                                <th class="text-center">Frequency</th>
                            </tr>
                        </thead>
                        <tbody style="background-color: white">
                            <tr *ngFor="let item of chargeDetails">
                    
                                <td class="text-center">{{ item.code }}</td>
                    
                                <td class="text-left">{{ item.name }}</td>
                                <td class="text-center">{{ item.amount}}</td>
                                <td class="text-center">
                                    {{
                                    item.frequency === 12 ? 'Yearly' :
                                    item.frequency === 6 ? 'Half Yearly' :
                                    item.frequency === 1 ? 'Monthly' :
                                    item.frequency === 0 ? 'On Demand' : item.frequency
                                    }}
                                </td>

                               
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="c('Close click')">
                    Close
                </button>
            </div>
        </div>
    </div>
</ng-template>