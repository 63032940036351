import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

//const apiUrl=environment.baseUrl + '/api/InterestCalculation/'

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;


@Injectable({
  providedIn: 'root'
})
export class InterestCalculationService {

  constructor(private http:HttpClient) { }

  getFrequencyList():Observable<any>{
    return this.http.get(dcbsUrl + '/api/InterestCalculation/GetAllFrequencyList')
  }
  getFrequencyListByProductType(id:number):Observable<any>{
    return this.http.get(dcbsUrl + '/api/InterestCalculation/GetAllFrequencyListByProBuilderType?id='+id)
  }

  getFrequencyById(id:number){
    return this.http.get(dcbsUrl + '/api/InterestCalculation/GetFrequencyById')
  }

  saveFrequency(model:any){
    return this.http.post(dcbsUrl + '/api/InterestCalculation/SaveFrequency',model)
  }

  deleteFrequency(id:any){
    return this.http.delete(dcbsUrl + '/api/InterestCalculation/DeleteFrequency/' + id)
  }


//InterestCalculationType
GetAllInterestCalculationType():Observable<any>{
  return this.http.get(dcbsUrl + '/api/InterestCalculation/GetAllInterestCalculationTypeWithDetail')
}
GetAllInterestCalculationTypeByProductType(id:number):Observable<any>{
  return this.http.get(dcbsUrl + '/api/InterestCalculation/GetAllInstCalTypeWithDetailByProBuilderType?id='+id)
}
GetInterestCalculationTypeById(id:number){
  return this.http.get(dcbsUrl + '/api/InterestCalculation/GetInterestCalculationTypeById')
}

GetDetailsByMasterId(id:any){
  return this.http.get(dcbsUrl +'/api/InterestCalculation/GetInterestDetailbyMasterId/'+ id);
}

SaveInterestCalculationType(model:any){
  return this.http.post(dcbsUrl + '/api/InterestCalculation/SaveInterestCalculationType',model)
}

DeleteInterestCalculationType(id:any){
  return this.http.delete(dcbsUrl + '/api/InterestCalculation/DeleteInterestCalculationType/' + id)
}

getListFreqWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
  return this.http.get(dcbsUrl + '/api/InterestCalculation/GetFrequencyListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
}
getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
  return this.http.get(dcbsUrl + '/api/InterestCalculation/GetCalculationTypeListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
}
getInterestCalculationType():Observable<any>{
  return this.http.get(dcbsUrl + '/api/InterestCalculation/GetAllInterestCalculationType')
}
}
