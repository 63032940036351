import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

// const apiUrl = environment.baseUrl + '/api/LoanRegisters/';
// const documentUrl=environment.baseUrl+'/api/LoanApplication/';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class ProfessionalDetailService {

  constructor(private http: HttpClient) { }

  getselfprofList() {
    return this.http.get(dcbsUrl + '/api/LoanRegisters/GetSelfProfinfo');
  }

  getList() {
    return this.http.get(dcbsUrl + '/api/LoanRegisters/GetProfessionalinfo');
  }

  GetprofesionalinfoByClientId(id: any) {
    return this.http.get(dcbsUrl + '/api/LoanRegisters/GetprofesionalinfoByClientId/' + id);
  }

  GetprofesionalinfoByClientandTypeId(id: number, professionaltypeId): Observable<any> {
    return this.http.get(dcbsUrl + '/api/LoanRegisters/GetprofesionalinfoByClientandtypeId/' + id + '/' + professionaltypeId);
  }

  save(model: any) {

    return this.http.post(dcbsUrl + '/api/LoanRegisters/SaveServiceHolder', model);
  }
  saveform2(model: any) {

    return this.http.post(dcbsUrl + '/api/LoanRegisters/SaveSelfEmployee', model);
  }
  saveform3(model: any) {

    return this.http.post(dcbsUrl + '/api/LoanRegisters/SaveBusiness', model);
  }
  saveUpdate(model: any) {

    return this.http.post(dcbsUrl + '/api/LoanRegisters/UpdateProfessionalInfo', model);
  }
  saveDocument(model: any) {

    return this.http.post(dcbsUrl + '/api/LoanApplication/UploadDocument', model);
  }
  delete(id: any) {
    return this.http.delete(dcbsUrl + '/api/LoanRegisters/DeleteProfessionalinfo/' + id);
  }
//#regio Address Details

getAllAddress():Observable<any>{
  return this.http.get(dcbsUrl + '/api/LoanRegisters/GetAllAddressDetails');
}

getAddressById(Id: number){
  return this.http.get(dcbsUrl + '/api/LoanRegisters/GetAddressDetailsById/' + Id);
}

getAllAddressByClientId(clientId: number):Observable<any>{
  return this.http.get(dcbsUrl + '/api/LoanRegisters/GetAllAddressDetailsByClientId/' + clientId);
}

getAddressByClientId(clientId: number){
  return this.http.get(dcbsUrl + '/api/LoanRegisters/GetAddressDetailsByClientId/' + clientId);
}

saveAddress(model:any){

  return this.http.post(dcbsUrl + '/api/LoanRegisters/SaveAddress', model);
}

deleteAddress(Id: number){
  return this.http.delete(dcbsUrl + '/api/LoanRegisters/DeleteAddress/' + Id);
}
GetLeadGenerationByLeadNoforAddress(customersId:number): Observable<any> {
  return this.http.get(dcbsUrl+ '/api/LoanRegisters/GetLeadGenerationByLeadNoforAddress?customersId=' + customersId );
 }

 GetPresentAndPermanentAddressByCustomerid(customerId:number):Observable<any>{
  return this.http.get(dcbsUrl+'/api/LoanRegisters/GetPresentPermanentAddressByCustomerId?customersId='+customerId)
 }
 

 GetTempCustomerPresentPermanentAddressByCustomerId(customerId:number):Observable<any>{
  return this.http.get(dcbsUrl+'/api/LoanApplication/GetTempCustomerPresentPermanentAddressByCustomerId?customersId='+customerId)
 }
 GetLeadGenerationLeadNoforAddress(customersId:number): Observable<any> {
  return this.http.get(dcbsUrl+ '/api/LoanRegisters/GetLeadGenerationLeadNoforAddress?customersId=' + customersId );
 }


 GetLeadGenerationByLeadNoforProfessional(customersId:number): Observable<any> {
  return this.http.get(dcbsUrl + '/api/LoanRegisters/GetLeadGenerationByLeadNoforProfessional?customersId=' + customersId );
 }


 
 GetTempProfessionByCustomerId(customersId:number): Observable<any> {
  return this.http.get(dcbsUrl + '/api/LoanApplication/GetTempProfessionByCustomerId?customersId=' + customersId );
 }

 GetUserCustomerPresentPermanentAddressByCustomerId(customerId:number):Observable<any>{
  return this.http.get(dcbsUrl+'/api/LoanApplication/GetCustomerPresentPermanentAddressByCustomerId?customersId='+customerId)
 }
 saveUserAddress(model:any){

  return this.http.post(dcbsUrl + '/api/LoanRegisters/SaveUserAddress', model);
}
GetProfessionByCustomerId(customersId:number): Observable<any> {
  return this.http.get(dcbsUrl + '/api/LoanApplication/GetProfessionByCustomerId?customersId=' + customersId );
 }


 saveUser(model: any) {

  return this.http.post(dcbsUrl + '/api/LoanRegisters/SaveUserServiceHolder', model);
}
saveUserform2(model: any) {

  return this.http.post(dcbsUrl + '/api/LoanRegisters/SaveUserSelfEmployee', model);
}
saveUserform3(model: any) {

  return this.http.post(dcbsUrl + '/api/LoanRegisters/SaveUserBusiness', model);
}
//endregion

}
