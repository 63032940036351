import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Balancetype } from "app/master-data/products/model/balancetype";
import { Excise_duty } from "app/master-data/products/model/Excise_Duty";
import { InterestCalculationFrequency } from "app/master-data/products/model/interest-calculation-frequency";
import { DaySize } from "app/master-data/products/model/day-size";
import { Accured } from "app/master-data/products/model/accured";
import { CapitalizationRule } from "app/master-data/products/model/capitalizationRule";
import { BalancetypeService } from "app/master-data/products/service/balancetype.service";
import { ExciseDutyService } from "app/master-data/products/service/excise-duty.service";
import { InterestCalculationService } from "app/master-data/products/service/interest-calculation.service";
import { AccuredService } from "app/master-data/products/service/accured.service";
import { DaySizeService } from "app/master-data/products/service/day-size.service";
import { TaxKeyService } from "app/master-data/products/service/tax-key.service";
import { CapitalizationRuleService } from "app/master-data/products/service/capitalization-rule.service";
import { Commission } from "app/master-data/products/model/commission";
import { CommissionService } from "app/master-data/products/service/commission.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CountryInfoService } from "app/master-data/basic/basic-service/country-info.service";
import { CountryInfo } from "app/master-data/basic/basic-model/country-info";
import { SlabService } from "app/master-data/products/service/slab.service";
import { CapitalizeFrequency } from "app/master-data/products/model/capitalize-frequency";
import { CapitalizeFrequencyService } from "app/master-data/products/service/capitalize-frequency.service";
import { ChargeService } from "app/master-data/products/service/charge.service";
import { Charge } from "app/master-data/products/model/charge";
import { LedgerService } from "app/accounting/service/ledger.service";
import { GroupService } from "app/accounting/service/group.service";
import { Location } from "@angular/common";
import Swal from "sweetalert2";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { UserRoleAssignService } from "app/user/user-management/user-service/user-role-assign.service";
import { ProductEventService } from "app/master-data/products/service/product-event.service";
import { Durations } from "app/master-data/products/model/durations";
import { ProductGLList } from "app/product/models/product-gl-list";
import { ProductMappingGL } from "app/product/models/product-mapping-gl";
import { LoanProductService } from "app/product/service/loan-product.service";
import { ProductBuilderService } from "app/product/service/product-builder.service";
import { InterestCalculationType } from "app/master-data/products/model/interest-calculation-type";
import { PaymentTypeService } from "app/master-data/products/service/payment-type.service";

interface Approver {
  order: number;
  userId: string;
  fullName: string;
}
@Component({
  selector: 'app-deposit-product-builder-edit',
  templateUrl: './deposit-product-builder-edit.component.html',
  styleUrls: ['./deposit-product-builder-edit.component.scss']
})
export class DepositProductBuilderEditComponent implements OnInit {

  list: any[] = [];
  product: any;
  ledgerList: any = [];
  ledgerListAcc: any = [];
  ledgerIntProList: any = [];
  accGroupList: any = [];
  incomeExpenseGroupList: any;
  categoryList: any[] = [];
  productDetails: any;
  balanceTypeList: Balancetype[] = [];
  balanceType: any;
  exciseDutyList: Excise_duty[] = [];
  exciseDutyDetails: any[] = [];
  sortExcDutyDetails: any[] = [];
  exciseDuty: any;
  capRuleList: CapitalizationRule[] = [];
  capRuleSelectList: CapitalizationRule[] = [];
  capRule: any;
  capFrequencyList: CapitalizeFrequency[] = [];
  capFrequency: any;
  intCalculationTypeList: any[] = [];
  intCalTypeDetails: any[] = [];
  storeIntCalTypeDetails: any[] = [];
  storeIntCalType: any;
  intCalculationFreqList: [InterestCalculationFrequency][] = [];
  intCalculationList: [InterestCalculationType][] = [];

  accuredList: Accured[] = [];
  daySizeList: DaySize[] = [];
  mappedGLList: ProductGLList[] = [];
  //mappedGLList: any = [];

  showGlTbl: boolean = false;
  daySize: any;
  currencyList: CountryInfo[] = [];
  taxKeyList: any[] = [];
  taxKey: any;
  taxKeyDetails: any[] = [];
  allSlab: any[] = [];
  instCalTypeSlab: any[] = [];
  isEdit: boolean = false;
  intCalTypeDiv: boolean = false;
  intCalTypeMultipleDiv: boolean = false;
  chargeList: Charge[] = [];
  charge: any;
  selectedChargeList: Charge[] = [];
  commissionList: Commission[] = [];
  commission: any;
  selectedCommissionList: Commission[] = [];
  GropupNatureList: any[] = [];
  showEntry = false;
  frmGroup: FormGroup;
  model: any = {};
  addedApprovers2: any[] = [];
  loandurationList: any[] = [];
  productEventList: any[] = [];
  allbankusers2: [];
  glList: any[] = [];
  vatGlList: any[] = [];
  ledgerId: number;
  interestProvisionLedgerId: number;
  plInterestLedgerId: number;
  productCode: string;
  selectedUserId2: string;
  selectedUsername2: string;
  closeResult: string;
  searchText: string;
  selectedDurationList: Durations[] = [];
  selectedProductGL: ProductMappingGL[] = [];

  gleventId: any;
  glId: any;

  editEvent = false;
  addEvent = true;
  defaultCategoryId= 0;
  allPaymentType: any[] = [];
  constructor(
    private _service: ProductBuilderService,
    private _userRoleService: UserRoleAssignService,
    private _CommissionService: CommissionService,
    private _blanaceTypeService: BalancetypeService,
    private _exciseDutyService: ExciseDutyService,
    private _capitaliseRuleService: CapitalizationRuleService,
    private _intCalculationService: InterestCalculationService,
    private _paymentTypeService: PaymentTypeService,
    private _accuredService: AccuredService,
    private _capitaliseFrequencyService: CapitalizeFrequencyService,
    private _daySizeService: DaySizeService,
    private _taxKeyService: TaxKeyService,
    private _countryService: CountryInfoService,
    private _slabService: SlabService,
    private _chargeService: ChargeService,
    private _groupService: GroupService,
    private _ledgerService: LedgerService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private changeref: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private _loanProductService: LoanProductService,
    private _productEventService: ProductEventService
  ) {
    this.getProductBuilderInfoList();
  }

  ngOnInit() {
    this.populateForm();
    this.isReadOnly = false; 
    this.loadIntProLedgerListByGroupId(2);
    this.getExciseDuty(1);
    this.getDaySize(1);
   // this.getTaxKey(1);
    this.getInterestCalculationType(1);
    this.getInterestCalculationFrequency(1);
    this.getCapitalizationRule(1);
    this.getCapitalizationFrequency(1);
    this.getBalanceType();
    this.getInterestCalculation(); 
    this.getAllPaymentType();
    this.GetAllCommissionList(1);
    this.GetAllChargeList(1);
    this.getCountryList();
    this.getSlabListByType(1);
    this.getAllProductCategory();
    this.getAllUserRole();
    this.getAllLoandurationList();
    this.getAllGLList();
    this.getProductEvents(3);
    this.getProductCode(3);

    this.activeRoute.paramMap.subscribe((params) => {
      const ProductBuilderId = +params.get("id");
      if (ProductBuilderId > 0) {
        this.getProductBuilderById(ProductBuilderId);
      }
    });

    this.intCalTypeDiv = false;
  }

  onGroupChange(gId: number, typeId: number) {
    if (typeId == 3) {
      if (gId != 0 || gId != null) {
        this.getLedgerListByGroupIdForProfit(gId);
      }
    } else if (typeId == 2) {
      if (gId != 0 || gId != null) {
        this.loadIntProLedgerListByGroupId(gId);
      }
    } else if (typeId == 1) {
      if (gId != 0 || gId != null) {
        this.loadLedgerListByGroupId(gId);
      }
    }
  }

  loadLedgerListByGroupId(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerListAcc = res;
    });
  }
  getLedgerListByGroupIdForProfit(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerList = res;
    });
  }
  loadIntProLedgerListByGroupId(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerIntProList = res;
    });
  }

  
  getProductCode(categoryId: number) {
    if (categoryId > 0 && !this.isEdit) {
      this._service.getProductCodeByCategory(categoryId).subscribe((res) => {
        if (categoryId == 3) {
          this.productCode = "D" + res;
        } else {
          this.productCode = res;
        }
        this.changeref.detectChanges();
      });
    }
  }
  addMultipleDuration() {
    const check = this.selectedDurationList.find(
      (obj) => obj.id == this.frmGroup.value.tenureId
    );
    if (this.frmGroup.value.tenureId > 0 && check == undefined) {
      this.selectedDurationList.push(
        this.loandurationList.find(
          (obj) => obj.id == this.frmGroup.value.tenureId
        )
      );
    }
  }

  onDurationRemove(id: number): void {
    this.selectedDurationList = this.selectedDurationList.filter(
      (item) => item.id !== id
    );
  }

  getAllGLList() {
    this._ledgerService
      .GetParticularLedgerForPaymentVoucher()
      .subscribe((res) => {
        this.glList = res;
        // this.vatGlList = res;
      });
  }

  getProductEvents(categoryId: number) {
    this._productEventService.getProductEvent(categoryId).subscribe((res) => {
      this.productEventList = res;
    });
  }

  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      Code: [""],
      Name: [""],
      Version: [""],
      Description: [""],       
      interestRate: ["0"],       
      Currency: ["1"],
      DaySizeId: [""],       
      interestCalculationTypeSelect: [0],
      InterestCalculationFrequencyId: [""], 
      BalanceTypeId: [""],
      paymentTypeId: ["", [Validators.required]],

      inputorId: [""],
      authorizerId: [""],
      AccuredId: [""],
      status: ["1"],
      chargeId: [""],
      commissionId: [""],
      ledgerRelationId: [""],
      plInterestGroupId: [""],
      interestProvisionGroupId: [""],
      
      productCategoryId: [""],
      productType: [1],
      isVatInclude: [0],
      vatAmount: [0],
      vatGLId: [0],
      chargeTypeName: [3],
      chargeAmount: [0],
      productEventName: [0],
      glType: [""],
      eventGLId: [0],
      eventId: [0],
      effectiveDate: [""],
      loanTypeId: [0],
      //maxwithdrawalLimit: [null, [Validators.required, Validators.pattern(/^[0-9]+$/)], ],
      //overdraftLimit: [null, [Validators.required, Validators.pattern(/^[0-9]+$/)],],
      //transactionLimit: [null, [Validators.required, Validators.pattern(/^[0-9]+$/)],],
      //transactionfrequencies: [null,[Validators.required, Validators.pattern(/^[0-9]+$/)],],
      
    });
  }

  getProductDetails(id: any) {
    this.populateForm();
    this.showGlTbl = true;

    this._service.getSavingProductBuilderDetailsById(id).subscribe(
      (data: any) => {
      
console.log("agsfdsa", data)
        const effectiveDate = data?.productInfo?.effectiveDate
          ? new Date(data.productInfo.effectiveDate).toISOString().split("T")[0]
          : null;

        this.intCalTypeDiv = false;
        this.intCalTypeMultipleDiv = false;
        this.productDetails = data;
        this.frmGroup = this.formBuilder.group({
          id: [id],
          Code: [data?.productInfo?.code],
          Name: [data?.productInfo?.name],
          Version: [data?.productInfo?.version],
          productCategoryId: [data?.productInfo?.productCategoryId],
          Description: [data?.productInfo?.description],
          InterestRateMin: [data?.productInfo?.interestRateMin],
          InterestRateMax: [data?.productInfo?.interestRateMax],
          minAmount: [data?.productInfo?.minAmount],
          maxAmount: [data?.productInfo?.maxAmount],
          interestRate: [data?.productInfo?.interestRate],
          //maxwithdrawalLimit: [data?.productInfo?.maxwithdrawalLimit],
          //overdraftLimit: [data?.productInfo?.overdraftLimit],
          //transactionLimit: [data?.productInfo?.transactionLimit],
          //transactionfrequencies: [data?.productInfo?.transactionfrequencies],
          Currency: [data?.productInfo?.currencyId],
          DaySizeId: [data?.productInfo?.daySizeId],
          
          isVatInclude: ["0"],
           
          effectiveDate: [effectiveDate],
          //InterestCalculationTypeSlabId: [""],
          //interestCalculationTypeSelect: [0],
          InterestCalculationFrequencyId: [
            data?.productInfo?.interestCalculationFrequencyId,
          ],
          paymentTypeId: [
            data?.productInfo?.paymentTypeId,
          ],
          interestCalculationTypeSelect: [
            data?.productInfo?.interestCalculationTypeId,
          ],
          loanTypeId: [data?.productInfo?.accountTypeId,],
          CapitalizationRuleId: [""],
          capitalizationFrequencyId: [
            data?.productInfo?.capitalizationFrequencyId,
          ],
           
          BalanceTypeId: [data?.productInfo?.balanceTypeId],
         
          chargeId: [""],
          ledgerRelationId: [data?.productInfo?.accountGroupId],
          ledgerId: [data?.productInfo?.ledgerId],
           
          interestProvisionGroupId: [
            data?.productInfo?.interestProvisionLedger?.groupId,
          ],
          interestProvisionLedgerId: [
            data?.productInfo?.interestProvisionLedgerId,
          ],
          status: [
            data?.productInfo?.status == true ||
            data?.productInfo?.status == "true" ||
            data?.productInfo?.status == "null" ||
            data?.productInfo?.status == null
              ? 1
              : 0,
          ],

          productEventName: data?.productInfo?.productEventName || 0,
          glType: data?.productInfo?.glType
            ? data.productInfo.glType.toUpperCase()
            : "",
          eventGLId: data?.productInfo?.ledgerId || 0,
        });
        this.taxKeyDetails = [];
        this.taxKey = null;
        this.taxKeyList?.forEach((e) => {
          if (
            e != null &&
            e != "null" &&
            this.frmGroup.value.taxKeyId == e.taxKey.id
          ) {
            this.taxKey = e.taxDetails[0].taxKey;
            this.taxKeyDetails = e.taxDetails;
          }
        });
        this.daySize = null;
        this.daySizeList?.forEach((e) => {
          if (this.frmGroup.value.DaySizeId == e.id) {
            this.daySize = e;
          }
        });
        
         
        this.storeIntCalTypeDetails = [];
        this.storeIntCalType = null;
        this.intCalTypeDetails?.forEach((e) => {
          if (
            e != null &&
            e != "null" &&
            this.frmGroup.value.InterestCalculationTypeId ==
              e.interestCalculationTypeId
          ) {
            this.storeIntCalTypeDetails.push(e);
            this.storeIntCalType = e.interestCalculationType;
          }
        });
        if (data?.productInfo?.interestCalculationTypeId == null) {
          this.intCalTypeDiv = false;
          this.intCalTypeMultipleDiv = true;
        }
        data?.productCommission?.forEach((e) => {
          if (e != null && e != "null") {
            this.selectedCommissionList.push(e.commission);
          }
        });
        data?.charge?.forEach((e) => {
          if (e != null && e != "null") {
            this.selectedChargeList.push(e.charge);
          }
        });
        data?.productRules?.forEach((e) => {
          if (e != null && e != "null") {
            this.capRuleSelectList.push(e.rule);
          }
        });
        data?.productInstCalTypes?.forEach((e) => {
          if (e != null && e != "null") {
            this.instCalTypeSlab.push(e.slab);
          }
        });
        data?.approver?.forEach((e) => {
          if (e != null && e != "null") {
            const newObject: any = {
              order: e.order,
              roleId: e.roleId,
              roleName: e.role?.name,
            };
            this.addedApprovers2.push(newObject);
          }
        });
        if (data?.productInfo?.accountGroupId > 0) {
          this.onGroupChange(data?.productInfo?.accountGroupId, 1);
        }
        if (data?.productInfo?.plInterestLedgerId > 0) {
          this.onGroupChange(data?.productInfo?.plInterestLedger?.groupId, 3);
        }
        if (data?.productInfo?.interestProvisionLedgerId > 0) {
          this.onGroupChange(
            data?.productInfo?.interestProvisionLedger?.groupId,
            2
          );
        }

        data?.dpsTenures?.forEach((e) => {
          if (e != null && e != "null") {
            const newObject: any = {
              id: e.durationId,
              monthName: e.duration.monthName,
            };
            this.selectedDurationList.push(newObject);
          }
        });

        data?.productCharge?.forEach((e) => {
          if (e != null && e != "null") {
            const newObject: any = {
              eventId: e.productEventId,
              productEventName: e.productEventId,
              accountCode: e.ledgerRelation?.ledger?.accountCode,
              ledgerId: e.ledgerRelation?.ledger?.id,
              accountName: e.ledgerRelation?.ledger?.accountName,
              eventCode: e.productEvent?.eventCode,
              eventName: e.productEvent?.eventName,
              ledgerRelationId: e.ledgerRelationId,
              isVat: 0,
              chargeAmount: 0,
              chargeTypeName: "",
              chargeTypeId: 0,
              glType: e.glType,
            };
            this.mappedGLList.push(newObject);
          }
        });
      },
      (error) => {
        console.error("Error fetching product details:", error);
      }
    );
  }
  editId: number = 0;
  isReadOnly: boolean = false;
  getProductBuilderById(id: number) {
    this.isEdit = true;
    this.isReadOnly = true;
    this.editId = id;
    this.getProductDetails(id);
  }

  getProductBuilderInfoList() {
    this._service.getList().subscribe((res) => {
      this.list = res;
    });
  }

  onsubmit() {
    debugger
    if (!this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    } else {
      this.model.id = this.editId;
    }

    this.model.Code = this.frmGroup.value.Code;
      this.model.Name = this.frmGroup.value.Name;
      this.model.Version = this.frmGroup.value.Version;
      this.model.Description = this.frmGroup.value.Description;
      this.model.Currency = this.frmGroup.value.Currency;
      this.model.DaySizeId = this.frmGroup.value.DaySizeId;
      this.model.InterestCalculationFrequencyId = this.frmGroup.value.InterestCalculationFrequencyId;
      this.model.BalanceTypeId = this.frmGroup.value.BalanceTypeId;
      this.model.InterestCalculationTypeId = this.frmGroup.value.interestCalculationTypeSelect;
      this.model.selectedChargeId = this.selectedChargeList;      
      this.model.effectiveDate = this.frmGroup.value.effectiveDate;
      this.model.productGLMappings = this.mappedGLList;
      this.model.loanTypeId = this.frmGroup.value.loanTypeId;
      this.model.paymentTypeId = this.frmGroup.value.paymentTypeId;
      this.model.interestRate = this.frmGroup.value.interestRate;
      //this.model.maxwithdrawalLimit = this.frmGroup.value.maxwithdrawalLimit;
      //this.model.overdraftLimit = this.frmGroup.value.overdraftLimit;
      //this.model.transactionLimit = this.frmGroup.value.transactionLimit;
      //this.model.transactionfrequencies = this.frmGroup.value.transactionfrequencies;
      this.model.approvers = this.addedApprovers2; 

    Swal.fire({
      title: "Are you sure?",
      text: this.isEdit ? "You want to update!" : "You want to save!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.isEdit ? "Update!" : "Save!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this._loanProductService
          .save(this.model)
          .pipe(
            catchError((error) => {
              if (error.error.message.indexOf("UNIQUE KEY") !== -1) {
                Swal.fire({
                  title: "Failed?",
                  text: "Version are already exist.",
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonText: "Ok",
                });
              }
              return throwError(error);
            })
          )
          .subscribe((res) => {
            console.log("sdfisdfhi", res)
            Swal.fire({
              title: "Success?",
              text: this.isEdit ? "Update success!" : "Save success!",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Ok",
            }).then((e) => {
              this.getProductBuilderInfoList();
              this.router.navigate(["/product/deposit-product-builder-list"]);
            });
          });
      }
    });
  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }
   

  getAllUserRole() {
    this._userRoleService.GetAllRole().subscribe((res) => {
      this.allbankusers2 = res;
    });
  }

   
  getAllProductCategory() {
    this._blanaceTypeService.getDepistCategorylist().subscribe((res) => {
      this.categoryList = res; 
      
      if (res && res.length > 0) {
        this.defaultCategoryId = res[0].id; 
        this.GetAllChargeList(this.defaultCategoryId);
        
      } else {
        console.warn("No categories found in the response.");
      }
    });
  }
  
  getSlabListByType(id: number) {
    this._slabService.getlistByProBuilderType(id).subscribe((res) => {
      this.allSlab = res;
    });
  }
  // getBalanceType(id: number) {
  //   this._blanaceTypeService.getlistByProBulderType(id).subscribe((res) => {
  //     this.balanceTypeList = res;
  //   });
  // }
  getBalanceType() {
    this._blanaceTypeService.getBalanceTypeListDataType().subscribe((res) => {       
      this.balanceTypeList = res;
    });
  }
  getExciseDuty(id: number) {
    this._exciseDutyService.getExciseDutyListByType(id).subscribe((res) => {
      for (const exDuty of res) {
        this.exciseDutyList.push(exDuty["exciseDuty"]);
        if (exDuty["exciseDutyDetails"].length > 0) {
          for (const dtt of exDuty["exciseDutyDetails"]) {
            this.exciseDutyDetails.push(dtt);
          }
        }
      }
    });
  }
  onExciseDutyChange() {
    this.exciseDuty = null;
    this.sortExcDutyDetails = [];
    for (const dt of this.exciseDutyDetails) {
      if (this.frmGroup.value.exciseDutyId == dt.exciseDutyId) {
        this.sortExcDutyDetails.push(dt);
        this.exciseDuty = dt.exciseDuty;
      }
    }
  }
  getCapitalizationRule(id: number) {
    this._capitaliseRuleService.getlistByProBuilderType(id).subscribe((res) => {
      this.capRuleList = res;
    });
  }
  onCapitalizeRuleChange() {
    this.capRule = null;
    this.capRule = this.capRuleList.find(
      (obj) => obj.id == this.frmGroup.value.CapitalizationRuleId
    );
  }
  addMultipleCapRuleMulti() {
    const check = this.capRuleSelectList.find(
      (obj) => obj.id == this.frmGroup.value.capitalizeRuleSlabId
    );
    if (this.frmGroup.value.CapitalizationRuleId > 0 && check == undefined) {
      this.capRuleSelectList.push(
        this.capRuleList.find(
          (obj) => obj.id == this.frmGroup.value.CapitalizationRuleId
        )
      );
    }
  }
  getCapitalizationFrequency(id: number) {
    this._capitaliseFrequencyService
      .getCapFreqListByProBuildeType(id)
      .subscribe((res) => {
        this.capFrequencyList = res;
      });
  }
  onCalTypeSelect() {
    this.frmGroup.value.interestCalculationTypeId = 0;
    if (this.frmGroup.value.interestCalculationTypeSelect == "0") {
      this.intCalTypeDiv = false;
      this.intCalTypeMultipleDiv = false;
    } else {
      this.intCalTypeDiv = true;
      this.intCalTypeMultipleDiv = true;
    }
  }
  addSlabForIntCalTypeMulti() {
    const check = this.instCalTypeSlab.find(
      (obj) => obj.id == this.frmGroup.value.InterestCalculationTypeSlabId
    );
    if (
      this.frmGroup.value.InterestCalculationTypeSlabId > 0 &&
      check == undefined
    ) {
      this.instCalTypeSlab.push(
        this.allSlab.find(
          (obj) => obj.id == this.frmGroup.value.InterestCalculationTypeSlabId
        )
      );
    }
  }
  getInterestCalculationType(id: number) {
    this.intCalculationTypeList = [];
    this.intCalTypeDetails = [];
    this._intCalculationService
      .GetAllInterestCalculationTypeByProductType(id)
      .subscribe((res) => {
        for (const type of res) {
          this.intCalculationTypeList.push(type["instCalType"]);
          if (type["instCalTypeDetail"].length > 0) {
            for (const dtt of type["instCalTypeDetail"]) {
              this.intCalTypeDetails.push(dtt);
            }
          }
        }
      });
  }
  onCalTypeChange() {
    this.storeIntCalTypeDetails = [];
    this.storeIntCalType = null;
    for (const ty of this.intCalTypeDetails) {
      if (
        this.frmGroup.value.InterestCalculationTypeId ==
        ty.interestCalculationTypeId
      ) {
        this.storeIntCalTypeDetails.push(ty);
        this.storeIntCalType = ty.interestCalculationType;
      }
    }
  }
  onCalTypeSlabChange() {
    this.storeIntCalType = null;
    this.storeIntCalType = this.allSlab.find(
      (obj) => obj.id == this.frmGroup.value.InterestCalculationTypeSlabId
    );
  }
  getAccured() {
    this._accuredService.getAccuredList().subscribe((res) => {
      this.accuredList = res;
    });
  }
  GetAllCommissionList(id: number) {
    this._CommissionService
      .GetAllCommissionListByProBuilderType(id)
      .subscribe((res) => {
        this.commissionList = res;
      });
  }
  onCommissionChange() {
    this.commission = null;
    this.commission = this.commissionList.find(
      (obj) => obj.id == this.frmGroup.value.commissionId
    );
  }
  addMultipleCommission() {
    const check = this.selectedCommissionList.find(
      (obj) => obj.id == this.frmGroup.value.commissionId
    );
    if (this.frmGroup.value.commissionId > 0 && check == undefined) {
      this.selectedCommissionList.push(
        this.commissionList.find(
          (obj) => obj.id == this.frmGroup.value.commissionId
        )
      );
    }
  }
  GetAllChargeList(id: number) {
    this._chargeService
      .GetAllChargeListByProBuilderType(id)
      .subscribe((res) => {
        this.chargeList = res;
        //console.log("chargeList", this.chargeList);
      });
  }
   
  onChargeChange() {
    this.charge = null;
    this.charge = this.chargeList.find(
      (obj) => obj.id == this.frmGroup.value.chargeId
    );
  }
  addMultipleCharge() {
    const check = this.selectedChargeList.find(
      (obj) => obj.id == this.frmGroup.value.chargeId
    );
    if (this.frmGroup.value.chargeId > 0 && check == undefined) {
      this.selectedChargeList.push(
        this.chargeList.find((obj) => obj.id == this.frmGroup.value.chargeId)
      );
    }
  }
  getInterestCalculationFrequency(id: number) {
    this._intCalculationService
      .getFrequencyListByProductType(id)
      .subscribe((res) => {
        this.intCalculationFreqList = res;
      });
  }
  getDaySize(id: number) {
    this._daySizeService.getDaySizeListByProductId(id).subscribe((res) => {
      this.daySizeList = res;
    });
  }
  getCountryList() {
    this._countryService.getAllGetCurrencyInfo().subscribe((res) => {
      this.currencyList = res;
    });
  }
   
  onDaySizeChange() {
    this.daySize = null;
    for (const dt of this.daySizeList) {
      if (this.frmGroup.value.DaySizeId == dt.id) {
        this.daySize = dt;
      }
    }
  }

  openModal(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  openBuilderDetailsModal(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  goBack() {
    this.location.back();
  }

  getAllLoandurationList() {
    this._loanProductService.getAllLoanDuration().subscribe((res) => {
      this.loandurationList = res;
    });
  }

  fnAddSelectedGL(
    id: number,
    glcode: string,
    glname: string,
    eventName: string,
    eventCode: string,
    ledgerRelId: number
  ): void {
    const exists = this.selectedProductGL.some((item) => item.chargeId === id);

    if (exists) {
      alert("This item is already selected.");
      return;
    }

    this.selectedProductGL.push(
      new ProductMappingGL(
        id,
        glcode,
        glname,
        eventCode,
        eventName,
        ledgerRelId
      )
    );
  }

  fnRemoveGL(id: number): void {
    this.selectedProductGL = this.selectedProductGL.filter(
      (item) => item.chargeId !== id
    );
  }
  ///////////////////////////////////////
  gleventData(id: any) {
    this.gleventId = id;
  }

  eventGLIdData(selectedItem: any): void {
    const selectedId = selectedItem?.id;
    this.glId = selectedId;
  }

  editMappedGL(item: any): void {
    this.editEvent = true;
    this.addEvent = false;

    this.frmGroup.patchValue({
      productEventName: item?.productEventName ?? 0,
      glType: item?.glType.toUpperCase() === "DR" ? "DR" : "CR",
      eventGLId: item?.ledgerRelationId ?? 0,
    });
  }

  fnAddMappedGL() {
    if (
      this.frmGroup.value.productEventName == 0 ||
      this.frmGroup.value.glType == 0 ||
      this.frmGroup.value.eventGLId == ""
    ) {
      return false;
    }

    const id = this.frmGroup.value.productEventName;
    //const id = this.gleventId;

    this.mappedGLList = this.mappedGLList.filter(
      (items) => items.eventId !== id
    );

    const exists = this.mappedGLList.some(
      (item) => item.eventId === parseInt(id)
    );
    if (exists) {
      alert("This item is already Added.");
      return false;
    }

    this.showGlTbl = true;

    const eventss = this.productEventList.find(
      (event) => String(event.id) === String(id)
    );

    const eventName = eventss ? eventss.eventName : null;
    const eventGLId = this.frmGroup.value.eventGLId;
    //const eventGLId = this.glId;

    const selectedGL = this.glList.find((x) => x.id === eventGLId);

    const eventGLCode = selectedGL ? selectedGL.ledger?.accountCode : null;
    const eventGLName = selectedGL ? selectedGL.ledger?.accountName : null;
    const accountName = selectedGL ? selectedGL.ledger?.accountName : null;
    const eventCode = eventss ? eventss.eventCode : null;
    const chargeTypeId = this.frmGroup.value.chargeTypeName;
    let chargeTypeName = "";
    switch (this.frmGroup.value.chargeTypeName) {
      case "1":
        chargeTypeName = "Fixed";
        break;
      case "2":
        chargeTypeName = "Percentage";
        break;
      default:
        chargeTypeName = "";
        break;
    }
    const chargeAmount = this.frmGroup.value.chargeAmount;
    //const isVatInclude = this.frmGroup.value.isVatInclude;
    const isVatInclude = 0;

    const vatAmount = this.frmGroup.value.vatAmount;
    const vatGLId = this.frmGroup.value.vatGLId;
    const glType = this.frmGroup.value.glType;

    this.mappedGLList.push(
      new ProductGLList(
        id,
        eventGLCode,
        eventGLName,
        eventCode,
        eventName,
        eventGLId,
        chargeAmount,
        chargeTypeName,
        chargeTypeId,
        isVatInclude,
        vatAmount,
        vatGLId,
        glType
      )
    );
  }

  removeMappedGL(id: number) {
    this.mappedGLList = this.mappedGLList.filter((item) => item.eventId !== id);
    // this.mappedGLList = this.mappedGLList.filter(
    //   (item) => item.productEventId !== id
    // );
    this.changeref.detectChanges();
  }
  getInterestCalculation() {
    this._intCalculationService.getInterestCalculationType().subscribe((res) => {
       
      this.intCalculationList = res;
    });
  }
  getAllPaymentType() {
    this._paymentTypeService.getPaymentTypeList().subscribe((res) => {
      this.allPaymentType = res;
    });
  }

  ChangeApprover2(target) {
    this.selectedUserId2 = target.value;
    this.selectedUsername2 = target.selectedOptions[0].innerText;
  }
  AddApprover2() {
    const newObject: any = {
      order: this.addedApprovers2.length + 1,
      roleId: this.selectedUserId2,
      roleName: this.selectedUsername2,
    };
    this.addedApprovers2.push(newObject);
  }
}
