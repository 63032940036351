import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: "root",
})
export class VoucherInfoService {
  constructor(private http: HttpClient) {}
  getVoucherMasterWithDetailsList(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/CashTransaction/GetVouchermasterInfoWithDetails"
    );
  }

  getVoucherMasterList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CashTransaction/GetVouchermasterInfo");
  }

  getVoucherMasterListWithSearchAndPagination(
    currentPage: any,
    itemsPerPage: any,
    searchValue: string,
    fromDate: any,
    toDate: any
  ) {
    return this.http.get(
      dcbsUrl +
        "/api/CashTransaction/GetVoucherMasterListWithSearchAndPagination?page=" +
        currentPage +
        "&pageSize=" +
        itemsPerPage +
        "&search=" +
        searchValue +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate
    );
  }

  getVoucherMasterListFromDateToDate(fromDate: Date, toDate: Date) {
    return this.http.get(
      dcbsUrl +
        "/api/CashTransaction/GetVoucherMasterListFromDateToDate?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate
    );
  }

  getVoucherDetailsByMasterId(id: any): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/CashTransaction/GetVoucherDetailsInfoByMasterId?id=" + id
    );
  }


  getCustomerWiseBreakDown(vId: any): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/CashTransaction/getCustomerWiseBreakDown?vId=" + vId
    );
  }


  getDailyBreakDown(vId: any, accId: number): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/CashTransaction/getDailyBreakDown?vId=" + vId + "&accId=" + accId
    );
  }

  approveVoucherMasterById(id: any): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/CashTransaction/AppvoveVoucherMasterByMasterId?id=" + id
    );
  }

  SaveVoucher(model: any) {

    return this.http.post(dcbsUrl + "/api/CashTransaction/SaveVoucher", model);
  }
  SaveJournalVoucher(model: any) {

    return this.http.post(
      dcbsUrl + "/api/CashTransaction/SaveJournalVoucher",
      model
    );
  }


  getVoucherMasterListWithDate(fromDate: any, toDate: any) {
    return this.http.get(
      dcbsUrl +
        "/api/VoucherListReportExcel?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate,
        { observe: "response", responseType: "blob" }
    );
  }

  // getVoucherMasterListWithDate(fromDate: Date,toDate: Date) {
  //   return this.http.get(
  //     apiUrl +
  //       "/AccountingReport/VoucherListReportExcel?fromDate=" +
  //       fromDate +
  //       "&toDate=" +
  //       toDate,
  //       { observe: "response", responseType: "blob" }
  //   );
  // }

}
