import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class SubGroupService {

  constructor(private http:HttpClient) { }
  GetAccountGroupList():Observable<any>{
    return this.http.get(dcbsUrl+'/api/AccountGroup/GetAccountSubGroupList');
  }

  GetAccountSubGroupList(currentPage: any, itemsPerPage : any, searchValue : string):Observable<any>{
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetAccountSubGroupWithPaginationAndSearch?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }

   SaveAccountGroup(model:any){
    return this.http.post(dcbsUrl +'/api/AccountGroup/SaveAccountGroup',model);
   }

   DeleteaccountGroupById(id: any) {
    return this.http.delete(dcbsUrl + '/api/AccountGroup/DeleteaccountGroupById?Id=' + id)
  }


}
