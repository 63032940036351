import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;


@Injectable({
  providedIn: 'root'
})



export class RelationServiceService {

  constructor(private http: HttpClient) { }

  getRelationByYid(id: number){
    return this.http.get(dcbsUrl + '/api/Relation/GetRelationById?id=' +id);
  }

  save(model : any){
    //alert(22);
    return this.http.post(dcbsUrl + '/api/Relation/SaveRelation', model);
  }

  getRelationList():Observable<any>{
    return this.http.get(dcbsUrl + '/api/Relation/GetRelationList');
  }
  GetRelationById(id: number){
    return this.http.get(dcbsUrl + '/api/Relation/GetRelationById/' +id);
  }
  GetIncomeSourceById(id: number){
    return this.http.get(dcbsUrl + '/api/ProductBuilder/GetIncomeSourceById/' +id);
  }

  delete(id:any){
    //alert(55);
    return this.http.get(dcbsUrl + '/api/Relation/DeleteRelationById/' +id);
  }

  getListWithSearchAndPagination(currentPage: any, itemsPerPage : any, searchValue : string){
    return this.http.get(dcbsUrl + '/api/Relation/GetRelationListWithSerchAndPagination?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }
}
