import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CashPostingService } from '../service/cash-posting.service';
import { LedgerService } from '../service/ledger.service';
import { SharedService } from 'app/services/shared.service';
import { CashPosting } from '../model/cash-posting';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { Observable, of } from 'rxjs';
import { CustomerService } from 'app/customer/customer.service';
import swal from 'sweetalert';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-fund-transfer',
  templateUrl: './fund-transfer.component.html',
  styleUrls: ['./fund-transfer.component.scss']
})
export class FundTransferComponent implements OnInit {

  selectedOption: string;
  customerIdPayment: number;
  customerAccountId: number;
  customerAccountId2: number;
  currentBalance: number;
  transactionLimit: number;
  isvalid: boolean = true;
  isBtnValid: boolean = false;
  exchangeAmount: number;
  accountNo: string = '';
  accountNoRecieve: string = '';
  accountNoFund1: string = '';
  accountNoFund2: string = '';

  customerInfo_cashFundTransfer1: any = '';
  customerInfo_cashFundTransfer2: any = '';

  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: CashPosting[] = [];
  model = new CashPosting();
  form: FormGroup;
  formControl = new FormControl();
  suggestions: any[] = [];
  hideShow: string = 'Show';
  searchType: string = '';

  constructor(
    private router: Router,
    private _service: CashPostingService,
    private _LedgerService: LedgerService,
    private _CountryInfoService: CountryInfoService,
    private _CustomerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,

    private formBuilder: FormBuilder,
    private shared: SharedService,
  ) {
    {
      this.frmGroup = new FormGroup({
        id: new FormControl(),
        customerCode: new FormControl(),
        transactionType: new FormControl(),
        customerId: new FormControl(),
        voucherNo: new FormControl(),
        postingType: new FormControl(),
        remarks: new FormControl(),
        currencyId: new FormControl(),
        voucherAmount: new FormControl(),
        exchangeAmount: new FormControl(),

      });
       
    }


  }

  ngOnInit(): void {
    this.loadLedgerList();
    //this.loadCountryList(); 
    this.formControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(value => this._LedgerService.getAutocompleteSuggestions(value))
    ).subscribe(data => {
      this.suggestions = data;
      
    });
    this.hideShow = 'Show';

  }
  selectedSuggestion: any = null;

  selectSuggestion(item: any) {
    this.formControl.setValue(item.bdGenerateCode);
    //this.suggestions = [];
    this.selectedSuggestion = item;
    this.hideShow = 'Hide';
  }
  hideAfterSelectValue(value: any) { 
    this.hideShow = value;
  }
  selectSearchType(type: string) {
    this.searchType = type;
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  LedgerList: Observable<any[]>;
  loadLedgerList() {
    this._LedgerService.getLedgerRelationlist().subscribe(res => {

      this.LedgerList = of(res);
    });
  }

   

  onsubmit() {
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    else {
      this.model.id = 0;
    }

    this.model.customerCode = this.frmGroup.value.customerCode;
    this.model.transactionType = 3;
    this.model.customerAccountId = this.customerAccountId;
    this.model.customerAccountId2 = this.customerAccountId2;
    this.model.voucherNo = this.frmGroup.value.voucherNo;
    this.model.postingType = this.frmGroup.value.postingType;
    this.model.remarks = this.frmGroup.value.remarks;
    this.model.currencyId = this.frmGroup.value.currencyId;
    //this.model.voucherAmount = this.frmGroup.value.voucherAmount;
    this.model.voucherAmount = this.frmGroup.value.exchangeAmount;   


    if (this.model.voucherAmount > 0 && this.model.currencyId > 0) {

      this._CustomerService.getProductBuilderByAccountNo(this.accountNoFund1).subscribe(res =>{   
        this.transactionLimit = res.monthlyTRMaxAmount; 
        if(this.transactionLimit >= this.model.voucherAmount){
          if (this.model.id == 0) {
         

            this._service.save(this.model).subscribe((res: number) => {
              if (res > 0) {
                this.ResetForm();
    
                this.shared.ShowAlert("Success", "Save Successfully", "success");
                this.isEdit = false;
              }
              else {
                this.shared.ShowAlert("Warning", "Failed to Save", "warning");
                this.isEdit = false;
              }
    
            })
          }
          else {
            this._service.save(this.model).subscribe(res => {
              this.ResetForm();
    
              this.shared.ShowAlert("Success", "Save Successfully", "success");
              //this.getLedgerList();
              this.isEdit = false;
              //this.showSaveForm='saveList';
            })
          }
        }
        else{
          this._service.savePanddingTransfer(this.model).subscribe((res: number) => {
            if (res > 0) {
              this.ResetForm();
  
              this.shared.ShowAlert("Success", "Transaction Pending and Sent for approval", "success");
              this.isEdit = false;
            }
            else {
              this.shared.ShowAlert("Warning", "Failed to Transaction", "warning");
              this.isEdit = false;
            }
  
          })
        }
      })

       
    } 
 
  }


  

  

  loadUserDataByAccountNoFundFirst() {
    this._CustomerService.getCustomerByAccountNo(this.accountNoFund1).subscribe(res => {
      if (res.statusMsg == "Success") {
        this.currentBalance = res.currentBalance;
        this.customerInfo_cashFundTransfer1 = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
        
        this.customerAccountId = res.id;
        this.isBtnValid = true;

         this._CustomerService.getCurrencyByCustomerAccountId(res.id).subscribe(res =>{
          
             this.countryList = res;
         })
      }
      else {
        this.refreshAc();
        swal('Warning', 'Invalid Account No', 'warning');
      }
    })
  }


  loadUserDataByAccountNoFundLast() {
    this._CustomerService.getCustomerByAccountNo(this.accountNoFund2).subscribe(res => {
      if (res.statusMsg == "Success") {
        if(res.id == this.customerAccountId){
          swal('Warning', 'The same account cannot be transferred', 'warning');
        }
        else{
          this.customerInfo_cashFundTransfer2 = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
           this.customerAccountId2 = res.id;
          this.isBtnValid = true;
        }
         
      }
      else {
        //this.refreshAc();
        swal('Warning', 'Invalid Account No', 'warning');
      }
    })
  }

  refreshAc() {
    this.currentBalance = 0;
    this.customerInfo_cashFundTransfer1 = '';
    this.customerInfo_cashFundTransfer2 = '';
    this.customerIdPayment = 0;
    this.customerAccountId = 0;
    this.isBtnValid = false;
  }

   

  countryList: any[] = [];
  selectedExchangeRate: number = 1; // Default to 1 if no currency is selected
  
  // loadCountryList() {
  //   this._CountryInfoService.getAllGetCurrencyInfo().subscribe((res) => {
  //     this.countryList = res;
  //   });
  // }
  
  onCurrencyChange(event: Event) {
    const selectedCurrencyId = (event.target as HTMLSelectElement).value; 
     const selectedCurrency = this.countryList.find(
       
      (item) => item.currency?.id == selectedCurrencyId
       
    );
  
    this.selectedExchangeRate = selectedCurrency.currency
      ? selectedCurrency.currency.exchangeRate
      : 1;
  
    this.CheckBalance();  
  }
  
  CheckBalance() {
    const { currentBalance, frmGroup, selectedExchangeRate } = this;
    const inputValue = frmGroup.value.voucherAmount;
    const exchangeAmount = inputValue * selectedExchangeRate;  
    frmGroup.patchValue({ exchangeAmount });
     this.isvalid =
      currentBalance >= exchangeAmount;
  }
  
  ResetForm() {
    this.frmGroup.reset();
    this.customerInfo_cashFundTransfer1 = '';
    this.customerInfo_cashFundTransfer2 = '';
  }

  

}
