import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class AccountBalanceService {

  constructor(private http:HttpClient) { }
  getlist():Observable<any>
  {
    return this.http.get(dcbsUrl + '/api/CashTransaction/GetAccountBalanceAll');
  }
}
