<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-8 col-6">
                    <h5 class="card-title page-heading" style="color: black">All Products</h5>
                </div>
                <div class="col-md-3 col-6 text-right">
                    <button type="button" data-toggle="tooltip" [routerLink]="['/product/deposit-product-builder']" class="btn btn-info btn-gotoadd" style="margin-right: 5px; border: none;">Product Entry</button>
                </div>
                <div class="col-md-1 text-right">
                    <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()">
                        <i class="icon-arrow-left" style="font-size: 17px;"></i>
                    </button>
                </div>
            </div>
            <hr>
            <div class="card-body p-0" style="overflow: auto; max-height: 700px;">
                <table id="example" class="table table-striped table-bordered scrollable-table" style="width:100%">
                    <thead style="position: sticky; top: -2px; z-index: 1; background-color: #EEF5E9">
                        <tr>
                            <th>SL No.</th>
                            <th style="width: 20%;">Actions</th>
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Version</th>
                            <th>Effective Date</th>
                            <th>Status</th>
                            <th>Approve Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of list; let i = index;">
                            <td>{{i + 1}}.</td>
                            <td style="width: 20%;">
                                <button class="btn btn-info btn-sm mb-1" (click)="openBuilderDetailsModal(productBuilderDetails); getProductDetails(item.id)"><i class="fa fa-list"></i></button>
                                <button *ngIf="item.createdBy == userName && item.productStatusId != 6 && item.productStatusId != 2 && item.productStatusId != 3" class="btn btn-primary btn-sm mb-1" (click)="edit(item)"><i class="fa fa-edit"></i></button>
                                <!-- <button *ngIf="item.createdBy == userName && item.productStatusId == 3 && item.status == 0" [disabled]="isEdit" class="btn btn-secondary mb-1 btn-sm" (click)="delete(item.id, item.status === true || item.status === null ? false : true)"><i class="fa fa-times"></i></button> -->
                            </td>
                            <td>{{item.code}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.version}}</td>
                            <td style="white-space: nowrap;">{{item.effectiveDate | date: dateFormat}}</td>
                            <td class="text-center">
                                <span class="badge badge-{{item.status == 1 ? 'success' : 'danger'}}" style="width: 100%;">{{item.status === true || item.status === null ? 'Active' : "In-Active"}}</span>
                            </td>
                            <td>
                                <span [ngClass]="{ 'red-underline': item.productStatusId == 4 }" [ngStyle]="{ 'color': item.productStatusId == 3 ? 'green' : 'inherit' }">{{ item.productStatus?.statusName }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
  
  <ng-template #productBuilderDetails let-c="close" let-d="dismiss">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Product Informations</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tr>
                            <th>Product Name</th>
                            <td>{{ productDetails?.productInfo?.name }}</td>
                            <th>Product Category</th>
                            <td>{{ productDetails?.productInfo?.categoryName }}</td>
                        </tr>
                        <tr>
                            <th>Version:</th>
                            <td>{{ productDetails?.productInfo?.version }}</td>
                            <th>Product Code:</th>
                            <td>{{ productDetails?.productInfo?.code }}</td>
                        </tr>
                        <tr>
                            <th>Description:</th>
                            <td>{{ productDetails?.productInfo?.description }}</td>
                            <th>Interest Rate:</th>
                            <td>{{productDetails?.productInfo?.interestRate}} <span>%</span></td>
                        </tr>
                         
                        <tr>
                            <th>Currency:</th>
                            <td>{{ productDetails?.productInfo?.currencyInfo?.code }}</td>
                            <th>Day Size:</th>
                            <td>{{ productDetails?.productInfo.daySize?.name }}</td>
                        </tr>
                         
                        <tr>
                            <th>Interest Calculation Type:</th>
                            <td>{{ productDetails?.productInfo?.interestCalculationType?.name }}</td>
                            <th>Interest Calculation Frequency:</th>
                            <td>{{productDetails?.productInfo?.interestCalculationFrequency?.name}} - {{productDetails?.productInfo?.interestCalculationFrequency?.days}} {{productDetails?.productInfo?.interestCalculationFrequency?.days != null ? "days" : ""}}</td>
                        </tr>
                        <tr>
                            <th>Balance Type:</th>
                            <td>{{ productDetails?.productInfo?.balanceType?.name }}</td>
                            <th>Status:</th>
                            <td>{{ productDetails?.productInfo?.status ? "Active" : "In-Active" }}</td>
                        </tr>
                        
                        
                        <tr>
                            <th>Created Date:</th>
                            <td>{{productDetails?.productInfo?.productCreateDate | date: dateFormat}}</td>
                            <th>Modification Date:</th>
                            <td>{{productDetails?.productInfo?.modificationDate | date: dateFormat}}</td>
                        </tr>
                        <tr>
                            <th>Charges:</th>
                            <td>
                                <ul *ngFor="let item of productDetails?.charges" style="padding-left: 3px;">
                                    <li style="text-align: left; border: none; padding: 0px;">{{item?.charge.name}}-{{item?.charge.code}}</li> 
                                </ul>
                            </td>
                            <th>Effective Date:</th>
                            <td>{{productDetails?.productInfo?.effectiveDate | date: dateFormat}}</td>
                             
                        </tr>
                        
                    </table>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered" style="border-color:black; font-size: 13px;">
                        <thead style="background-color:lightgray;border-bottom:groove;">
                            <tr>
                                <th style="text-align:left;vertical-align:middle">Event Name</th>
                                <th style="text-align:left;vertical-align:middle">GL Name</th>
                                <th style="text-align:left;vertical-align:middle">Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of productDetails?.productCharges">
                                <td style="text-align: left; vertical-align:middle">{{item?.productEvent?.eventName}}</td>
                                <td style="text-align: left; vertical-align:middle">{{item?.ledgerRelation?.ledger?.accountName}}</td>
                                <td style="text-align: left; vertical-align:middle">{{item?.glType == 'DR' ? 'Debit' : 'Credit'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button btn-sm"  class="btn" style="background-color: #40C057;color:white" (click)="download()">Download</button>
                <button type="button btn-sm" class="btn btn-secondary" (click)="c('Close click')">Close</button>
            </div>
        </div>
    </div>
  </ng-template>
  