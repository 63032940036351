import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';
const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: "root",
})
export class LedgerService {
  constructor(private http: HttpClient) {}

  GetLedgerById(id: number): Observable<any> {
    return this.http.get(dcbsUrl + "/GetLedgerById/" + id);
  }
  save(model: any) {
    return this.http.post(dcbsUrl + "/api/Ledger/SaveLedger", model);
  }

  getlist(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ledger/GetLedgerList");
  }

  GetLedgerListByNatureId(gId: number) {
    return this.http.get(
      dcbsUrl + "/api/ledger/GetLedgerListByNatureId?gId=" + gId
    );
  }

  GetLedgerListWithGLByNatureId(gId: number) {
    return this.http.get(
      dcbsUrl + "/api/ledger/GetLedgerListWithGLByNatureId?gId=" + gId
    );
  }

  getGrpLedgerList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ledger/GetGrpLedgerList");
  }

  getGroupListWithSearchAndPagination(
    currentPage: any,
    itemsPerPage: any,
    searchValue: string
  ) {
    //alert(220);
    return this.http.get(
      dcbsUrl +
        "/api/Ledger/GetGroupLedgerListWithSearchAndPagination?page=" +
        currentPage +
        "&pageSize=" +
        itemsPerPage +
        "&search=" +
        searchValue
    );
  }

  getSubGrpLedgerList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ledger/GetSubGrpLedgerList");
  }

  getSubGroupListWithSearchAndPagination(
    currentPage: any,
    itemsPerPage: any,
    searchValue: string
  ) {
    //alert(333);
    return this.http.get(
      dcbsUrl +
        "/api/Ledger/GetSubGroupLedgerListWithSearchAndPagination?subGroupPage=" +
        currentPage +
        "&subGroupPageSize=" +
        itemsPerPage +
        "&subGroupSearch=" +
        searchValue
    );
  }

  getLedgerRelationlist(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ledger/GetLedgerRelationlist");
  }

  getMFSLedgerRelation(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ledger/GetMFSLedgerRelationlist");
  }

  getWitoutSubLedgerRelationlist(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/ledger/GetLedgerRelationlistWithOutSubledger"
    );
  }
  GetBusinessDatebyActiveStatus(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Ledger/GetBusinessDatebyActiveStatus");
  }
  GetAccountCode(gId: number) {
    return this.http.get(
      dcbsUrl + "/api/ledger/GetAccountCodeByGroupId?gId=" + gId
    );
  }

  delete(Id: any) {
    return this.http.delete(dcbsUrl + "/api/ledger/DeleteLegerById?Id=" + Id);
  }

  //#region GLGenerate
  GetAllLedger(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ledger/GetAllLedger");
  }
  GetLedgerListWithOutGL(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ledger/GetLedgerListWithOutGL");
  }
  PostGLGenerate(model: any) {
    //debugger
    return this.http.post(dcbsUrl + "/api/Ledger/PostGLGenerate", model);
  }

  PostBankUltimusLedgerMapping(model: any) {
    //debugger
    return this.http.post(
      dcbsUrl + "/api/Ledger/SaveBankUltimusLedgerMapping",
      model
    );
  }

  PostTransactionEvent(model: any) {
    //debugger
    return this.http.post(dcbsUrl + "/api/Ledger/SaveTransactionEvent", model);
  }

  GetGLGenerate(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Ledger/GetGLGenerate");
  }

  GetGlGenerateWithSearchAndPagination(
    currentPage: any,
    itemsPerPage: any,
    searchValue: string
  ) {
    return this.http.get(
      dcbsUrl +
        "/api/Ledger/GetAllBankUltimusLedgerMappingsPaginationAndSearch?page=" +
        currentPage +
        "&pageSize=" +
        itemsPerPage +
        "&search=" +
        searchValue
    );
  }

  GetAllBankUltimusLedgerMappingsPaginationAndSearch(
    currentPage: any,
    itemsPerPage: any,
    searchValue: string
  ) {
    return this.http.get(
      dcbsUrl +
        "/api/Ledger/GetAllBankUltimusLedgerMappingsPaginationAndSearch?page=" +
        currentPage +
        "&pageSize=" +
        itemsPerPage +
        "&search=" +
        searchValue
    );
  }

  GetAllTransactionEventsPaginationAndSearch(
    currentPage: any,
    itemsPerPage: any,
    searchValue: string
  ) {
    return this.http.get(
      dcbsUrl +
        "/api/Ledger/GetAllTransactionEventsPaginationAndSearch?page=" +
        currentPage +
        "&pageSize=" +
        itemsPerPage +
        "&search=" +
        searchValue
    );
  }

  DeleteGLGenerateById(Id: any) {
    return this.http.delete(
      dcbsUrl + "/api/ledger/DeleteGLGenerateById?Id=" + Id
    );
  }
  getGLGenerateAuto(): Observable<any[]> {
    return this.http.get<any[]>(dcbsUrl + "/api/Ledger/GetGLGenerate");
  }
  getAutocompleteSuggestions(searchTerm: string): Observable<any[]> {
    return this.http.get<any[]>(
      dcbsUrl + "/api/Ledger/GetGLGenerateAutoCompleate",
      { params: { searchTerm } }
    );
  }
  //#endregion

  //#region BusinessDate
  GetAllStatus(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ledger/GetAllStatus");
  }
  PostBusinessDate(model: any) {
    return this.http.post(dcbsUrl + "/api/Ledger/PostBusinessDate", model);
  }

  GetBusinessDate(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Ledger/GetBusinessDate");
  }

  GetBusinessDateWithSearchAndPagination(
    currentPage: any,
    itemsPerPage: any,
    searchValue: string
  ) {
    //alert(searchValue);
    return this.http.get(
      dcbsUrl +
        "/api/Ledger/GetBusinessDateWithSearchAndPagination?page=" +
        currentPage +
        "&pageSize=" +
        itemsPerPage +
        "&search=" +
        searchValue
    );
  }

  DeleteBusinessDateById(Id: any) {
    return this.http.delete(
      dcbsUrl + "/api/ledger/DeleteBusinessDateById?Id=" + Id
    );
  }

  GetLastAciteBusinessDate(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Ledger/GetLastActiveBusinessDate");
  }

  //#endregion

  //#region BusinessDate

  PostHoliday(model: any) {
    return this.http.post(dcbsUrl + "/api/Ledger/PostHoliday", model);
  }

  GetHoliday(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Ledger/GetHoliday");
  }

  GetHolidayListWithSearchAndPagination(
    currentPage: any,
    itemsPerPage: any,
    searchValue: string
  ) {
    return this.http.get(
      dcbsUrl +
        "/api/Ledger/GetHolidayWithSearchAndPagination?page=" +
        currentPage +
        "&pageSize=" +
        itemsPerPage +
        "&search=" +
        searchValue
    );
  }

  DeleteHolidayById(Id: any) {
    return this.http.delete(dcbsUrl + "/api/ledger/DeleteHolidayById?Id=" + Id);
  }

  GetAllLedgerList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ledger/GetAllLedgerList");
  }

  GetMainLedgerForPaymentOrReceiveVouhcer(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/ledger/GetMainLedgerForPaymentOrReceiveVouhcer"
    );
  }

  GetParticularLedgerForPaymentVoucher(): Observable<any> { 
    return this.http.get(
      dcbsUrl + "/api/ledger/GetParticularLedgerForPaymentVoucher"
    );
  }

  GetParticularLedgerForRecieveVoucher(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/ledger/GetParticularLedgerForRecieveVoucher"
    );
  }
  GetParticularLedgerForJournalVoucher(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/ledger/GetParticularLedgerForJournalVoucher"
    );
  }

  GetAllSubLedgerbyLrId(Id: number): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/ledger/GetAllSubLedgerbyLrId?id=" + Id
    );
  }

  GetAllSubLedgerbyLId(Id: number): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/ledger/GetAllSubLedgerbyLId?subLedgerId=" + Id
    );
  }

  GetAllSubLedger(): Observable<any> {
    return this.http.get(environment.baseUrl + "/api/ledger/GetAllSubLedger");
  }
  GetCoaTree(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ledger/GetCoaTree");
  }
  DeleteLedgerMappingById(Id: any) {
    return this.http.delete(
      dcbsUrl + "/api/ledger/DeletebankUltimusLedgerMappings?id=" + Id
    );
  }

  DeleteLedgerRelationByRelId(Id: any) {
    return this.http.delete(
      dcbsUrl + "/api/Ledger/DeleteLedgerRelationByRelId?relId=" + Id
    );
  }

  DeleteSubLedgerRelationById(Id: any) {
    return this.http.delete(dcbsUrl + "/api/Ledger/DeleteSubLedger?Id=" + Id);
  }
  //#endregion

  DeleteTransactionEventById(Id: any) {
    return this.http.delete(
      dcbsUrl + "/api/ledger/DeleteTransactionEventById?id=" + Id
    );
  }

  GetSubLedgerCodeAndSubLedgerOf(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ledger/GetSubLedgerCodeAndSubLedgerOf");
  }

  saveSubLedger(model: any) {
    return this.http.post(dcbsUrl + "/api/Ledger/PostSubLedger", model);
  }
}
