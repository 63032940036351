import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe, Location } from "@angular/common";
import { SharedService } from "app/services/shared.service";
import { LayoutService } from 'app/shared/services/layout.service';
import { CustommeraccountService } from '../custommeraccount.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { th } from 'date-fns/locale';

@Component({
  selector: "app-customer-charge",
  templateUrl: "./customer-charge.component.html",
  styleUrls: ["./customer-charge.component.scss"],
})
export class CustomerChargeComponent implements OnInit {
  frmGroup: FormGroup;
  modalRef: NgbModalRef | undefined;
  customerCharge: any;
  chargeDetails: any;

  showSaveForm: string = "";
  accountTypelist: any;
  editingIndex: number = -1;
  chDetailsId: number = 0;
  isEdit: boolean = false;
  code: string;
  name: string;
  frequency: number;
  amount: number;
  status: number=1;
  masterId: any = 0;
  dlist: any = {};
  chDetails: {
    code: string;
    name: string;
    amount: number;
    frequency: number;
    status: number;
    chDetailsId: number;
  }[] = [];
  addbtn: boolean = true;
  validationError: string | null = null;
  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private modalService: NgbModal,
    private _service: CustommeraccountService,
    private shared: SharedService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.populateForm();
    this.reset();
    this.showSaveForm = "saveList";
    this.GetCustomerChargeLogs();

    //this.getAccountTypeypeList();
  }

  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      code: ["", Validators.required],
      name: ["", Validators.required],
      isDefault: [1],
      accountTypeId: [0, Validators.required],
    });
  }

  GetCustomerChargeLogs() {
    this._service.getCustomerChargeList().subscribe((res) => {
      this.customerCharge = res;
    });
    this.changeDetectorRef.detectChanges();
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.getAccountTypeypeList();
    // this.getCodeByType(2);
  }

  getAccountTypeypeList() {
    this._service.geAccountTypeList().subscribe((res) => {
      this.accountTypelist = res;
      this.changeDetectorRef.detectChanges();
    });
  }

  addDetails() {
    // var checkDuplicate = this.chDetails.find(
    //   (x) =>
    //     x.dutyAmount == this.dutyAmount &&
    //     x.minAmountRange == this.minAmountRange &&
    //     x.maxAmountRange == this.maxAmountRange
    // );

    // if (checkDuplicate != null) {
    //   this.shared.ShowAlert("Warning", "Duplicate Values.", "warning");
    //   return;
    // }

    if (this.editingIndex === -1) {
      this.chDetails.push({
        code: this.code,
        name: this.name,
        frequency: this.frequency,
        amount: this.amount,
        status: this.status,
        chDetailsId: this.chDetailsId,
      });
    } else {
      this.chDetails[this.editingIndex] = {
        code: this.code,
        name: this.name,
        frequency: this.frequency,
        amount: this.amount,
        status: this.status,
        chDetailsId: this.chDetailsId,
      };
      this.editingIndex = -1;
    }
    this.code = "";
    this.name = "";
    this.frequency = null;
    this.amount = null;
    this.status = 1;
    this.addbtn = true;
    this.changeDetectorRef.detectChanges();
  }

  editChargeDetails(index: number) {
    this.addbtn = false;
    this.editingIndex = index;
    this.code = this.chDetails[index].code;
    this.name = this.chDetails[index].name;
    this.chDetailsId = this.chDetails[index].chDetailsId;
    this.amount = this.chDetails[index].amount;
    this.frequency = this.chDetails[index].frequency;
    this.status = this.chDetails[index].status;
    this.changeDetectorRef.detectChanges();
  }

  deleteChargeDetails(index: number) {
    this.chDetails.splice(index, 1);
    if (index === this.editingIndex) {
      this.editingIndex = -1;
      this.code = null;
      this.name = null;
      this.amount = 0;
      this.frequency = 0;
      this.status = 0;
    }
  }

  prepareDataForAPI() {
    const formData = this.frmGroup.value;
    formData.chargeDetails = this.chDetails;
    if (formData.status === "1") {
      formData.status = true;
    } else {
      formData.status = false;
    }
    return formData;
  }
  onsubmit() {
    if (this.frmGroup.valid) {
      const keydetails = this.prepareDataForAPI();

      if (keydetails.chargeDetails.length === 0) {
        this.shared.ShowAlert(
          "Warning",
          "Please add at least one details.",
          "warning"
        );
      } else {
        this._service.saveCustomerCharge(keydetails).subscribe(
          (res: any) => {
            if (res === null) {
              this.shared.ShowAlert(
                "Error",
                "No Information Given for Details.",
                "error"
              );
            } else {
              this.shared.ShowAlert(
                "Success",
                this.isEdit
                  ? "Successfully Updated ."
                  : "Saved" + " Successfully",
                "success"
              );
              this.GetCustomerChargeLogs();
              this.isEdit = false;
              this.showSaveForm = "saveList";
            }
          },
          (error) => {
            this.shared.ShowAlert("Error", "Failed to save data.", "error");
          }
        );
      }
    }
  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.chDetails = [];
  }

  edit(item: any) {
    const statusString = item.isDefault === false ? "0" : "1";
    this.isEdit = true;
    this.masterId = item.Id;
    this.showSaveForm = "saveForm";
    this.frmGroup.patchValue({
      id: item.Id,
      code: item.code,
      name: item.name,
      accountTypeId: item.accountTypeId,
      isDefault: statusString,
    });
    this.Updatedetails(this.masterId);
  }

  Updatedetails(id: number) {
    this.isEdit = true;
    this._service.GetCustomerChargeDetalisbyId(id).subscribe((res) => {
      this.dlist = res;
      this.chDetails = [];
      this.dlist.forEach((item: any) => {
        this.chDetails.push({
          code: item.code,
          name: item.name,
          frequency: item.frequency,
          amount: item.amount,
          status: item.status,
          chDetailsId: item.chDetailsId,
        });
      });
    });
  }

  OpenModal(requestBodyModal, item: any) {
    this._service
      .GetCustomerChargeDetalisbyId(item.Id)
      .subscribe((res: any) => {
        this.chargeDetails = res;
        this.changeDetectorRef.detectChanges();
      });

    this.modalRef = this.modalService.open(requestBodyModal, {
      backdrop: "static",
      keyboard: false,
    });
  }

  goBack() {
    this.location.back();
  }
}
