<section>
    <div class="card" style="width: 100%;">
      <div class="card-body">
        
          <h4 class="card-title" style="color: black">Statement Logs List</h4>
          <hr> 
  
          
          <div>
  
  
  
            <br>
            <div class="table-container" style="max-height: 700px; overflow-y: auto;">
  
              <table id="example" class="table table-striped table-bordered scrollable-table"
                style="width: 100% !important;">
                <thead>
                  <tr>
                    <th>SL No.</th>
                    
                    <th>AccountNo </th>
                    <th>Statement Type </th>
                    <th>Generate Date</th>
                    <th>Delivery Type</th>
                    <th>Generated By</th> 
  
                   </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of statementList; let i = index;">
                    
                    <td>{{i + 1}}</td> 
                    <td>{{item.customerAccount?.accountTilte}},<br>
                        {{item.customerAccount?.customerAccountNo}}
                    </td>
                    <td>
                        {{item.statementType===2 ? 'Physical':
                        item.statementType===1?'Electronic':''}} 
                    </td>
                    <td>{{item.generateDate | date}}</td> 
                    <td>
                        {{item.deliveryType===1 ? 'SMS':
                        item.deliveryType===2?'EMAIL':''}}
                    </td>
                    <td> {{item.createdBy}}</td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
         
      </div>
    </div>
  </section>

   
