import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
//const apiUrl = environment.baseUrl+'/api/ExciseDuty/';


const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})

export class ExciseDutyService {

  constructor(private http:HttpClient) { }

 saveExciseDuty(model: any){

    return this.http.post(dcbsUrl +'/api/ExciseDuty/PostExciseDuty' , model);
 }

  getExciseDutyList(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/ExciseDuty/GetExciseDuty');
   }
   getExciseDutyListByType(id:number): Observable<any> {
    return this.http.get(dcbsUrl + '/api/ExciseDuty/GetExciseDutyByProductBuilderType?id='+id );
   }
   getExciseDutyDetailsList(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/ExciseDuty/GetExciseDutyDetails');
   }

   delete(id: any){
    return this.http.delete(dcbsUrl + '/api/ExciseDuty/DeleteExciseDutyById/'+ id );

 }
 gettansactionList(): Observable<any> {
  return this.http.get(dcbsUrl + '/api/ExciseDuty/GetAllTransactiontype' );
 }
 GetDetailsByMasterId(id:any){
  return this.http.get(dcbsUrl +'/api/ExciseDuty/GetExciseDutyDetailsByMasterId/'+ id);
    }

    getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
      return this.http.get(dcbsUrl + '/api/ExciseDuty/GetExciseDutyListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
    }

}
