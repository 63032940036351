import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerAccountRoutingModule } from './customer-account-routing.module';
import { CustomerAccountComponent } from './customer-account/customer-account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AccountDetailComponent } from './account-detail/account-detail.component';
import { CustomerAccountListComponent } from './customer-account-list/customer-account-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminCustomerAccountComponent } from './admin-customer-account/admin-customer-account.component';
import { CustomerAccountDetailsComponent } from './customer-account-details/customer-account-details.component';
import { SavingAccountDetailsComponent } from './saving-account-details/saving-account-details.component';
import { CustomerChargeComponent } from './customer-charge/customer-charge.component';


@NgModule({
  declarations: [
    CustomerAccountComponent,
    AccountDetailComponent,
    CustomerAccountListComponent,
    AdminCustomerAccountComponent,
    CustomerAccountDetailsComponent,
    SavingAccountDetailsComponent,
    CustomerChargeComponent
  ],
  imports: [
  
    NgbModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CustomerAccountRoutingModule,
  ]
})
export class CustomerAccountModule { }
