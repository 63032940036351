import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
//const apiUrl = environment.baseUrl+'/api/LoanType/';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class LoanTypeService {

  constructor(private http: HttpClient) { }

  getLoanTypeList(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/LoanType/GetAllLoanType');
   }

   getBNPLLoanTypeList(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/LoanType/GetAllBNPLLoanType');
   }

}
