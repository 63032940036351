import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;


@Injectable({
  providedIn: 'root'
})
export class UpazilaService {

  constructor(private http: HttpClient) { }

  save(model: any) {

    return this.http.post(dcbsUrl + '/api/Upazila/SaveUpazila', model);

  }
  getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/Upazila/GetAllUpazilaWithPaginationSearch?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }

  getlist(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/Upazila/GetAllUpazila');
  }

  getUpazilaByDistrictId(disId : number){
    return this.http.get(dcbsUrl + '/api/Upazila/GetUpazilaByDistrictId?dId=' + disId);
  }
  getThanaByDistrictId(disId : number){
    return this.http.get(dcbsUrl + '/api/PoliceStation/GetAllPoliceStationByDistrict?id=' + disId);
  }
  getUnionWordListByThana(tId : number){
    return this.http.get(dcbsUrl + '/api/UnionWard/GetAllUnionWardByPoliceStation?id=' + tId);
  }
  getVillageListByUnionWord(tId : number){
    return this.http.get(dcbsUrl + '/api/Village/GetAllVillageByUnionWard?id=' + tId);
  }
  getVillageListByThana(tId : number){
    return this.http.get(dcbsUrl + '/api/Village/GetAllVillageByPoliceStation?id=' + tId);
  }
  getUpazilaByDistrictId2(disId : number){
    return this.http.get(dcbsUrl + '/api/Upazila/GetUpazilaByDistrictId?dId=' + disId);
  }


  GetAllCountry(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/Country/GetAllCountry');
  }

  GetDistrtList(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/District/GetAllDistrict');
  }

  delete(id: any) {

    return this.http.delete(dcbsUrl + '/api/Upazila/Delete/' + id);
  }

}
