import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';


const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;


@Injectable({
  providedIn: "root",
})
export class RoleService {
  constructor(private http: HttpClient) {}

  save(model: any) {
    return this.http.post(dcbsUrl  + "/api/Auth/SaveRole", model);
  }

  getlist(): Observable<any> {
    return this.http.get(dcbsUrl  + "/api/Auth/GetAllRole");
  }

  GetUserCheckByPhone(phone: any) {
    return this.http.get(dcbsUrl  + "/api/Auth/GetUserInfoByPhone/" + phone);
  }
}
