import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'app/customer/service/customer.service';
import { TransectionType } from 'app/master-data/products/model/transection-type';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { LayoutService } from 'app/shared/services/layout.service';
import swal from 'sweetalert'; 
import Swal from 'sweetalert2';


@Component({
  selector: 'app-fund-transfer-panding',
  templateUrl: './fund-transfer-panding.component.html',
  styleUrls: ['./fund-transfer-panding.component.scss']
})
export class FundTransferPandingComponent implements OnInit {

  accountNo: string = '';
  accountList: any[] = [];
  startDate: Date = new Date();
  EndDate: Date = new Date();

  transactionList: any[] = [];
  SearchAccNo: boolean = false;
  pdfView: boolean = false;
  transferDetails: any;
  currentUser: string = '';
  transactionType: string='';
  noDataAvailable: boolean = false;
  FundtransactionList: any = [];
  dateFormat:string = "";
  userName: string;
  closeResult: string;
  errorMessage: string | null = null;


  constructor(private customerService: CustomerService,
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private changeDetect: ChangeDetectorRef,
    private modalService: NgbModal,

    private datePipe: DatePipe) {

  }

  ngOnInit(): void {
    var thumb = JSON.parse(localStorage.getItem("thumb"));
    this.dateFormat = thumb.thumbRule.dateFormat;
    this.userName = localStorage.getItem('userName');
    this.gettransactionList();


    this.layoutService.getCurrentUserName().subscribe(res => {
      this.currentUser = res;
    }
    );

  }


  gettransactionList() {
    this.customerService.getFundTransferPanddinfList().subscribe(res => {  
       
      this.transactionList = res;
      console.log("w8e", this.transactionList)
      this.changeDetect.detectChanges();

    })

  }

  openBuilderDetailsModal(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  getProductDetails(id: any) { 
    this.customerService.getFundTransferPanddinfListById(id).subscribe(
  
      (data: any) => {
     
        this.transferDetails = data;
         
      },
      (error) => {
        console.error("Error fetching product details:", error);
      }
    );
  }

  Approve(productId:number){
    // this.validateInput();
    if (!this.errorMessage) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Approve',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Approved',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this.customerService.AproveTransactionByUserRole(productId).subscribe(res => {
            if(res >= 1){
              Swal.fire({
                title: 'Success?',
                text: 'Approved Successfully!',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              }).then((e) =>{
                window.location.reload();
              })
            }
            else{
              Swal.fire({
                title: 'Failed',
                text: 'Failed to Approve',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                //cancelButtonText: 'No'
              })
            }
          })
        }
      })
    }
    else{
      Swal.fire({
        title: 'Warning',
        text: 'You must enter a comment',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
      })
    }


  }


  Reject(productId:number){ 
    if (!this.errorMessage) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Reject',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Rejected',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this.customerService.RejectTransactionByUserRole(productId).subscribe(res => {
            if(res >= 1){
              Swal.fire({
                title: 'Success?',
                text: 'Rejected Successfully!',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              }).then((e) =>{
                window.location.reload();
              })
            }
            else{
              Swal.fire({
                title: 'Failed',
                text: 'Failed to Reject',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                //cancelButtonText: 'No'
              })
            }
          })
        }
      })
    }
    else{
      Swal.fire({
        title: 'Warning',
        text: 'You must enter a comment',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
      })
    }
  }

}
