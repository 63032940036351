export class Customer {
    Id:number;
    CustomerRCode:string;
    CustomerNameEng:string;
    CustomerNameBan:string;
    FatherNameEng:string;
    FatherNameBan:string;
    MotherNameEng:string;
    MotherNameBan:string;
    DateOfBirth:any;
    MobileNo:string;
    Email:string;
    NidNo:string;
    tin:string;
    MaritalStatus:string;
    SpouseNameEng:string;
    SpouseNameBan:string;
    SpouseMobileNo:string;
    GenderId:number;
    presentAddressEN:string;
    permenantAddressEN:string;
    DistrictId:number;
    UpazilaId:number;
    otp:string;
   
    frontImage:string;
    backImage:string;
    profileImage:string;
    isConfirm:boolean;
    otp1: string = '';
    
    otp2: string = '';
    otp3: string = '';
    otp4: string = '';

  creditScore: any;

  otpCode: string = '';

}
