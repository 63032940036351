import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class FdrdetailsService {

  constructor(private http:HttpClient) { }


  GetProductForFDR(): Observable<any> {

    return this.http.get(dcbsUrl + '/api/FDRAccount/GetFdrProductInfo');
  }

  saveFDR(model: any) {


    return this.http.post(dcbsUrl + '/api/FDRAccount/SaveFDRAccount', model);
  }

  GetAllFDRAccount(): Observable<any> {

    return this.http.get(dcbsUrl + '/api/FDRAccount/AllFDRCustomerAccount');
  }

}
