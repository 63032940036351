import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;


@Injectable({
  providedIn: 'root'
})
export class CountryInfoService {


  constructor(private http: HttpClient) { }

  save(model: any) {

    return this.http.post(dcbsUrl + '/api/Country/SaveCountry', model);

  }

  getlist(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/Country/GetAllCountry');
  }

  getAllCountryWithPaginationSearch(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/Country/GetAllCountryWithPaginationSearch?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }
  delete(id:any){

    return this.http.delete(dcbsUrl + '/api/Country/Delete/' + id);
  }
  getAllCurrency(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/Country/GetAllCurrency');
  }
  getAllGetCurrencyInfo(): Observable<any> {    
    return this.http.get(dcbsUrl + '/api/Country/GetCurrencyInfo');
  }
  getAllGetBankList(): Observable<any> {    
    return this.http.get(dcbsUrl + '/api/Country/GetBankList');
  }

}
