<!-- Product Builder Input start -->
<section>
    <div class="card" style="width: 100%;">
        <div class="card-body">
  
  
            <div class="container">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title" style="color: black">Saving Product</h5>
                    </div>
                    <!-- <div class="col" style="text-align: right;">
                        <button type="button" data-toggle="tooltip" (click)="areaView(1)" class="btn btn-info"
                            style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Go to List</button>
                    </div> -->
  
                    <div class="col-2" style="text-align: right;">
                        <button type="button" data-toggle="tooltip" [routerLink]="['/product/deposit-product-builder-list']"
                            class="btn btn-info" style="float:right;margin-right:5px;margin-left: 8px;">Go
                            to List</button>
                    </div>
                    <div class="col-1">
                        <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                            <!-- <i class="ft-chevrons-left"> Back</i> -->
                            <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                        </button>
                    </div>
                </div>
                <hr>
                <form [formGroup]="frmGroup">
                    <div asp-validation-summary="All" class="text-danger"></div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Product Type <span
                                        style="color:red">*</span></label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="loanTypeId"
                                        (change)="getProductCode($event.target.value)">
                                        <ng-container *ngFor="let item of categoryList"> 
                                            <option *ngFor="let item of categoryList" [value]="item?.id" selected>{{item?.typeName}}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                        </div>
                         
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Code" class="col-sm-4 col-form-label">Code <span
                                        style="color:red">*</span></label>
                                <div class="col-sm-8">
  
                                    <input type="text" class="form-control" formControlName="Code" placeholder="Code"
                                        [(ngModel)]="productCode" readonly>
                                </div>
                            </div>
  
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Name" class="col-sm-4 col-form-label">Name <span
                                        style="color:red">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="Name" placeholder="Name"
                                        [readonly]="isReadOnly">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Version" class="col-sm-4 col-form-label">Version <span
                                        style="color:red">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="Version"
                                        placeholder="Version">
                                </div>
                            </div>
                        </div>
                    </div>
  
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Description" class="col-sm-4 col-form-label">Description</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="Description"
                                        placeholder="Description">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Description" class="col-sm-4 col-form-label">Payment Type</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="paymentTypeId">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of allPaymentType" [value]="item.id">{{item.typeName}}
                                        </option>
                                    </select>
                                     
                                </div>
                            </div>
    
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="Currency" class="col-sm-4 col-form-label">Currency <span
                                        style="color:red">*</span></label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="Currency">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of currencyList" [value]="item?.id">{{item?.code}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Day Size <span
                                        style="color:red">*</span></label>
                                <div class="col-sm-7">
                                    <select class="form-control" formControlName="DaySizeId"
                                        >
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of daySizeList" [value]="item?.id">
                                            {{item?.name}}-{{item?.size}} ({{item?.upMark}}/{{item?.downMark}})</option>
                                    </select>
                                </div>
                                <button type="button" title="Details" id="loadDaySize"
                                    (click)="openModal(contentDaySize)" value="Load"
                                    style="text-align: center;height: 37px;" class="btn btn-sm col-sm-1">
                                    <i style="font-size: 30px;" class="ft-alert-circle"></i>
                                </button>
                            </div>
  
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Interest Rate <span
                                        style="color:red">*</span></label>
                                <div class="col-sm-8">
                                    <input type="number"  min="0" class="form-control text-right" formControlName="interestRate"
                                        placeholder="Interest Rate"
                                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Balance Type</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="BalanceTypeId">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of balanceTypeList" [value]="item?.id">
                                            {{item?.name}}</option>
                                    </select>
                                </div>
                            </div>
  
                        </div>
                    </div>
                    
                    
                    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Interest Calculation Type</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="interestCalculationTypeSelect">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of intCalculationList" [value]="item.instCalType.id">
                                            {{item.instCalType.name}} - {{item.instCalType.code}}</option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Interest Calculation Frequency</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="InterestCalculationFrequencyId">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of intCalculationFreqList" [value]="item?.id">
                                            {{item?.name}} - {{item?.days}} Days</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        
  
                        <div class="col-md-6 d-none">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Capitalization Frequency</label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="capitalizationFrequencyId">
                                        <option *ngFor="let item of capFrequencyList" [value]="item?.id">{{item?.name}}
                                            -
                                            {{item?.days}} Days</option>
                                    </select>
                                </div>
                            </div>
  
                        </div>
  
                    </div>
                    <div class="row">
                         
                         
                        <!-- <div class="col-md-6">
                            <div class="form-group row">
                                <label for="status" class="col-sm-4 col-form-label">Status <span
                                        style="color:red">*</span></label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="status">
                                        <option value="">N/A</option>
                                        <option value="1">Active</option>
                                        <option value="0">in-Active</option>
                                    </select>
                                </div>
                            </div>
                        </div> -->
                         
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="sortorder" class="col-sm-4 col-form-label">Charge</label>
                                <div class="col-sm-6">
                                    <select class="form-control" formControlName="chargeId" (change)="onChargeChange()">
                                        <option value="">N/A</option>
                                        
                                            <option *ngFor="let item of chargeList" [value]="item?.id">
                                                {{item?.code}} - {{item?.name}}
                                            </option>
                                    </select>
                                </div>
                                <button title="Details" value="Details" class="btn btn-sm col-sm-1"
                                    (click)="openModal(contentCharge)" style="height: 100%;"><i style="font-size: 30px;"
                                        class="ft-alert-circle"></i></button>
  
                                <button type="button" title="Add" (click)="addMultipleCharge()"
                                    class="btn btn-primary btn-sm">Add</button>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label"></label>
                                <div class="col-sm-8">
                                    <ul>
                                        <li *ngFor="let item of selectedChargeList">
                                            {{item?.name}} - {{item?.code}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
  
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="effectiveDate" class="col-sm-4 col-form-label">Effective Date</label>
                                <div class="col-sm-8">
                                    <!-- <input type="text" class="form-control" formControlName="effectiveDate"> -->
                                    <input type="date" class="form-control" id="effectiveDate" formControlName="effectiveDate" />
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="form-group row">
                                <label for="status" class="col-sm-4 col-form-label">Status <span style="color:red">*</span></label>
                                <div class="col-sm-8">
                                    <select class="form-control" formControlName="status">
                                        <option value="">N/A</option>
                                        <option value="1">Active</option>
                                        <option value="0">in-Active</option>
                                    </select>
                                </div>
                            </div>
                        </div> -->
                         
                        <!-- <div class="col-md-6">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Maximum Withdrawal Limits</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="maxwithdrawalLimit"
                                        placeholder="Maximum Withdrawal Limits" appDecimalOnly>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Overdraft Limits</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="overdraftLimit"
                                        placeholder="Overdraft Limits" appDecimalOnly>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Transaction Limit</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="transactionLimit"
                                        placeholder="Transaction Limit" appDecimalOnly>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Transaction  Frequencies</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="transactionfrequencies"
                                        placeholder="Transaction Frequencies" appDecimalOnly>
                                </div>
                            </div>
                        </div> -->
  
                    </div>
  
                    
  
  
                    <hr />
  
                   
                    <div class="row">
                        <div class="col-12">
                            <div class="content-header">GL Mapping </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-12">
           
                            <div class="form-row">
                                <div class="col-md-12 col-12">
                                    <div class="form-group mb-2">
                                        <label for="basic-form-6">Event Name <span style="color:red">*</span></label>
                                  
                                        <select id="productEvent" name="productEventName"
                                            formControlName="productEventName" class="form-control"  (change)="gleventData($event.target.value)">
                                            <option value="0">Select Event</option>
                                    <option *ngFor="let item of productEventList" [value]="item.id">{{item.eventName}} - {{item.eventCode}}</option>
                                        </select>
  
                                        <label for="basic-form-6">GL Type <span style="color:red">*</span></label>
                                        <select id="glType" name="glType" formControlName="glType" class="form-control">
                                            <option value="">Select GL Type</option>
                                            <option value="DR">Debit</option>
                                            <option value="CR">Credit</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12">
                                </div>
                            </div>
  
                        </div>
                       
  
                      <div class="col-lg-6 col-12">
                          <div class="form-row">
                              <label for="basic-form-7">GL <span style="color:red">*</span></label>
                              <ng-select class="form-control" name="glList" formControlName="eventGLId" [items]="glList"
                                  [virtualScroll]="true" bindLabel="ledger.accountName" bindValue="id" placeholder=""
                                  (change)="eventGLIdData($event)">
                                  <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                      <span class="option-content">
                                          {{ item?.ledger?.accountCode + '-' + item?.ledger?.accountName }}
                                      </span>
                                  </ng-template>
                              </ng-select>
                          </div>
                      </div>
  
                        
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-8"></div>
                        <div class="col-md-4">
                            <button class="btn btn-info"  (click)="fnAddMappedGL()">
                             
                              <span *ngIf="addEvent">ADD</span>
                              <span *ngIf="editEvent"> UPDATE</span>
  
                          </button>
  
                          
                        </div>
  
                    </div>
                    <br />
                    <div class="row" *ngIf="showGlTbl">
                        <div class="col-md-1"></div>
                        <div class="col-md-9">
  
                            <table class="table table-bordered" >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Event</th>
                                        <th>GL</th>
                                        <th>GL Type</th>
                                        <th style="width:20%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
  
                                    <tr *ngFor="let item of mappedGLList;let i=index">
                                        <td>{{i+1}}</td>
                                        <td>{{item?.eventName}}</td>
                                        <td>{{item?.accountName}}-{{item?.accountCode}}</td>
                                        <td>{{item?.glType?.toUpperCase() == 'DR' ? 'Debit' : 'Credit'}}</td>
                                        <td>
                                          <a class="btn btn-danger btn-sm mr-2" (click)="removeMappedGL(item?.eventId)"><i class="fa fa-remove"></i></a>
                                          <a class="btn btn-info btn-sm" (click)="editMappedGL(item)"><i class="fa fa-edit"></i></a>
                                       </td>
                                    </tr>
  
                                </tbody>
                            </table>
                        </div>
  
                    </div>
  <!-- -------------------------------------------------------------------------- -->
                    <hr />
  

                    <div class="row mt-4">
                        <span class="col-md-12" style="font-weight: 800;">Approval :</span>
                        <div class="col-md-12 border">
                            <div class="form-group row"
                                style="display: flex; justify-content: center; align-items: center; margin-top: 5px;">
                                <label for="" class="col-md-2">Approver</label>
                                <div class="col-md-4">
                                    <select name="" id="approver" class="form-control"
                                        (change)="ChangeApprover2($event.target)">
                                        <option value="">Choose Approver</option>
                                        <option value="{{item.id}}" *ngFor="let item of allbankusers2">{{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <a href="javascript:void(0)" class="btn btn-info btn-sm"
                                        (click)="AddApprover2()">Add</a>
                                </div>
                            </div>
                            <div class="form-group row"
                                style="display: flex; justify-content: center; align-items: center; margin-top: 5px;">
                                <span *ngFor="let item of addedApprovers2"
                                    class="badge badge-primary mr-1 mb-1">{{item.order}}. {{item.roleName}}</span>
                            </div>
                            
                        </div>
                    </div>
    
                    
                    <button [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit"
                        (click)="onsubmit()" value="Submit" class="btn btn-success"
                        style="float:right; margin-top:5px;">
                        <span>Update</span>
                    </button>
  
                    <button type="button" data-toggle="tooltip" title="Refresh" (click)="reset()" [hidden]="isEdit"
                        class="btn btn-secondary"
                        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
                </form>
            </div>
        </div>
    </div>
  
  
    <ng-template #contentDaySize let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Day Size Details</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <table class="table table-bordered">
                <tr>
                    <td>Day Size Name</td>
                    <td>{{daySize?.name}}</td>
                </tr>
                <tr>
                    <td>Size</td>
                    <td>{{daySize?.size}}</td>
                </tr>
                <tr>
                    <td>Up Value</td>
                    <td>{{daySize?.upMark}}</td>
                </tr>
                <tr>
                    <td>Down Value</td>
                    <td>{{daySize?.downMark}}</td>
                </tr>
                <tr>
                    <td>Remarks</td>
                    <td>{{daySize?.remarks}}</td>
                </tr>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
  
    <ng-template #contentTax let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Tax Key Details</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p *ngIf="taxKey!=null">Tax Code: {{taxKey?.taxCode}}, Tax Name: {{taxKey?.taxName}}, Type:
                {{taxKey?.transectionType?.typeName}}</p>
            <ul *ngIf="taxKeyDetails!=null">
                <li *ngFor="let item of taxKeyDetails">Percent: {{item?.taxPercent}}% {{item?.type==0?'Without Tax
                    Return':'With Tax Return'}}</li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
  
    <ng-template #contentExcise let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Excise Duty Info</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p *ngIf="exciseDuty!=null">Excise Code: {{exciseDuty.exciseDutyName}}, Excise Name:
                {{exciseDuty.exciseDutyCode}}, Type: {{exciseDuty.transectionType.typeName}}</p>
            <ul *ngIf="sortExcDutyDetails!=null">
                <li *ngFor="let item of sortExcDutyDetails">Excise Amount: {{item?.dutyAmount}};
                    Amount Range: {{item?.minAmountRange}} - {{item?.maxAmountRange}}
                </li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
  
    <ng-template #contentCalType let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">INT. Calculation Type Detail</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p *ngIf="storeIntCalType!=null">INT. Calculation Type: {{storeIntCalType.name}}</p>
            <ul *ngIf="storeIntCalTypeDetails!=null">
                <li *ngFor="let item of storeIntCalTypeDetails">Amount: {{item?.amount}}; Percent: {{item?.taxPercent}}%
                </li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
  
    <ng-template #contentCalSlabType let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">INT. Calculation Type Slab Detail</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ul>
                <li>Min Amount: {{this.storeIntCalType.min}};</li>
                <li>Max Amount: {{this.storeIntCalType.max}};</li>
                <li>Parcentage: {{this.storeIntCalType.amountParcent}};</li>
                <li>Remarks: {{this.remarks}};</li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
    <ng-template #contentCapRule let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Capitalize Rule Detail</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ul>
                <li>Code: {{capRule!=null?capRule.code:''}}</li>
                <li>Details: {{capRule!=null?capRule.details:''}}</li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
    <ng-template #contentCharge let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Charge Detail</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ul>
                <li>Code: {{charge!=null?charge.code:''}}</li>
                <li>Name: {{charge!=null?charge.name:''}}</li>
                <li>{{charge!=null && charge.type==1?"Amount":"Percentage"}}: {{charge!=null?charge.amount:''}}</li>
                <li>Type: {{charge!=null && charge.type==1?"Fixed":"Percentage"}}</li>
                <li>Remarks: {{charge!=null?charge.remarks:''}}</li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
    <ng-template #contentCommission let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Commission Detail</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ul>
                <li>Code: {{commission!=null?commission.code:''}}</li>
                <li>Name: {{commission!=null?commission.name:''}}</li>
                <li>Amount: {{commission!=null?commission.amount:''}}</li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
    </ng-template>
  
  </section>
  