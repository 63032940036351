import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
// const apiUrl = environment.baseUrl+'/api/ProductBuilder/';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: "root",
})
export class ProductBuilderService {
  constructor(private http: HttpClient) {}
  getDPSProductList(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/ProductBuilder/GetDPSFromProductBuilders"
    );
  }

  getList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ProductBuilder/GetProductBuilders");
  }

  getAccountTypeInfoList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ProductBuilder/GetAllAccountTypeList");
  }

  GetProductByAccountTypeId(accTypeId: number): Observable<any> {

    return this.http.get<any>(
      dcbsUrl + "/api/ProductBuilder/GetProductByAccountTypeId/" + accTypeId,
      {
        responseType: "json",
      }
    );
  }

  GetDPSStatusInfos(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ProductBuilder/GetDPSStatusInfos");
  }

  UpdateDPSStatus(accId: number, statusId: number): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/ProductBuilder/UpdateDPSStatus?accId=" +
        accId +
        "&statusId=" +
        statusId
    );
  }

  getProductCodeByCategory(categoryId: number): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/api/ProductBuilder/GenerateProductCode/" + categoryId,
      {
        responseType: "json",
      }
    );
  }

  getIncomeSourceList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ProductBuilder/GetIncomeSource");
  }
  save(model: any) {
    return this.http.post(
      dcbsUrl + "/api/ProductBuilder/SaveProductBuilders",
      model
    );
  }

  getLoanTypeById(id: any) {
    return this.http.get(
      dcbsUrl + "/api/ProductBuilder/GetProductBuildersById/" + id
    );
  }

  getLedgerByProductId(id: any) {
    return this.http.get(
      dcbsUrl + "/api/ProductBuilder/GetLedgerListByProductId/" + id
    );
  }

  // getLedgerByProductId(id:any):Observable<any>{
  //   return this.http.get(dcbsUrl +'GetLedgerListByProductId/' +id);
  // }

  delete(id: any) {
    return this.http.delete(
      dcbsUrl + "/api/ProductBuilder/DeleteProductBuilder/" + id
    );
  }
  deActive(id: any, s: boolean) {
    return this.http.delete(
      dcbsUrl +
        "/api/ProductBuilder/DeActiveProductBuilder?id=" +
        id +
        "&status=" +
        s
    );
  }
  getProductMasterDateCodeByType(type: number) {
    return this.http.get(
      dcbsUrl + "/api/ProductBuilder/GetProductMasterDataCodeByType/" + type
    );
  }

  getProductBuilderDetailsById(id: number): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/ProductBuilder/GetProductBuilderDetailsById/" + id
    );
  }

  ProductBuilderDetailsByProId(id: number): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/ProductBuilder/ProductBuilderDetailsById/" + id
    );
  }

  GetMaturityDate(pId: number) {
    return this.http.get(
      dcbsUrl + "/api/ProductBuilder/GetMaturityDateByProductId?pId=" + pId
    );
  }

  getTenureList(productId: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanProductBuilder/GetAllLoanDurationListByProductId?productId=" +
        productId
    );
  }
  GetAllPendingProductByRole(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ProductBuilder/GetAllPendingProductByRole");
  }

  AproveLoanProductByUserRole(
    productId: number,
    remarks: string
  ): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/ProductBuilder/AproveLoanProductByUserRole?productId=" +
        productId +
        "&remarks=" +
        remarks
    );
  }

  ReturnLoanProductByUserRole(
    productId: number,
    remarks: string
  ): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/ProductBuilder/ReturnLoanProductByUserRole?productId=" +
        productId +
        "&remarks=" +
        remarks
    );
  }
  RejectLoanProductByUserRole(
    productId: number,
    remarks: string
  ): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/ProductBuilder/RejectLoanProductByUserRole?productId=" +
        productId +
        "&remarks=" +
        remarks
    );
  }

  getDepositList(): Observable<any> {
    return this.http.get(dcbsUrl  + "/api/ProductBuilder/GetDepostProduct");
  }

  
  ProductBuilderDetailsByProductId(id: number): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/ProductBuilder/ProductBuilderDetailsByProductId/" + id
    );
  }

  getSavingProductBuilderDetailsById(id: number): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/ProductBuilder/GetSavingProductBuilderDetailsById/" + id
    );
  }
  getAccountTypeInfoList2(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/ProductBuilder/GetAllAccountTypeList2");
  }
}
