import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
//const apiUrl=environment.baseUrl + '/api/CapitalizationRule/'


const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: "root",
})
export class CapitalizationRuleService {
  constructor(private http: HttpClient) {}

  //#region Capitalization
  getlist(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetAllCapitalizationRuleList");
  }
  getlistByProBuilderType(id: number): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetAllCapRuleListByProBuilderType?id=" + id);
  }

  GetCapitalizationById(id: number) {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetCapitalizationRuleById");
  }
  GetProductWiseIntaCalculationRuleId(id: number) {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetProductWiseIntaCalculationRuleId/" + id);
  }

  SaveCapitalization(model: any) {
    return this.http.post(dcbsUrl + "/api/CapitalizationRule/SaveCapitalizationRule", model);
  }
  getListWithSerchAndPagination(
    currentPage: any,
    itemsPerPage: any,
    searchValue: string
  ) {
    return this.http.get(
      dcbsUrl +
        "/api/CapitalizationRule/GetCapitalListWithSerchAndPagination?page=" +
        currentPage +
        "&pageSize=" +
        itemsPerPage +
        "&search=" +
        searchValue
    );
  }
  DeleteCapitalization(id: any) {
    return this.http.delete(dcbsUrl + "/api/CapitalizationRule/DeleteCapitalizationRule/" + id);
  }

  GetCapitalizationRuleById(id: number) {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetCapitalizationRuleById");
  }
  //#endregion

  //Tenure

  getAutoCodeForDuration() {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetAutoCodeForDuration");
  }
  SaveDuration(model: any) {
    return this.http.post(dcbsUrl + "/api/CapitalizationRule/SaveDurationinfo", model);
  }

  DeleteDuration(id: any) {
    return this.http.delete(dcbsUrl + "/api/CapitalizationRule/DeleteDurationById/" + id);
  }

  GetDurationById(id: number) {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetDurationInfoById/" + id);
  }

  getAllDurationlist(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetAllDurationListInfo");
  }
  //

  //MasterBank
  SaveMasterBank(model: any) {
    return this.http.post(dcbsUrl + "/api/CapitalizationRule/SaveMasterBankInfo", model);
  }

  DeleteMasterBank(id: any) {
    return this.http.delete(dcbsUrl + "/api/CapitalizationRule/DeleteMasterBankById/" + id);
  }

  GetMasterBanknById(id: number) {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetMasterBankInfoById/" + id);
  }

  getAllMasterBanklist(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetAllMasterBankListInfo");
  }
  //

  //BankBranch
  SaveBankBranchesInfo(model: any) {
    return this.http.post(dcbsUrl + "/api/CapitalizationRule/SaveBankBranchesInfo", model);
  }

  DeleteBankBranchById(id: any) {
    return this.http.delete(dcbsUrl + "/api/CapitalizationRule/DeleteBankBranchById/" + id);
  }

  GetBankBranchesInfoById(id: number) {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetBankBranchesInfoById/" + id);
  }

  GetAllBankBranchList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CapitalizationRule/GetAllBankBranchListInfo");
  }
  //
}
