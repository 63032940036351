<!-- <p>nid-verify works!</p> -->

<div class="container">
    <!-- <h3 class="card-title" style="margin-top: 3%;color: black;text-align: center;"> eKyc - Customer Registration</h3> -->
    <div class="card">
        <div class="card-body">
            <form #customerForm="ngForm">


                <div class="row">
                    <div class="col-md-12">
                        <div class="row" *ngIf="isNIDShow">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <div class="mb-4 form-group">
                                    <label for="NidNo">NID No <span style="color:red; font-size: 15px; ">*</span></label>
                                    <input type="text" class="form-control" name="NidNo" id="NidNo" placeholder="Type your NID number" [(ngModel)]="model.NidNo">
                                </div>
                                <div class="mb-4 form-group">
                                    <label for="dob">Date of Birth <span style="color:red; font-size: 15px; ">*</span></label>
                                    <input class="form-control" type="date" id="dob" placeholder="yyyy-mm-dd" name="DateOfBirth" [(ngModel)]="model.DateOfBirth">
                                </div>
                                <div class="mb-4 form-group">
                                    <button class="btn btn-primary pull-left" (click)="onNidSubmit(customerForm)" type="button">NID Verify</button>

                                </div>
                            </div>
                            <div class="col-md-3">
 
                            </div>
                             
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row mt-5">
                            <div class="col-md-1"></div>
                            <div class="col-md-4">
                                <div class="row" *ngIf="isNIDImageShow">
                                    <div class="col-md-12">
    
                                        <label for="image" style="position: absolute;
                                        top: -24px;
                                        right: 33%;">NID Card Front Side</label>
    
                                        <input type="file" accept="image/*" id="image" (change)="selectNidFrontPic($event)"
                                            style="display: none;" #fileInputFront />
    
                                        <div style="height: 185px; width: 330px; cursor: pointer;"
                                            (click)="fileInputFront.click()">
                                            <img src="{{ frontPreview ? frontPreview : 'assets/img/logos/Part1.jpeg' }}"
                                                id="image" style="width: 100%; height: 100%;" class="proPreview"
                                                title="Click here to upload photo" />
                                        </div>
                                    </div>
                                     
    
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-4">
                                <div class="row" *ngIf="isNIDImageShow">
                                     
                                    <div class="col-md-12" style="padding-left: 0px;">
    
                                        <label for="image" style="position: absolute;
                                        top: -24px;
                                        right: 33%;">NID Card Back Side</label>
    
                                        <input type="file" accept="image/*" id="image" (change)="selectNidBackPic($event)"
                                            style="display: none;" #fileInputBack />
    
                                        <div style="height: 185px; width: 330px; cursor: pointer;"
                                            (click)="fileInputBack.click()">
                                            <img src="{{ backPreview ? backPreview : 'assets/img/logos/Part2.jpeg' }}"
                                                id="image" style="width: 100%; height: 100%;" class="proPreview"
                                                title="Click here to upload photo" />
                                        </div>
    
    
                                    </div>
    
                                </div>






                              <!--   <div class="mb-4 form-group">
                            
                                    <div *ngIf="isNIDImageShow">
                                        <button class="btn btn-primary pull-right" (click)="onNidOK()" type="button">Next</button>
                                    </div>
                                </div> -->
                                
                            </div>
                            <div class="col-md-1"></div>

                            <div   *ngIf="isNIDImageShow" style="display: flex;justify-content: space-around;" class="col-md-12 col-sm-12 mt-5">

                       
                                <button class="btn btn-success" (click)="onNidOK()" type="button">Upload & Next</button>
                                 
          
                              </div>




                        </div>
                        
                    </div>
                    <div class="col-md-12">
                        <div class="col-md-5 col-sm-12" *ngIf="isNIDData">
                            <div class="form-group">
                                <p>Name : {{ nid.fullNameEN }}</p>
                                <p>Date Of Birth : {{ nid.dateOfBirth }}</p>
                            </div>
                            <div class="form-group">
                                <p>Father Name : {{ nid.fathersNameEN }}</p>
                                <p>Mother Name : {{ nid.mothersNameEN }}</p>
                            </div>
                            <div class="form-group">
                                <p>Present Address : {{ nid.presentAddressEN}} </p>
                                <p>Permanent Address : {{ nid.presentAddressEN}} </p>
                                
                                <div style="height: 140px; width: 200px; cursor: pointer;">
                                    <img src="{{nid.photoUrl}}" id="image" style="width: 100%; height: 100%;"
                                        class="proPreview" />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isNIDData">
                            <button class="btn btn-primary pull-right" (click)="onNidOK()" type="button">Next</button>
                        </div>
                    </div>



                     
                    <div class="col-md-2 col-sm-12"></div>

                    
                </div>










                <!-- <div class="row">

                    <div class="col-md-3 col-sm-12 mt-2" style="display: none;">
                        
                        <div class="col-md-12 col-sm-12 mt-2">
                            <img src="{{frontPreview}}" id="imageFrontPreview" class="frontPreview" />
                        </div>
                        <div class="col-md-12 col-sm-12 mt-4">

                            <img src="{{backPreview}}" id="imageBackPreview" class="backPreview" />
                        </div>

                    </div>

                    <div class="col-md-2 col-sm-12"></div>
                    <div class="col-md-4 col-sm-12">
                        <div class="mb-4 form-group">
                            <label for="NidNo">NID No <span style="color:red; font-size: 15px; ">*</span></label>
                            <input type="text" class="form-control" name="NidNo" id="NidNo" placeholder="Type your NID number" [(ngModel)]="model.NidNo">
                        </div>
                        <div class="mb-4 form-group">
                            <label for="dob">Date of Birth <span style="color:red; font-size: 15px; ">*</span></label>
                            <input class="form-control" type="date" id="dob" placeholder="yyyy-mm-dd" name="DateOfBirth" [(ngModel)]="model.DateOfBirth">
                        </div>

                        <div class="mb-4 form-group">
                            <div class="row" *ngIf="isNIDImageShow">
                                <div class="col-md-6" style="padding-right: 0px;">

                                    <label for="image">NID Front PICTURE</label>

                                    <input type="file" accept="image/*" id="image" (change)="selectNidFrontPic($event)"
                                        style="display: none;" #fileInputFront />

                                    <div style="height: 125px; width: 150px; cursor: pointer;"
                                        (click)="fileInputFront.click()">
                                        <img src="{{ frontPreview ? frontPreview : 'assets/img/gallery/upload_image.png' }}"
                                            id="image" style="width: 100%; height: 100%;" class="proPreview"
                                            title="Click here to upload photo" />
                                    </div>
                                </div>
                                <div class="col-md-6" style="padding-right: 0px;">

                                    <label for="image">NID Back PICTURE</label>

                                    <input type="file" accept="image/*" id="image" (change)="selectNidBackPic($event)"
                                        style="display: none;" #fileInputBack />

                                    <div style="height: 125px; width: 150px; cursor: pointer;"
                                        (click)="fileInputBack.click()">
                                        <img src="{{ backPreview ? backPreview : 'assets/img/gallery/upload_image.png' }}"
                                            id="image" style="width: 100%; height: 100%;" class="proPreview"
                                            title="Click here to upload photo" />
                                    </div>


                                </div>

                            </div>

                        </div>

                        <div class="mb-4 form-group">
                            <button class="btn btn-primary pull-left" (click)="onNidSubmit(customerForm)" type="button">NID Verify</button>

                            <div *ngIf="isNIDOkShow">
                                <button class="btn btn-primary pull-right" (click)="onNidOK()" type="button">Next</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-12" *ngIf="isNIDData">
                        <div class="form-group">
                            <p>Name : {{ nid.fullNameEN }}</p>
                            <p>Date Of Birth : {{ nid.dateOfBirth }}</p>
                        </div>
                        <div class="form-group">
                            <p>Father Name : {{ nid.fathersNameEN }}</p>
                            <p>Mother Name : {{ nid.mothersNameEN }}</p>
                        </div>
                        <div class="form-group">
                            <p>Present Address : {{ nid.presentAddressEN}} </p>
                            <p>Permanent Address : {{ nid.presentAddressEN}} </p>
                            
                             
                             
                            <div style="height: 140px; width: 200px; cursor: pointer;">
                                <img src="{{nid.photoUrl}}" id="image" style="width: 100%; height: 100%;"
                                    class="proPreview" />
                            </div>
                        </div>
                    </div>
                </div> -->
            </form>
        </div>
    </div>
</div>
