import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';


const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  getCustomer(): Observable<any> {
    const url = `${dcbsUrl}`;
    return this.http.get<any>(url);
  }

  getCustomerByAccountNo(accountNo: string): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/get-customer-by-accountNo/" + accountNo
    );
  }

  GetCustomerInfoByAccountNoById(accountId: number): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/GetCustomerInfoByAccountNoById/" + accountId
    );
  }

  getTransactionLimitInfoAccountNo(accountNo: any): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/GetTransactionLimitInfoAccountNo/" + accountNo
    );
  }

  getTransactionLimitByAccountId(accountId: any): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/GetTransactionLimitAccountID/" + accountId
    );
  }

  getTransactionCountBalanceId(accountId: any, typeId: any): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/GetTransactionCountBalanceByAccountId/" +
        accountId +
        "/" +
        typeId
    );
  }

  GetGLGenerateByCode(glCode: any): Observable<any> {
    //alert(0)
    return this.http.get(dcbsUrl + "GetGLGenerateByCode?glCode=" + glCode);
  }

  GetCustomerGenerateCode(): Observable<any> {
    //alert(0)
    return this.http.get(
      dcbsUrl + "/api/LoanApplication/GetCustomerGenerateCode"
    );
  }
  //#region
  getList(): Observable<any> {
    return this.http.get(dcbsUrl + "GetClientinfo");
  }
  GetCustomerById(id: any) {
    debugger
    return this.http.get(dcbsUrl + "GetCustomerById/" + id);
  }
  //    save(model: any){
  //     return this.http.post(dcbsUrl +'PostCustomerInfo' , model);
  //  }
  savebasic(model: any) {
    return this.http.post(
      dcbsUrl + "/api/LoanApplication/PostBasicCustomerInfo",
      model
    );
  }
  PostBasicCustomerInfo(model: any):any {
    return this.http.post(
      cifUrl + "/api/Customers/PostBasicCustomerInfo",
      model
    );
  }
  PostDraftCustomerInfo(model: any) {
    return this.http.post(
      cifUrl + "/api/Customers/PostDraftCustomerInfo",
      model
    );
  }

  saveCustomerDoc(model: any) {
    return this.http.post(
      dcbsUrl + "/api/LoanApplication/CustomerUploadDocument",
      model
    );
  }

  DeleteTempDocumentsById(id: any){
    return this.http.delete(dcbsUrl + '/api/LoanApplication/DeleteTempDocumentsById/'+ id );
  }
  GetLeadGenerationByLeadNoforBasic(
    leadId: number,
    customersId: number,
    loanProductsId: number
  ): Observable<any> {
    //return this.http.get(dcbsUrl + '/api/LoanApplication/GetLeadGenerationByLeadNo?leadId=' + leadId);
    return this.http.get(
      dcbsUrl +
        "/api/LoanApplication/GetLeadGenerationByLeadNoForBasic?leadId=" +
        leadId +
        "&customersId=" +
        customersId +
        "&loanProductsId=" +
        loanProductsId
    );
  }
  GetLeadGenerationByLeadNo(leadId: number): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/LoanApplication/GetLeadGenerationByLeadNo?leadId=" +
        leadId
    );
  }

  getlist(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Gender/GetAllGenders");
  }

  //#endregion

  //#region
  getContactList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/LoanApplication/GetAllContact");
  }
  Contactsave(model: any) {
    return this.http.post(
      dcbsUrl + "/api/LoanApplication/PostContactInfo",
      model
    );
  }
  GetContactByCustomerId(id: any) {
    return this.http.get(
      dcbsUrl + "/api/LoanApplication/GetContactByCustomerId/" + id
    );
  }
  GetLeadGenerationByLeadNoforContact(customersId: number): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/LoanApplication/GetLeadGenerationByLeadNoforContact?customersId=" +
        customersId
    );
  }


  GetTempContactByCustomerId(customersId: number): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/LoanApplication/GetTempContactByCustomerId?customersId=" +
        customersId
    );
  }
  GetLeadGenerationByLeadNoforCustomer(customersId: number): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/LoanApplication/GetLeadGenerationByLeadNoforCustomer?customersId=" +
        customersId
    );
  }


  GetTempCustomerById(customersId: number): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/LoanApplication/GetTempCustomerById?customersId=" +
        customersId
    );
  }



  
  GetTempCIFById(customersId: number): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/LoanApplication/GetTempCustomerById?customersId=" +
        customersId
    );
  }
  //#endregion

  //#region
  getLeadDocumentTypeList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/LoanApplication/GetDocumentType");
  }
  GetCustomerDetailsForEdit() {
    return this.http.get(dcbsUrl + "/api/Customers/GetCustomerDetailsForEdit");
  }

  GetTempUDFInfoById(id:number) {
    return this.http.get(dcbsUrl + "/api/LoanApplication/GetTempUDFInfoById?id=" + id);
  }
  DeleteTempUDFInfoById(Id:any){

    return this.http.delete(dcbsUrl +'/api/LoanApplication/DeleteTempUDFInfoById?Id=' +Id);
  }
  GetCustomerDetailsForAdminEdit(id: number) {
    return this.http.get(
      dcbsUrl +
        "/api/Customers/GetCustomerDetailsForEditForAdmin?customerId=" +
        id
    );
  }

  GetCustomerAccountNoForChange(id: any): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/Customers/GetCustomerAccountNoForChange/" + id
    );
  }

  GetCustomerAccountNo(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Customers/GetCustomerAccountNo");
  }

  GetAllCustomerAccountNo(): Observable<any> { 
    return this.http.get(dcbsUrl + "/api/Customers/GetAllCustomerAccountNo");
  }
  GetCustomerDPSAccountNo(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Customers/GetCustomerDPSAccountNo");
  }

  GetMobileBanking(accountType: string): Observable<any> {
    return this.http.get(dcbsUrl + "/GetMobileBankingInfo/" + accountType);
  }

  GetDPSPaymentTypes(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/GetDPSPaymentTypes");
  }

  // GetMobileBankingById(id:number):Observable<any>{
  //   return this.http.get(dcbsUrl + '/GetMobileBankingByMobileNo/' + id);
  // }

  GetMobileBankingById(id: number, accountType: string): Observable<any> {
    return this.http.get(
      `${dcbsUrl}/GetMobileBankingByMobileNo/${id}/${accountType}`
    );
  }

  SaveMobileBanking(model: any) {
    return this.http.post(
      dcbsUrl + "/api/CashTransaction/SaveMobileBankingInfo",
      model
    );
  }

  getAutocompleteSuggestions(searchTerm: string): Observable<any[]> {
    return this.http.get<any[]>(
      dcbsUrl + "/api/CashTransaction/GetMobileNumberAutoComplete",
      { params: { searchTerm } }
    );
  }

  GetCreditCustomerNo(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/CustomerAccount/GetCreditCustomerAccount"
    );
  }
  GetBankAccountNo(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/Customers/GetBankBeneficiaryAccountNo"
    );
  }

  GetBankBeneficiaryAccountByUser(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/Customers/GetBankBeneficiaryAccountByUser"
    );
  }

  GetBankBeneficiaryAccountNoById(accountId: number): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/GetBankBeneficiaryAccountNoById/" + accountId
    );
  }

  //#endregion
  getProfileUpdatePercentage(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/Customers/GetCustomerProfileUpdatePercentage"
    );
  }
  GetCustomerProfileUpdatePerByCustId(custId: number): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/Customers/GetCustomerProfileUpdatePerByCustId?customerId=" +
        custId
    );
  }

  getProfileUpdatePercentageByAdmin(id: number): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/Customers/GetCustomerProfileUpdatePercentageByAdmin?customerId=" +
        id
    );
  }
  GetTempCustomerProfileUpdatePercentage(id: number): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/LoanApplication/GetTempCustomerProfileUpdatePercentage?customerId=" +
        id
    );
  }

  GetMobileRechargeInfo(): Observable<any> {
    return this.http.get(dcbsUrl + "/GetMobileRechargeInfo/");
  }

  GetMobileRechargeByMobileNo(mobileNo: string): Observable<any> {
    return this.http.get(dcbsUrl + "/GetMobileRechargeByMobileNo/" + mobileNo);
  }

  GetMobileOperator(): Observable<any> {
    return this.http.get(dcbsUrl + "/GetMobileOperatorInfoAll/");
  }
  GetMobileOperatorsList(operatorType: string): Observable<any> {
    return this.http.get(dcbsUrl + "/GetMobileOperatorsList/" + operatorType);
  }

  getCustomerByMobile(nid: string, mobile: string): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/api/Customers/GetCustomerByMobile?nid=" +
        nid +
        "&mobile=" +
        mobile
    );
  }

  ////WhiteList

  getlistWhiteListedCustomer(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/CustomerAccount/GetAllWhiteListedCustomer"
    );
  }

  UploadWhiteListCustomer(model: any) {
    return this.http.post(
      dcbsUrl + "/api/CustomerAccount/WhiteListCustomerUpload",
      model
    );
  }
  GetDPSCustomerInfoByAccountNoById(accountId: number): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/GetCustomerDPSAccountsByCustomerAccountId/" + accountId
    );
  }
  GetDPSBalanceByAccountId(accountId: number): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/GetDPSBalanceByAccountId/" + accountId
    );
  }
  GetDPSOSByAccountId(accountId: number): Observable<any> {
    return this.http.get<any>(dcbsUrl + "/GetDPSOSByAccountId/" + accountId);
  }

  SaveDPSCollection(model: any) {
    return this.http.post(
      dcbsUrl + "/api/CashTransaction/SaveDPSCollection",
      model
    );
  }

  GetCustomerDPSInfoForEncashment(accountId: number): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/GetCustomerDPSInfoForEncashment/" + accountId
    );
  }

  SaveDPSEncashment(model: any) {
    return this.http.post(
      dcbsUrl + "/api/CashTransaction/SaveDPSEncashment",
      model
    );
  }

  GetDPSOS(accountId: number): Observable<any> {
    return this.http.get<any>(dcbsUrl + "/GetDPSOS/" + accountId);
  }

  GetCustomerDPSAccountNoWithoudOD(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/Customers/GetCustomerDPSAccountNoForEncashment"
    );
  }

  //Terms And Condition

  saveTerms(model: any): Observable<any> {
    return this.http.post(
      dcbsUrl + "/api/CustomerAccount/SaveTermsAndConditions",
      model
    );
  }

  GetChargeMasters(typeId: number): Observable<any> {
    return this.http.get(dcbsUrl + "/api/GetChargeMasters?accTypeId=" + typeId);
  }

  GetChargeDetails(productId: number): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/GetChargeDetails?productId=" + productId
    );
  }

  GenerateCustomerAccNoinfo(): Observable<any> {
    return this.http.get<any>(dcbsUrl + "/api/GenerateCustomerAccNo");
  }
/*   GetProductCharges(productId: number): Observable<any> {
    debugger
     
    return this.http.get<any>(
      dcbsUrl +
        "/api/GetProductCharges?productId=" +
        productId
    );
  } */

  GetProductCharges(productId: any): Observable<any> { 
    return this.http.get(cifUrl + "/api/GetProductCharges?productId=" + productId );
  }


  GetBankingService(): Observable<any> { 
    return this.http.get(cifUrl + "/api/GetBankingService" );
  }
  getProductBuilderByAccountNo(accountNo: string): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/GetProductBuilderByAccountNo/" + accountNo
    );
  }
  GetAllSectorInfo(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/LoanApplication/GetAllSectorInfo");
  }

  GetAllCustomerClass(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/LoanApplication/GetAllCustomerClass");
  }

  GetAllInstituteInfo(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/LoanApplication/GetAllInstituteInfo");
  }

  GetAllCustomerType(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/LoanApplication/GetAllCustomerType");
  }


  PostTempUDFInfo(model: any) {
    return this.http.post(
      dcbsUrl + "/api/LoanApplication/PostTempUDFInfo",
      model
    );
  }

  getCurrencyByCustomerAccountId(accountId: number): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/get-currency-by-accountId/" + accountId
    );
  }


  getCustomerId(): Observable<any> {     
    return this.http.get<any>(
      dcbsUrl + "/api/LoanRegisters/get-user-id/"
    );
  }

  // GetUserById(id: number) {
  //   debugger
  //   return this.http.get(dcbsUrl + "GetCustomerById/" + id);
  // }

  GetUserById(customersId: number): Observable<any> { 
    return this.http.get(
      dcbsUrl +
        "/api/LoanApplication/GetUserByCustomerId?customersId=" +
        customersId
    );
  }

  saveUserbasic(model: any) {
    return this.http.post(
      dcbsUrl + "/api/LoanApplication/PostBasicUserInfo",
      model
    );
  }


  GetUserContactByCustomerId(customersId: number): Observable<any> {
    debugger
    return this.http.get(
      dcbsUrl +
        "/api/LoanApplication/GetUserContactByCustomerId?customersId=" +
        customersId
    );
  }

  UserContactsave(model: any) {
    return this.http.post(
      dcbsUrl + "/api/LoanApplication/PostUserContactInfo",
      model
    );
  }
}
