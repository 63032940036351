<section>
    <div class="card" style="width: 100%;">
      <div class="card-body">
        
          <h4 class="card-title" style="color: black">Pending Fund Transfer List</h4>
          <hr> 
  
          <div *ngIf="noDataAvailable">
  
            <p style="text-align: center;font-size: 25px;font-weight: 700;">No data available</p>
          </div>
          <div *ngIf="!noDataAvailable">
  
  
  
            <br>
            <div class="table-container" style="max-height: 700px; overflow-y: auto;">
  
              <table id="example" class="table table-striped table-bordered scrollable-table"
                style="width: 100% !important;">
                <thead>
                  <tr>
                    <th>SL No.</th>
                    <th>Action</th>
                    <th>Transaction No </th>
                    <th>Transaction Date </th>
                    <th>Transaction Type</th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Balance</th>
  
                    <th>Receiver</th>
                    <th>Sender</th> 
  
                   </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of transactionList; let i = index;">
                    
                    <td>{{i + 1}}</td>
  
                    <td>
                        <button class="btn btn-info btn-sm mb-1" (click)="openBuilderDetailsModal(fundTransferData); getProductDetails(item.id)"><i class="fa fa-list"></i></button>

                  </td>  
                    
                    <td>{{item.transactionNo}}</td>
                    <td>{{item.transactionDate | date}}</td>
  
                    <td>
                      {{item.transactionType===0 ? 'Open Account':
                      item.transactionType===1?'Payment':item.transactionType===2?'Add
                      Money':item.transactionType===3?'Fund Transfer':''}}
   
                    </td>
                    <td>{{item.currencyInfo?.code}}</td>
                    <td> {{item.amount}}</td>
                    <td>{{item.balance}}</td>  
                    <td>
                      {{item.debitACNo}}
                       
  
                    </td>
                    <td>
                      {{item.creditACNo}}
                      
  
                    </td> 
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
         
      </div>
    </div>
  </section>

  <ng-template #fundTransferData let-c="close" let-d="dismiss">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Transfer Informations</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tr>
                            <th>Transaction No</th>
                            <td>{{transferDetails?.transactionNo}}</td>
                            <th>Transaction Date</th>
                            <td>{{ transferDetails?.transactionDate }}</td>
                        </tr>
                        <tr>
                            <th>Transaction Type</th>
                            <td>{{transferDetails?.transactionType===0 ? 'Open Account':
                                transferDetails?.transactionType===1?'Payment':transferDetails?.transactionType===2?'Add
                                Money':transferDetails?.transactionType===3?'Fund Transfer':''}} 
                            <th>Currency</th>
                            <td>{{ transferDetails?.currencyInfo?.code }}</td>
                        </tr>
                        <tr>
                            <th>Balance</th>
                            <td>{{ transferDetails?.balance }}</td>
                            <th>Amount</th>
                            <td>{{transferDetails?.amount}}</td>
                        </tr>
                         
                        <tr>
                            <th>Sender</th>
                            <td>{{ transferDetails?.senderAccount?.customerAccountNo }}</td>
                            
                            <th>Receiver</th>
                            <td>{{ transferDetails?.receiverAccoount.customerAccountNo }}</td>
                        </tr>
                         
                         
                         
                        
                    </table>
                </div>
                 
                
            </div>
            <div class="modal-footer">
                
                
                     <button type="button" class="btn btn-success btn-sm" (click)="Approve(transferDetails?.id)">Approve</button>
                     <button type="button" class="btn btn-danger btn-sm" (click)="Reject(transferDetails?.id)">Reject</button>
                    <button type="button" class="btn btn-warning btn-sm" (click)="c('Close click')">Close</button>
               
                    
                  
             </div>
        </div>
    </div>
  </ng-template>