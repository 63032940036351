export class AccountClosures{
    id: number = 0;
    customerAccountId: number | null = null;
    lienAmount: number | null = null;
    provisionedProfit: number | null = null;
    profit: number | null = null;
    taxonprofit: number | null = null;
    closingCharge: number | null = null;
    VAT: number | null = null;
    exciseDuty: number | null = null;
    netPayable: number | null = null;
    transactionType: string = '';
    amount: number | null = null;
}