import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
//const apiUrl=environment.baseUrl + '/api/InterestCalculation/'


const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class CapitalizeFrequencyService {

  constructor(private http:HttpClient) { }
  //#region

getCapitalizeFrequencyList():Observable<any>{
  return this.http.get(dcbsUrl + '/api/InterestCalculation/GetAllCapitalizeFrequencyList')
}

getCapFreqListByProBuildeType(id:number):Observable<any>{
  return this.http.get(dcbsUrl + '/api/InterestCalculation/GetAllCapFreqListByProBuilderType?id='+id)
}

getCapitalizeFrequencyById(id:number){
  return this.http.get(dcbsUrl + '/api/InterestCalculation/GetCapitalizeFrequencyById')
}

saveCapitalizeFrequency(model:any){
  return this.http.post(dcbsUrl + '/api/InterestCalculation/SaveCapitalizeFrequency',model)
}

deleteCapitalizeFrequency(id:any){
  return this.http.delete(dcbsUrl + '/api/InterestCalculation/DeleteCapitalizeFrequency/' + id)
}
getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
  return this.http.get(dcbsUrl + '/api/InterestCalculation/GetCapitalizationFrequencyWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
}
//#endregion
}
