
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
//const apiUrl=environment.baseUrl;

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;


@Injectable({
  providedIn: 'root'
})
export class AccountingreportService {

  constructor(private http: HttpClient) { }

  GetMainGroupInfo(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetMainGroup');
  }

  GetSubGroupByMgId(Id: number): Observable<any> {
    return this.http.get(environment.dcbsApi + '/api/AccountGroup/GetSubGroupByMgId?mgId=' + Id);
  }

  GetLedgerByGId(Id: number): Observable<any> {
    return this.http.get(environment.dcbsApi + '/api/AccountGroup/GetLedgerByGId?gId=' + Id);
  }
  GetSubLedgerByGId(Id: number): Observable<any> {
    return this.http.get(environment.dcbsApi + '/api/AccountGroup/GetAllSubLedgerbyledger?id=' + Id);
  }


  GetSbuList(): Observable<any> {
    //alert('subList');
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetSbuList');
  }
  GetProjectist(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetProjectist');
  }
  Getledgerlist(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/Ledger/GetLedgerList');
  }
}
