import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth/auth.service';
import { environment } from 'environments/environment';
import { Observable, fromEvent, timer } from 'rxjs';
import { takeWhile, timeout } from 'rxjs/operators';


const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: "root",
})
export class SessionService {

  // private readonly INACTIVE_TIMEOUT = environment.sessionTimeout;
  INACTIVE_TIMEOUT: number = 36000000;
  private timer: any;
  private userSessionId: any;
  private browserIdentity: string;
  private username: string;

  constructor(
    private router: Router,
    private auth: AuthService,
    private http: HttpClient
  ) {
    // this.GetSessionTime();
    var username = localStorage.getItem("userName");

    var data = this.GetActiveSessionByUserName(username, localStorage.getItem('browserIdentity'));
  }

  initSessionTimer(): void {
    let lastActivityTime = Date.now();


    const activity$ = fromEvent(document, "mousemove").pipe(
      takeWhile(() => true)
    );

    activity$.subscribe(() => {
      lastActivityTime = Date.now();
      clearTimeout(this.timer);
      this.startTimer(lastActivityTime);
    });

    this.startTimer(lastActivityTime);
  }

  private startTimer(lastActivityTime: number): void {
    // Retrieve thumb from sessionStorage and parse it safely
    const thumb = sessionStorage.getItem("thumb");
    if (!thumb) {
     // console.error("No thumb data found in sessionStorage.");
      return;
    }

    let sessionTimeMin: number;
    try {
      sessionTimeMin = JSON.parse(thumb).thumbRule.sessionTimeMin;
    } catch (e) {
      //console.error("Error parsing thumb data from sessionStorage:", e);
      return;
    }

    // Convert sessionTimeMin from minutes to seconds and then to milliseconds
    const timeOutMs = sessionTimeMin * 60 * 1000;

    // Calculate time since last activity
    const timeSinceLastActivity = Date.now() - lastActivityTime;

    // Define INACTIVE_TIMEOUT if not already defined
    const INACTIVE_TIMEOUT = timeOutMs; // Assuming INACTIVE_TIMEOUT should be the same as timeOutMs

    // Calculate time to logout
    const timeToLogout = INACTIVE_TIMEOUT - timeSinceLastActivity;

    // Clear any previous timer
    if (this.timer) {
      clearTimeout(this.timer);
    }
    // console.log(timeToLogout);
    // Ensure timeToLogout is non-negative
    if (timeToLogout > 0) {
      this.timer = setTimeout(() => {
        this.logout();
      }, timeToLogout);
    } else {
      this.logout();
    }
  }

  GetAllowNewTabForUSer(): Observable<any> {
    return this.http.get<any>(`${dcbsUrl}/api/GetThumbRules`);
  }

  private logout(): void {
    sessionStorage.clear();
    this.auth.clearAuthToken();
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    //New Tab Open as Login or selected page access//
    //this.router.navigate(['/pages/login']);


   this.GetAllowNewTabForUSer().subscribe(
    (response: any) => {
      if (response.allowMultipleLogin === false) {
        this.router.navigate(["/pages/login"]);
      }
    });
  }

  saveUserLastSession(username: string, sessionId: string, browserName:string): Observable<any> {

    if(localStorage.getItem('browserIdentity') != null && localStorage.getItem('browserIdentity') != 'null'){
      browserName = localStorage.getItem('browserIdentity');
    }
    else{
      const randomNumber = Math.floor(Math.random() * 10000); // Adjust range as needed
      browserName = browserName + '_' + randomNumber;
      localStorage.setItem('browserIdentity', browserName);
    }

    const requestBody = { username, sessionId, browserName };
    return this.http.post<number>(
      `${dcbsUrl}/api/SaveUserLastSessionId`,
      requestBody
    );
  }

  GetActiveSessionByUserName(username: string, browserIdentity:string): Observable<any> {
    return this.http.get<any>(
      `${dcbsUrl}/api/GetActiveSessionByUserName?userName=${username}&browserIdentity=${browserIdentity}`
    );
  }

  fetchAndSetUserSession(username: string, browserIdentity: string) {
    this.username = username;
    this.browserIdentity = browserIdentity;

    this.GetActiveSessionByUserName(username, browserIdentity).subscribe(
      (response: any) => {
        this.userSessionId = response.sessionId;

        // sessionStorage.setItem('userSessionId', this.userSessionId);

        if (this.userSessionId != sessionStorage.getItem("sessionToken")) {
          //logged out if multiple session found
          this.logout();
        }
      },
      (error: any) => {
        //console.error("Error occurred:", error);
      }
    );

    this.validateUserSession().subscribe((browserValidity) => {
      if(!browserValidity){
        this.logout();
      }
    });

  }

  validateUserSession() {
    // if(this.userSessionId != sessionStorage.getItem('sessionToken')){
    //   this.logout();
    // }

    return this.http.get<any>(`${dcbsUrl}/api/ValidateSession?browserIdentity=${this.browserIdentity}&username=${this.username}`);
  }

  GetThumbRule(): Observable<any> {
    return this.http.get<any>(`${dcbsUrl}/api/GetThumbRule`);
  }

  GetSessionTime() {
    this.GetThumbRule().subscribe(
      (response) => {
        this.INACTIVE_TIMEOUT = response?.sessionTimeMin * 60;
      },
      (error) => {
        //console.error("Error fetching thumb rule:", error);
      }
    );
  }
}
