import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
//const apiUrl=environment.baseUrl + '/api/Charge/'


const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class ChargeService {

  constructor(private http:HttpClient) { }


  //#region Commission
  GetAllChargeList():Observable<any>{
    return this.http.get(dcbsUrl + '/api/Charge/GetAllChargeList')
  }
  //#region Commission
  GetAllChargeListByProBuilderType(id:number):Observable<any>{
    return this.http.get(dcbsUrl + '/api/Charge/GetAllChargeListByProBuilderType?id='+id)
  }
  getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/Charge/GetChargeListWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }
  GetChargeById(id:number){
    return this.http.get(dcbsUrl + '/api/Charge/GetChargeById')
  }

  SaveCharge(model:any){
    return this.http.post(dcbsUrl + '/api/Charge/SaveCharge',model)
  }

  DeleteCharge(id:any):Observable<any>{
    return this.http.delete(dcbsUrl + '/api/Charge/DeleteCharge/' + id)
  }


  getAllCategorylistData(): Observable<any> { 
    return this.http.get(dcbsUrl + "/api/BalanceType/AllAccountTypeList");
  }
  //#endregion

}
