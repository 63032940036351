import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';


const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;


@Injectable({
  providedIn: 'root'
})
export class OccupationService {

  constructor(private http: HttpClient) { }

  getlist():Observable<any>
   {
        return this.http.get(dcbsUrl + '/api/Occupation/GetAllOccupation');
   }
    getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/Occupation/GetAllOccupationWithPaginationSearch?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }


   save(model: any){
        return this.http.post(dcbsUrl + '/api/Occupation/ekyc/InsertOccupation',model);
      }

      delete(Id:any){

        return this.http.delete(dcbsUrl +'/api/Occupation/DeleteOccupationbyId?Id=' +Id);
      }
}
