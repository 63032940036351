<section>
    <div class="card" style="width: 100%;">
      <div class="card-body">
        
          <h4 class="card-title" style="color: black">Fund Transfer List</h4>
          <hr> 
  
          <div *ngIf="noDataAvailable">
  
            <p style="text-align: center;font-size: 25px;font-weight: 700;">No data available</p>
          </div>
          <div *ngIf="!noDataAvailable">
  
  
  
            <br>
            <div class="table-container" style="max-height: 700px; overflow-y: auto;">
  
              <table id="example" class="table table-striped table-bordered scrollable-table"
                style="width: 100% !important;">
                <thead>
                  <tr>
                    <th>SL No.</th>
                    <th>Status</th>
                    <th>Transaction No </th>
                    <th>Transaction Date </th>
                    <th>Transaction Type</th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Balance</th>
  
                    <th>Receiver</th>
                    <th>Sender</th> 
  
                   </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of transactionList; let i = index;">
                    
                    <td>{{i + 1}}</td>
  
                    <td>
                      <span *ngIf="item.status === 1" class="badge badge-pending">Pending</span>
                <span *ngIf="item.status === 2" class="badge badge-verified">Approve</span>
                <span *ngIf="item.status === 3" class="badge badge-not-verified">Reject</span>
                         
                    </td>  
                    
                    <td>{{item.transactionNo}}</td>
                    <td>{{item.transactionDate | date}}</td>
  
                    <td>
                      {{item.transactionType===0 ? 'Open Account':
                      item.transactionType===1?'Payment':item.transactionType===2?'Add
                      Money':item.transactionType===3?'Fund Transfer':''}}
                      
                    </td>
                    <td>{{item.currency?.currency}}</td>
                    <td> {{item.amount}}</td>
                    <td>{{item.balance}}</td>
  
                    <td>
                      {{item.debitACNo}}
                       
  
                    </td>
                    <td>
                      {{item.creditACNo}}
                      
  
                    </td>
                     
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
         
      </div>
    </div>
  </section>

   