import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
//const apiUrl = environment.baseUrl+'/api/LoanProductBuilder/';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class LoanProductService {

  constructor(private http: HttpClient) { }


  saveloanCollections(model:any){
    return this.http.post(dcbsUrl  + '/api/LoanProductBuilder/SaveloanCollections', model);
  }

  SaveloanCollectionsbyCustomer(model:any){
    return this.http.post(dcbsUrl  + '/api/LoanProductBuilder/SaveloanCollectionsbyCustomer', model);
  }

  SaveloanSettlementbyCustomer(model:any){
    return this.http.post(dcbsUrl  + '/api/LoanProductBuilder/SaveloanSettlementbyCustomer', model);
  }


  getLoanProductList(): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetAllLoanProdictList/');
   }

   getLoanProductListByLoanTypeId(loanTypeId: number): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetAllLoanProdictListByLoanTypeId/'+loanTypeId);
   }


   getNanoLoanProduct(): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetAllNanoLoanProdictList/');
   }


   GetAllPendingProductByUserName(): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetAllPendingProductByUserName');
   }

   GetAllPendingProductByRole(): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetAllPendingProductByRole');
   }

   AproveLoanProductAndShiftNext(productId: number): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/AproveLoanProductAndShiftNext?productId=' + productId);
   }

   AproveLoanProductByUserRole(productId: number): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/AproveLoanProductByUserRole?productId=' + productId);
   }

   getProductBuilderList():Observable<any>{
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetAllProductBuilderList');
   }

   getLoanProductDetailsById(id): Observable<any>{
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetLoanProductDetailsById/'+id);
   }

   saveLoanProduct(model: any){
    return this.http.post(dcbsUrl  +'/api/LoanProductBuilder/SaveLoanProductBuilders' , model);
  }

  getLoanProductBuilderDetailsById(id: number): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetLoanProductBuilderDetailsById2/' + id);
  }

  getFeesInfoByProductId(id: number): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetFeesInfoByProductId/' + id);
  }

  getOtherFeesInfoByProductId(id: number): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetOtherFeesInfoByProductId/' + id);
  }


  getLoanProductByCode(Code: string): Observable<any>
  {
    return this.http.get<any>(dcbsUrl +'/api/LoanProductBuilder/GetLoanProductBuilderByCode/' + Code, { responseType: 'json' });
  }
  getLoanProductVersionByCode(version: string,code:string): Observable<any>
  {
    return this.http.get<any>(dcbsUrl +'/api/LoanProductBuilder/GetLoanProductBuilderByVersionCode?version=' +version+'&code='+ code, { responseType: 'json' });
  }

  getAllReducingMethod(): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetAllReducingMethodList');
   }

  getAllLoanDuration(): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetAllLoanDurationList');
   }

   getLoanProductAmontFromLimit(id: number): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetLoanProductAmountByProductId/' + id);
  }

  getLoanProductCodeByType(typeId: number): Observable<any>
  {
    return this.http.get<any>(dcbsUrl +'/api/LoanProductBuilder/GenerateLoanProductCode/'+typeId, { responseType: 'json' });
  }

   getTenureInfoByTenureId(id: number): Observable<any> {
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetNanoLoanDurationByTenureId/' + id);
  }
  deActive(id: any,s: boolean) {
    return this.http.delete(dcbsUrl  + "/api/LoanProductBuilder/DeActiveProductBuilder?id=" + id+'&status='+s);
  }
  getMFSAccountList():Observable<any>{
    return this.http.get(dcbsUrl  + '/api/LoanProductBuilder/GetAllMFSAccountList');
   }

   getProductCodeByType(typeId: number): Observable<any>
   {
     return this.http.get<any>(dcbsUrl +'/api/LoanProductBuilder/GenerateProductCode/'+typeId, { responseType: 'json' });
   }

   save(model: any) { 
    return this.http.post(
      dcbsUrl + "/api/ProductBuilder/SaveProductBuildersData",
      model
    );
  }
 
}
