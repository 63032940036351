import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
// const apiUrl = environment.baseUrl + '/api/AccountGroup/';
// const apiUrl2 = environment.baseUrl + '/api/GroupNature/';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private http: HttpClient) { }

  GetAccountGroupList(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetAccountGroupList')

  }

  GetAllAccountGroupSubGroupList(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetAllAccountGroupSubGroupList')

  }


  AllAccountGroupSubGroupList(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/AccountGroup/AllAccountGroupSubGroupList')

  }

  getGroupListWithPaginationAndSearch(currentPage: any, itemsPerPage : any, searchValue : string){
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetAccountGroupWithPaginationAndSearch?page=' + currentPage + '&pageSize=' + itemsPerPage + '&search=' + searchValue);
  }

  GetAccountGroupSubGroup(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetAllAccountGroupSubGroupList')

  }

  getSubGrpByGrpId(grpId:number):Observable<any>{
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetAccountSubGroupByGroupId?parentId='+grpId)
  }

  GetAccountGroupListByNature(id:number): Observable<any> {
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetAccountGroupByNatureId?nId='+id)

  }

  GetAccountMainGroupByNatureId(id:number): Observable<any> {
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetAccountMainGroupByNatureId?nId='+id)

  }
  SaveAccountGroup(model: any) {

    return this.http.post(dcbsUrl + '/api/AccountGroup/SaveAccountGroup', model)
  }

  DeleteaccountGroupById(id: any) {
    return this.http.delete(dcbsUrl + '/api/AccountGroup/DeleteaccountGroupById?Id=' + id)
  }


  GetGroupNatureList(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/GroupNature/GetGroupNatureList')

  }

  GetGroupCode(nId: number) {

    return this.http.get(dcbsUrl + '/api/AccountGroup/GetGroupCodeByNatureId?nId=' + nId);
  }
  GetSubGroupCode(nId: number) {

    return this.http.get(dcbsUrl + '/api/AccountGroup/GetSubGroupCodeByNatureId?nId=' + nId);
  }


  ChildAccountGroupByNature(id: number): Observable<any> {
    return this.http.get(dcbsUrl + '/api/AccountGroup/ChildAccountGroupByNatureId?nId=' + id)

  }
  GetIncomeExpenseAccountGroup(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/AccountGroup/GetIncomeExpenseAccountGroup')

  }

}
