import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'app/customer/service/customer.service';
import { TransectionType } from 'app/master-data/products/model/transection-type';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { LayoutService } from 'app/shared/services/layout.service';
import swal from 'sweetalert'; 
import Swal from 'sweetalert2';


@Component({
  selector: 'app-statement-logs',
  templateUrl: './statement-logs.component.html',
  styleUrls: ['./statement-logs.component.scss']
})
export class StatementLogsComponent implements OnInit { 

  statementList: any[] = [];
  SearchAccNo: boolean = false; 
  transferDetails: any;
  currentUser: string = '';
  transactionType: string='';  


  constructor(private customerService: CustomerService,
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private changeDetect: ChangeDetectorRef,
    private modalService: NgbModal,

    private datePipe: DatePipe) {

  }

  ngOnInit(): void {
     
    this.gettransactionList();


    this.layoutService.getCurrentUserName().subscribe(res => {
      this.currentUser = res;
    }
    );

  }


  gettransactionList() {
    this.customerService.getStatementLogsList().subscribe(res => {  
       
      this.statementList = res;
      console.log("w8e", this.statementList)
      this.changeDetect.detectChanges();

    })

  }
 

 

 
}
