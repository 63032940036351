//const baseUrl = "https://localhost:7228";
const baseUrl = "https://arishgateway.opuserp.com";

const dpsApi = baseUrl + "/dps"
const authApi = baseUrl + "/auth"
const dcbsApi = baseUrl + "/dcbs"
const cifApi = baseUrl + "/cif"



const sessionTimeout = 22000000;

const eKycUrl="https://gq6msjjt-7062.asse.devtunnels.ms";

export const environment = {
  production: true,
  baseUrl: baseUrl,
  dcbsApi: dcbsApi,
  dpsApi: dpsApi,
  authApi: authApi,
  cifApi: cifApi,
  sessionTimeout: sessionTimeout,

  registernurl: baseUrl + "/register",
  userurl: baseUrl + "/users",
  customerurl: baseUrl + "/users",
  eKycUrl:eKycUrl
};
