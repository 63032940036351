import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FiscalyearService } from '../../basic-service/fiscalyear.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { DatePipe } from '@angular/common';
import { Fiscalyear } from '../../basic-model/fiscalyear';

@Component({
  selector: 'app-currency-exchange-rate',
  templateUrl: './currency-exchange-rate.component.html',
  styleUrls: ['./currency-exchange-rate.component.scss']
})
export class CurrencyExchangeRateComponent implements OnInit {

  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: Fiscalyear[] = [];

CurrencyInfosList: any[] = [];
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  showSaveForm: string = '';
CurrencyCode: string = '';

  constructor(
    private _service: FiscalyearService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private datePipe: DatePipe,
  ) {
    this.frmGroup = new FormGroup({
      id: new FormControl(null), // For edit mode, this will be populated with the existing ID.
      CurrencyId: new FormControl(null, Validators.required),  // CurrencyId should be required
      BaseCurrencyCode: new FormControl('', Validators.required),  // BaseCurrencyCode should be required
      Rate: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$')]), // Rate should be required and a valid number.
      Date: new FormControl('', Validators.required),  // Date should be required
    });
  }

  ngOnInit(): void {
    this.GetAllCurrencyExchangeRatelist();
    this.GetAllCurrencyInfosList();
    this.showSaveForm = 'saveList';
  }

  model = {
    Id: null,
    CurrencyId: null,
    BaseCurrencyCode: null,
    Rate: null,
    Date: null
  };
  getCurrencyById(Id: number) {
    this._service.GetCurrencyInfoById(Id).subscribe(res => {
      this.CurrencyCode = res.code;
    
    })
  }
  onsubmit() {
    // Step 1: Check if the form is in edit mode (isEdit)
    if (this.isEdit) {
      this.model.Id = this.frmGroup.value.id;  // Assign form value to model
    }
  
    // Step 2: Assign the rest of the form fields to the model
    this.model.CurrencyId = this.frmGroup.value.CurrencyId;
    this.model.BaseCurrencyCode = this.CurrencyCode;
    this.model.Rate = this.frmGroup.value.Rate;
    this.model.Date = this.frmGroup.value.Date;
    console.log(this.model);
    // Step 3: Validate the form fields (optional but recommended)
    if (!this.frmGroup.valid) {
      this.shared.ShowAlert("Error", "Please fill in all required fields", "error");
      return;  // Exit if form is invalid
    }
  
    // Step 4: Determine if it's a new record (save) or existing (update)
    if (this.model.Id) {
      // Update existing record
      this._service.saveCurrencyExchangeRate(this.model).subscribe(
        (res) => {
          // Success response
          this.shared.ShowAlert("Success", "Updated Successfully", "success");
          location.reload();
          this.isEdit = false;  // Reset edit mode
          this.showSaveForm = 'saveList';  // Switch to list view (or any other desired behavior)
        },
        (err) => {
          // Error handling
          this.shared.ShowAlert("Error", "Update failed. Please try again.", "error");
          console.error(err);  // Log error for debugging
        }
      );
    } else {
      // Save new record
      this._service.saveCurrencyExchangeRate(this.model).subscribe(
        (res) => {
          // Success response
          this.shared.ShowAlert("Success", "Saved Successfully", "success");
          location.reload();
          this.isEdit = false;  // Reset edit mode
          this.showSaveForm = 'saveList';  // Switch to list view
        },
        (err) => {
          // Error handling
          this.shared.ShowAlert("Error", "Save failed. Please try again.", "error");
          console.error(err);  // Log error for debugging
        }
      );
    }

  }
  


  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.GetAllCurrencyExchangeRatelist();
  }
  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.GetAllCurrencyExchangeRatelist();
  }

  onSearch() {
    this.currentPage = 1; // Reset the current page to 1 when a new search is performed
    this.GetAllCurrencyExchangeRatelist();
  }

  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    this.reset();
  }

  reset() {
    this.isEdit = false;
    this.frmGroup.patchValue({
      id: null,
      yearName: null,
      yearStatus: null,
      startDate: null,
      endDate: null,
      lockDate: null
    })
  }

  GetAllCurrencyExchangeRatelist() {
    this._service.GetAllCurrencyExchangeRatelist(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res: any) => {
      this.list = res.data;
      console.log(this.list)
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }
  GetAllCurrencyInfosList() {
    this._service.GetAllCurrencyInfos().subscribe((res: any) => {
      this.CurrencyInfosList = res;
    
    })
  }

  refresh(data: Fiscalyear) {
    this.model2.yearName = "";
    this.model2.startDate = this.model2.startDate;
    this.model2.endDate = this.model2.endDate;
    this.model2.yearStatus = "";
    this.model2.lockDate = this.model2.lockDate;
  }

  model2 = new Fiscalyear();



  delete(Id: any) {
    this._service.deleteCurrencyExchangeRate(Id).subscribe(res => {
      this.shared.ShowAlert("Success", "Data deleted successfully", "success");
      this.GetAllCurrencyExchangeRatelist();
    })
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.frmGroup.patchValue({
      id: item.id,
      CurrencyId:item.currencyId,
      Rate: item.rate,
      Date: this.datePipe.transform(item.date, 'yyyy-MM-dd')
    })

    this.CurrencyCode = item.baseCurrencyCode;
  }
}
