<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col-9">
                    <h5 class="card-title" style="color: black">Saving Account Product</h5>
                </div>
                <div class="col-2" style="text-align: right;">
                    <button type="button" data-toggle="tooltip" [routerLink]="['/product/deposit-product-builder-list']"
                        class="btn btn-info" style="float:right;margin-right:5px;margin-left: 8px;">Go
                        to List</button>
                </div>
                <div class="col-1">
                    <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                         <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                    </button>
                </div>
            </div>
            <hr>
            <form [formGroup]="frmGroup">
                <div asp-validation-summary="All" class="text-danger"></div>
                <div class="row">

<div class="col-md-6">
    <div class="form-group row">
        <label for="Version" class="col-sm-4 col-form-label">Product Type</label>
        <div class="col-sm-8">
             
            <select class="form-control" formControlName="loanTypeId" (change)="getProductCode($event.target.value)"
                [ngClass]="{ 'is-invalid': frmGroup.get('loanTypeId').invalid && (frmGroup.get('loanTypeId').dirty || frmGroup.get('loanTypeId').touched || submitted),
                              'is-valid': frmGroup.get('loanTypeId').valid && (frmGroup.get('loanTypeId').dirty || frmGroup.get('loanTypeId').touched || submitted)}">
                 <option *ngFor="let item of categoryList" [value]="item.id" selected>{{item?.typeName}}
                </option>
            </select>
            <span class="text-danger"
                *ngIf="frmGroup.get('loanTypeId').invalid && (frmGroup.get('loanTypeId').dirty || frmGroup.get('loanTypeId').touched || submitted) && frmGroup.get('loanTypeId').errors?.required">
                Select Loan Type
            </span>
             
        </div>
    </div>
</div>




<div class="col-md-6">
    <div class="form-group row">
        <label for="Code" class="col-sm-4 col-form-label">Code</label>
        <div class="col-sm-8">
            <input type="text" value="{{productCode}}" class="form-control" formControlName="Code"
                [readonly]="isReadOnly" placeholder="Code"
                [ngClass]="{'is-invalid': frmGroup.get('Code').invalid && (frmGroup.get('Code').dirty || frmGroup.get('Code').touched || submitted),
                  'is-valid': frmGroup.get('Code').valid && (frmGroup.get('Code').dirty || frmGroup.get('Code').touched || submitted)}">

            <span class="text-danger"
                *ngIf="frmGroup.get('Code').invalid && (frmGroup.get('Code').dirty || frmGroup.get('Code').touched || submitted) && frmGroup.get('Code').errors?.required">
                Code can't be blank
            </span>

            <span class="text-danger"
                *ngIf="frmGroup.get('Code').invalid && (frmGroup.get('Code').dirty || frmGroup.get('Code').touched || submitted) && frmGroup.get('Code').errors?.minlength">
                Code should contain at least 2 characters
            </span>
            <span class="text-danger"
                *ngIf="frmGroup.get('Code').invalid && (frmGroup.get('Code').dirty || frmGroup.get('Code').touched || submitted) && frmGroup.get('Code').errors?.uniqueCode">
                This Code Already Exist
            </span>
        </div>
    </div>

</div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="Name" class="col-sm-4 col-form-label">Name</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="Name" placeholder="Name"
                                    [readonly]="isReadOnly"
                                    [ngClass]="{
                                    'is-invalid': frmGroup.get('Name').invalid && (frmGroup.get('Name').dirty || frmGroup.get('Name').touched || submitted),
                                      'is-valid': frmGroup.get('Name').valid && (frmGroup.get('Name').dirty || frmGroup.get('Name').touched || submitted)}">

                                <span class="text-danger"
                                    *ngIf="frmGroup.get('Name').invalid && (frmGroup.get('Name').dirty || frmGroup.get('Name').touched || submitted) && frmGroup.get('Name').errors?.required">
                                    Name can't be blank
                                </span>

                                <span class="text-danger"
                                    *ngIf="frmGroup.get('Name').invalid && (frmGroup.get('Name').dirty || frmGroup.get('Name').touched || submitted) && frmGroup.get('Name').errors?.minlength">
                                    Name should contain at least 2 characters
                                </span>
                            </div>
                        </div>
                    </div>

              
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="Version" class="col-sm-4 col-form-label">Version</label>
                            <div class="col-sm-8">
                                <input type="text" id="Version" class="form-control" formControlName="Version"
                                    placeholder="Version"
                                    [ngClass]="{'is-invalid': frmGroup.get('Version').invalid && (frmGroup.get('Version').dirty || frmGroup.get('Version').touched || submitted),
                                'is-valid': frmGroup.get('Version').valid && (frmGroup.get('Version').dirty || frmGroup.get('Version').touched || submitted)}">
                                <span class="text-danger"
                                    *ngIf="frmGroup.get('Version').invalid && (frmGroup.get('Version').dirty || frmGroup.get('Version').touched || submitted) && frmGroup.get('Version').errors?.required">
                                    Version can't be blank
                                </span>
                                <span class="text-danger"
                                    *ngIf="frmGroup.get('Version').invalid && (frmGroup.get('Version').dirty || frmGroup.get('Version').touched || submitted) && frmGroup.get('Version').errors?.uniqueVersionCode">
                                    This Version For This Code Already Exist
                                </span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="Description" class="col-sm-4 col-form-label">Description</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="Description" placeholder="Description">
                            </div>
                        </div>
                    
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="Description" class="col-sm-4 col-form-label">Payment Type</label>
                            <div class="col-sm-8">
                                <select class="form-control" formControlName="paymentTypeId"
                                    [ngClass]="{
                                    'is-invalid': frmGroup.get('paymentTypeId').invalid && (frmGroup.get('paymentTypeId').dirty || frmGroup.get('paymentTypeId').touched || submitted),
                                      'is-valid': frmGroup.get('paymentTypeId').valid && (frmGroup.get('paymentTypeId').dirty || frmGroup.get('paymentTypeId').touched || submitted)}">
                                    <option value="">N/A</option>
                                    <option *ngFor="let item of allPaymentType" [value]="item.id">{{item.typeName}}
                                    </option>
                                </select>
                                <span class="text-danger"
                                    *ngIf="frmGroup.get('paymentTypeId').invalid && (frmGroup.get('paymentTypeId').dirty || frmGroup.get('paymentTypeId').touched || submitted) && frmGroup.get('paymentTypeId').errors?.required">
                                    Select Payment Type
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="Currency" class="col-sm-4 col-form-label">Currency</label>
                            <div class="col-sm-8">
                                <select class="form-control" formControlName="Currency">
                                    <option value="">N/A</option>
                                    <option *ngFor="let item of currencyList" [value]="item.id">{{item.code}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="sortorder" class="col-sm-4 col-form-label">Day Size</label>
                            <div class="col-sm-8">
                                <select class="form-control" formControlName="DaySizeId">
                                    <option value="">N/A</option>
                                    <option *ngFor="let item of daySizeList" [value]="item.id">
                                        {{item.name}}-{{item.size}} ({{item.upMark}}/{{item.downMark}})</option>
                                </select>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label">Interest Rate</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="interestRate"
                                    placeholder="Interest Rate" appDecimalOnly>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="sortorder" class="col-sm-4 col-form-label">Balance Type</label>
                            <div class="col-sm-8">
                                <select class="form-control" formControlName="BalanceTypeId">
                                    <option value="">N/A</option>
                                    <option *ngFor="let item of balanceTypeList" [value]="item?.id">
                                        {{item?.name}}</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="sortorder" class="col-sm-4 col-form-label">Interest Calculation Type</label>
                            <div class="col-sm-8">
                                 
                                <select class="form-control" formControlName="interestCalculationTypeSelect">
                                    <option value="">N/A</option>
                                    <option *ngFor="let item of intCalculationList" [value]="item.instCalType.id">
                                        {{item.instCalType.name}} - {{item.instCalType.code}}</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="sortorder" class="col-sm-4 col-form-label">Interest Calculation
                                Frequency</label>
                            <div class="col-sm-8">
                                <select class="form-control" formControlName="InterestCalculationFrequencyId">
                                    <option value="">N/A</option>
                                    <option *ngFor="let item of intCalculationFreqList" [value]="item.id">
                                        {{item.name}} - {{item.days}} Days</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                     
                    <div class="col-md-6 d-none">
                        <div class="form-group row">
                            <label for="sortorder" class="col-sm-4 col-form-label">All Time Credit</label>
                            <div class="col-sm-8">
                                <select class="form-control" formControlName="BalanceTypeId">
                                    <option value="">N/A</option>
                                    <option *ngFor="let item of balanceTypeList" [value]="item.id">
                                        {{item.name}}</option>
                                </select>
                            </div>
                        </div>

                    </div>
                     
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="sortorder" class="col-sm-4 col-form-label">Charge</label>

                            <div class="col-sm-8">

                                <div class="input-group">
                                    <select class="form-control" formControlName="chargeId" (change)="onChargeChange()">
                                        <option value="">N/A</option>
                                        <option *ngFor="let item of chargeList" [value]="item.id">
                                            {{item.code}} - {{item.name}}
                                        </option>
                                    </select>
                                    <button title="Details" value="Details" class="btn btn-sm"
                                        (click)="openModal(contentCharge)" style="height: 100%;"><i
                                            style="font-size: 30px;" class="ft-alert-circle"></i></button>
                                    <button class="btn btn-primary  btn-sm " (click)="addMultipleCharge()"
                                        style="margin-right: 15px;margin-right: 10px;margin-left: -2px">Add</button>
                                </div>

                            </div>


                           
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label"></label>
                            <div class="col-sm-8">
                                <ul>
                                    <li *ngFor="let item of selectedChargeList">
                                        {{item.code}} - {{item.name}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
 
                    <div class="col-md-6">
                        <div class="form-group row">
                          <label for="effectiveDate" class="col-sm-4 col-form-label">Effective Date</label>
                          <div class="col-sm-8">
                            <input type="date" class="form-control" formControlName="effectiveDate">
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-6">
                        <div class="form-group row">
                            <label for="status" class="col-sm-4 col-form-label">Status <span style="color:red">*</span></label>
                            <div class="col-sm-8">
                                <select class="form-control" formControlName="status">
                                    <option value="">N/A</option>
                                    <option value="1">Active</option>
                                    <option value="0">in-Active</option>
                                </select>
                            </div>
                        </div>
                    </div> -->

                      <!-- <div class="col-md-6">
                        <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label">Maximum Withdrawal Limits</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="maxwithdrawalLimit"
                                    placeholder="Maximum Withdrawal Limits" appDecimalOnly>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label">Overdraft Limits</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="overdraftLimit"
                                    placeholder="Overdraft Limits" appDecimalOnly>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label">Transaction Limit</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="transactionLimit"
                                    placeholder="Transaction Limit" appDecimalOnly>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label">Transaction  Frequencies</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" formControlName="transactionfrequencies"
                                    placeholder="Transaction Frequencies" appDecimalOnly>
                            </div>
                        </div>
                    </div>
                    -->
                </div> 

                <hr />

                <div class="row">
                    <div class="col-12">
                      <div class="content-header">GL Mapping </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-12">
                        
                          <div class="form-row">
                            <div class="col-md-12 col-12">
                              <div class="form-group mb-2">
                                <label for="basic-form-6">Event Name <span style="color:red">*</span></label>
                                <select id="productEvent" name="productEventName" formControlName="productEventName" class="form-control">
                                    <option value="0">Select Event</option>
                                  <option *ngFor="let item of productEventList" [value]="item?.id">{{item?.eventName}}-{{item?.eventCode}}</option>
                                </select>
                
                                <label for="basic-form-6">GL Type <span style="color:red">*</span></label>
                                <select id="glType" name="glType" formControlName="glType" class="form-control">
                                    <option value="">Select GL Type</option>
                                    <option value="DR">Debit</option>
                                    <option value="CR">Credit</option>
                                </select>
                              </div>
                
                
                            </div>
                            <div class="col-md-6 col-12">
                
                            </div>
                          </div>
                
                      </div>
                      <div class="col-lg-6 col-12">
                        <div class="form-row">
                          <label for="basic-form-7">GL <span style="color:red">*</span></label>
                                <ng-select class="form-control" name="glList"
                                    formControlName="eventGLId" [items]="glList" [virtualScroll]="true" bindLabel="ledger.accountName" bindValue="id" placeholder="" [(ngModel)]="plInterestLedgerId">
                                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                        <span class="option-content">{{ item?.ledger?.accountCode + '-' + item?.ledger?.accountName }}</span>
                                    </ng-template>
                                </ng-select>
                        </div>
                      </div>
                       
                
                </div>
                <br />
                          <div class="row">
                            <div class="col-md-8"></div>
                            <div class="col-md-4">
                                <button  class="btn btn-info btn-inneradd" (click)="fnAddMappedGL()" >Add</button>
                            </div>
                
                          </div>
                          <br />
                  <div class="row" *ngIf="showGlTbl">
                    <div class="col-md-1"></div>
                    <div class="col-md-9">
                        <table class="table table-bordered" *ngIf="addMaapedGL">
                            <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Event</th>
                                  <th>GL</th>
                                  <th>GL Type</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                
                
                                <tr *ngFor="let item of mappedGLList;let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{item?.eventName}}</td>
                                    <td>{{item?.accountName}}-{{item?.accountCode}}</td>
                                    <td>{{item?.glType == 'DR' ? 'Debit' : 'Credit'}}</td>
                                    <td><a class="btn btn-danger btn-sm" (click)="removeMappedGL(item?.eventId)"><i class="fa fa-remove"></i></a></td>
                                </tr>
                              </tbody>
                        </table>
                
                
                        <table class="table table-bordered" *ngIf="editMaapedGL"  >
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Event</th>
                                    <th>GL</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                
                                <tr *ngFor="let data of mappedGLList;let ii=index">
                                    <td>{{ii+1}}</td>
                                    <td>{{data?.productEvent?.eventName}}</td>
                                    <td>{{data?.ledgerRelation?.ledger?.accountCode}}-{{data?.ledgerRelation?.ledger?.accountName}}</td>
                                    <td><a class="btn btn-danger btn-sm" (click)="removeMappedGL(data?.productEventId)"><i
                                                class="fa fa-remove"></i></a></td>
                                </tr>
                                 
                            </tbody>
                        </table>
                    </div>
                
                </div>
                
                <hr />
                
                                   

                <div class="row mt-4">
                    <span class="col-md-12" style="font-weight: 800;">Approval :</span>
                    <div class="col-md-12 border">
                        <div class="form-group row"
                            style="display: flex; justify-content: center; align-items: center; margin-top: 5px;">
                            <label for="" class="col-md-2">Approver</label>
                            <div class="col-md-4">
                                <select name="" id="approver" class="form-control"
                                    (change)="ChangeApprover2($event.target)">
                                    <option value="">Choose Approver</option>
                                    <option value="{{item.id}}" *ngFor="let item of allbankusers2">{{item.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2">
                                <a href="javascript:void(0)" class="btn btn-info btn-sm"
                                    (click)="AddApprover2()">Add</a>
                            </div>
                        </div>
                        <div class="form-group row"
                            style="display: flex; justify-content: center; align-items: center; margin-top: 5px;">
                            <span *ngFor="let item of addedApprovers2"
                                class="badge badge-primary mr-1 mb-1">{{item.order}}. {{item.roleName}}</span>
                        </div>
                        
                    </div>
                </div>





                <!-- [disabled]="!frmGroup.valid" -->

                <button data-toggle="tooltip" title="Save" type="submit" (click)="onsubmit()" value="Submit"
                    class="btn btn-success" style="float:right; margin-top:5px;">
                    <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                </button>
                <button type="button" data-toggle="tooltip" title="Refresh" [hidden]="isEdit" (click)="reset()"
                    class="btn btn-secondary"
                    style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
            </form>
        </div>
    </div>
</div>



<!-- Modal -->
 

<ng-template #contentCalType let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">INT. Calculation Type Detail</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p *ngIf="storeIntCalType!=null">INT. Calculation Type: {{storeIntCalType.name}}</p>
        <ul *ngIf="storeIntCalTypeDetails!=null">
            <li *ngFor="let item of storeIntCalTypeDetails">Amount: {{item.amount}}; Percent: {{item.taxPercent}}%
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
    </div>
</ng-template>
 

<ng-template #contentCharge let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Charge Detail</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul>
            <li>Code: {{charge!=null?charge.code:''}}</li>
            <li>Name: {{charge!=null?charge.name:''}}</li>
            <li>{{charge!=null && charge.type==1?"Amount":"Percentage"}}: {{charge!=null?charge.amount:''}}</li>
            <li>Type: {{charge!=null && charge.type==1?"Fixed":"Percentage"}}</li>
            <li>Remarks: {{charge!=null?charge.remarks:''}}</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
    </div>
</ng-template>

<!-- Modal --> 


 