import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'app/customer/service/customer.service';
import { TransectionType } from 'app/master-data/products/model/transection-type';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { LayoutService } from 'app/shared/services/layout.service';
import swal from 'sweetalert'; 
import Swal from 'sweetalert2';


@Component({
  selector: 'app-fund-transfer-approve-list',
  templateUrl: './fund-transfer-approve-list.component.html',
  styleUrls: ['./fund-transfer-approve-list.component.scss']
})
export class FundTransferApproveListComponent implements OnInit {

  accountNo: string = '';
  accountList: any[] = [];
  startDate: Date = new Date();
  EndDate: Date = new Date();

  transactionList: any[] = [];
  SearchAccNo: boolean = false;
  pdfView: boolean = false;
  transferDetails: any;
  currentUser: string = '';
  transactionType: string='';
  noDataAvailable: boolean = false;
  FundtransactionList: any = [];
   
   

  constructor(private customerService: CustomerService,
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private changeDetect: ChangeDetectorRef,
    private modalService: NgbModal,

    private datePipe: DatePipe) {

  }

  ngOnInit(): void {
     
    this.gettransactionList();


    this.layoutService.getCurrentUserName().subscribe(res => {
      this.currentUser = res;
    }
    );

  }


  gettransactionList() {
    this.customerService.getFundTransferDataList().subscribe(res => {  
       
      this.transactionList = res;
      this.changeDetect.detectChanges();

    })

  }

   

  getProductDetails(id: any) { 
    this.customerService.getFundTransferPanddinfListById(id).subscribe(
  
      (data: any) => {
     
        this.transferDetails = data;
         
      },
      (error) => {
        console.error("Error fetching product details:", error);
      }
    );
  }

  

}
