<!-- <p>otp-verify works!</p> -->

<div class="container">
  <h3 class="card-title" style="margin-top: 3%;color: black;text-align: center;">eKyc - Customer Registration</h3>
  <div class="card">
    <div class="card-body">
      <form #customerForm="ngForm">
        <!-- ------- -->
        <div *ngIf="isPhoneNumberShow">
          <div class="row">
            <div class="col-md-4 col-sm-12"></div>
            <div class="form-row mb-2 col-md-4 col-sm-12">
              <label for="MobileNo">Mobile No</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">88</span>
                </div>
                <input type="text" class="form-control" type="text" placeholder="Type mobile no..." id="MobileNo"
                  name="MobileNo" [(ngModel)]="model.MobileNo" maxlength="11"
                  (keydown.enter)="onPhoneNumberSubmit(customerForm)">
              </div>
            </div>
            <div class="col-md-4 col-sm-12"></div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12"></div>
            <div class="form-row col-md-4 col-sm-12">
              <button class="btn btn-primary" (click)="onPhoneNumberSubmit(customerForm)" type="button">Send
                OTP</button>
            </div>
            <div class="col-md-4 col-sm-12"></div>
          </div>
        </div>
        <div *ngIf="isOtpShow">
         
          <div class="row">
            <div class="col-md-4 col-sm-12">
             </div>
            <div class="form-row mb-2 col-md-4 col-sm-12">
              <label for="otp"> OTP </label>
              <input  type="number" maxlength="4" max="9999" id="otp" name="otp" class="form-control" style="text-align: center;" [(ngModel)]="model.otpCode" readonly>

              <input type="text" class="form-control newOtp" maxlength="1" [(ngModel)]="model.otp1"  #otp1 (keyup)="move($event,'',otp1,otp2)" > 
              <input type="text" class="form-control newOtp" maxlength="1" [(ngModel)]="model.otp2"  #otp2 (keyup)="move($event,otp1,otp2,otp3)" >
              <input type="text" class="form-control newOtp" maxlength="1" [(ngModel)]="model.otp3"  #otp3 (keyup)="move($event,otp2,otp3,otp4)" >
              <input type="text" class="form-control newOtp" maxlength="1" [(ngModel)]="model.otp4"  #otp4 (keyup)="move($event,otp3,otp4,'')" >
               
              <input hidden type="number" maxlength="4" max="9999" id="otp" name="otp" class="form-control otp" [(ngModel)]="model.otp" #otp="ngModel">
            </div>
            <div class="col-md-4 col-sm-12"></div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12"></div>
            <div class="col-md-4 col-sm-12">
              <button class="btn btn-primary pull-left" [disabled]="countdown> 0"
                (click)="startCountdown(customerForm,2)">Resend ({{countdown}})</button>
              <button class="btn btn-primary pull-right" (click)="onOTPSubmit(customerForm)"
                type="button">Submit</button>
            </div>
            <div class="col-md-4 col-sm-12"></div>
          </div>
        </div>
        <!-- -------------- -->
      </form>
    </div>
  </div>
</div>