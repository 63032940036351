import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

//const apiUrl=environment.baseUrl + '/api/Accured/'

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class AccuredService {

  constructor(private http:HttpClient) { }

  getAccuredList():Observable<any>{
    return this.http.get(dcbsUrl + '/api/Accured/GetAllAccuredList')
  }

  getAccuredById(id:number){
    return this.http.get(dcbsUrl + '/api/Accured/GetAccuredById/' + id)
  }

  saveAccured(model:any){
    return this.http.post(dcbsUrl + '/api/Accured/SaveAccured',model)
  }

  deleteAccured(id:any){
    return this.http.delete(dcbsUrl + '/api/Accured/DeleteAccured/' + id)
  }

}
