import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

// const apiUrl=environment.baseUrl + '/api/ScoreQuestion/'

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;


@Injectable({
  providedIn: 'root'
})
export class ScoreQueAnsService {

  constructor(private http:HttpClient) { }

  getAllQuestionList():Observable<any>{
    return this.http.get(dcbsUrl +'/api/ScoreQuestion/GetAllQuestion');
  }

  saveQuestion(model:any){
    return this.http.post(dcbsUrl  + '/api/ScoreQuestion/SaveQuestion', model);
  }



  //#region Answer

  saveAnswer(model:any){
    return this.http.post(dcbsUrl  + '/api/ScoreQuestion/SaveAnswer', model);
  }

  //#endregion


}
