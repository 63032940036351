import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

//const apiUrl=environment.baseUrl + '/api/DaySize/'

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;


@Injectable({
  providedIn: 'root'
})
export class DaySizeService {

  constructor(private http:HttpClient) { }

  getDaySizeList():Observable<any>{
    return this.http.get(dcbsUrl + '/api/DaySize/GetAllDaySizeList')
  }
  getDaySizeListByProductId(id:number):Observable<any>{
    return this.http.get(dcbsUrl + '/api/DaySize/GetAllDaySizeListByProductType?id='+id)
  }
  getDaySizeById(id:number){
    return this.http.get(dcbsUrl + '/api/DaySize/GetDaySizeById')
  }

  saveDaySize(model:any){
    return this.http.post(dcbsUrl + '/api/DaySize/SaveDaySize', model)
  }

  deleteDaySize(id:any){
    return this.http.delete(dcbsUrl + '/api/DaySize/DeleteDaySize/' + id)
  }
  getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
    return this.http.get(dcbsUrl + '/api/DaySize/GetDaysizeWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
  }
}
