import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CashApprovalService } from 'app/accounting/service/cash-approval.service';
import { GroupService } from 'app/accounting/service/group.service';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { CountryInfo } from 'app/master-data/basic/basic-model/country-info';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { Excise_duty } from 'app/master-data/products/model/Excise_Duty';
import { Accured } from 'app/master-data/products/model/accured';
import { Balancetype } from 'app/master-data/products/model/balancetype';
import { CapitalizationRule } from 'app/master-data/products/model/capitalizationRule';
import { CapitalizeFrequency } from 'app/master-data/products/model/capitalize-frequency';
import { Charge } from 'app/master-data/products/model/charge';
import { Commission } from 'app/master-data/products/model/commission';
import { DaySize } from 'app/master-data/products/model/day-size';
import { Durations } from 'app/master-data/products/model/durations';
import { InterestCalculationFrequency } from 'app/master-data/products/model/interest-calculation-frequency';
import { AccuredService } from 'app/master-data/products/service/accured.service';
import { BalancetypeService } from 'app/master-data/products/service/balancetype.service';
import { CapitalizationRuleService } from 'app/master-data/products/service/capitalization-rule.service';
import { CapitalizeFrequencyService } from 'app/master-data/products/service/capitalize-frequency.service';
import { ChargeService } from 'app/master-data/products/service/charge.service';
import { CommissionService } from 'app/master-data/products/service/commission.service';
import { DaySizeService } from 'app/master-data/products/service/day-size.service';
import { ExciseDutyService } from 'app/master-data/products/service/excise-duty.service';
import { InterestCalculationService } from 'app/master-data/products/service/interest-calculation.service';
import { LoanTypeService } from 'app/master-data/products/service/loan-type.service';
import { PaymentTypeService } from 'app/master-data/products/service/payment-type.service';
import { SlabService } from 'app/master-data/products/service/slab.service';
import { TaxKeyService } from 'app/master-data/products/service/tax-key.service';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { CustomValidatorsService } from 'app/shared/directives/custom-validators.service';
import { Location } from "@angular/common";
import { catchError, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { UserRoleAssignService } from 'app/user/user-management/user-service/user-role-assign.service';
import { ProductEventService } from 'app/master-data/products/service/product-event.service';
import { ProductGLList } from '../models/product-gl-list';
import { ProductMappingGL } from '../models/product-mapping-gl';
import { InterestCalculationType } from 'app/master-data/products/model/interest-calculation-type';
interface Approver {
  order: number;
  userId: string;
  fullName: string;
}
@Component({
  selector: 'app-deposit-product-builder',
  templateUrl: './deposit-product-builder.component.html',
  styleUrls: ['./deposit-product-builder.component.scss']
})
export class DepositProductBuilderComponent implements OnInit {
  ledgerList: any;
  incomeExpenseGroupList: any;
  ledgerListAcc: any = [];
  ledgerIntProList: any = [];
  categoryList: any = [];
  accGroupList: any[] = [];
  productDetails: any;
  balanceTypeList: Balancetype[] = [];
  exciseDutyList: Excise_duty[] = [];
  exciseDutyDetails: any[] = [];
  sortExcDutyDetails: any[] = [];
  exciseDuty: any;
  capRuleList: CapitalizationRule[] = [];
  capRuleSelectList: CapitalizationRule[] = [];
  capFrequencyList: CapitalizeFrequency[] = [];
  intCalculationTypeList: any[] = [];
  intCalTypeDetails: any[] = [];
  storeIntCalTypeDetails: any[] = [];
  storeIntCalType: any;
  intCalculationFreqList: [InterestCalculationFrequency][] = [];
  intCalculationList: [InterestCalculationType][] = [];
  accuredList: Accured[] = [];
  daySizeList: DaySize[] = [];
  currencyList: CountryInfo[] = [];
  taxKeyList: any[] = [];
  taxKey: any;
  taxKeyDetails: any[] = [];
  allSlab: any[] = [];
  allLoanType: any[] = [];
  allPaymentType: any[] = [];
  instCalTypeSlab: any[] = [];
  isEdit: boolean = false;
  intCalTypeDiv: boolean = true;
  intCalTypeMultipleDiv: boolean = false;
  chargeList: Charge[] = [];
  defaultCategoryId: 0;
  selectedChargeList: Charge[] = [];
  charge: any;
  capRule: any;
  selectedDurationList: Durations[] = [];
  commissionList: Commission[] = [];
  selectedCommissionList: Commission[] = [];
  commission: any;
  ReducingMethodList: any[] = [];
  loandurationList: any[] = [];
  glList:any[]=[];
  showEntry = false;
  frmGroup: FormGroup;
  submitted = false;
  model: any = {};
  closeResult: string;
  productCode: string;
  allbankusers: [];
  allbankusers2: [];
  isReadOnly: boolean;
  editId: number;
  ledgerId:number;
  interestProvisionLedgerId:number;
  plInterestLedgerId:number;

  selectedUserId: string;
  selectedUsername: string;

  selectedUserId2: string;
  selectedUsername2: string;

  addedApprovers: Approver[] = [];
  addedApprovers2: any[] = [];
  productEventList: any[] = [];
  mappedGLList: ProductGLList[] = [];
  editmappedGLList: any = [];
  showGlTbl: boolean = false;
  searchText: string;
  selectedProductGL: ProductMappingGL[] = [];
  addMaapedGL: boolean = true;
  editMaapedGL: boolean = false;
  constructor(
    private _loanProductService: LoanProductService,
    private _CommissionService: CommissionService,
    private _blanaceTypeService: BalancetypeService,
    private _exciseDutyService: ExciseDutyService,
    private _capitaliseRuleService: CapitalizationRuleService,
    private _intCalculationService: InterestCalculationService,
    private _accuredService: AccuredService,
    private _capitaliseFrequencyService: CapitalizeFrequencyService,
    private _daySizeService: DaySizeService,
    private _taxKeyService: TaxKeyService,
    private _countryService: CountryInfoService,
    private _slabService: SlabService,
    private _chargeService: ChargeService,
    private _loanTypeService: LoanTypeService,
    private _paymentTypeService: PaymentTypeService,
    private _groupService: GroupService,
    private customValidatorsService: CustomValidatorsService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private _ledgerService: LedgerService,
    private _cashApprovalService: CashApprovalService,
    private changeref: ChangeDetectorRef,
    private location: Location,
    private _service:UserRoleAssignService,
    private _productEventService: ProductEventService,
    private router: Router
  ) {}

   
  ngOnInit() {
    this.activeRoute.paramMap.subscribe((params) => {
      const ProductBuilderId = +params.get("id");
      // if (ProductBuilderId > 0) {
      //  // this.getProductBuilderById(ProductBuilderId);
      // }
      this.getProductEvents(3);
    });
    this.populateForm();
    this.getExciseDuty(2);
    this.getDaySize(2);
     //this.getInterestCalculationType(2);
    this.getInterestCalculationFrequency(2);
    //this.getCapitalizationRule(2);
    this.getCapitalizationFrequency(2); 
   // this.GetAllCommissionList(2);
    //this.GetAllChargeList();
    this.getCountryList();
    this.getSlabList(2); 
    this.getAllLoanType();
    this.getAllPaymentType();
    //this.getAllReducingMethodList();
    //this.getAllLoandurationList();
    
    this.getAllGLList();
      this.getAllProductCategory();
    this.getAllBankUsers();
    this.getAllUserRole();
    this.getInterestCalculation();
    this.getBalanceType();
  }

  onGroupChange(gId: number, typeId: number) {
    if (typeId == 3) {
      if (gId != 0 || gId != null) {
        this.getLedgerListByGroupIdForProfit(gId);
      }
    } else if (typeId == 2) {
      if (gId != 0 || gId != null) {
        this.loadIntProLedgerListByGroupId(gId);
      }
    } else if (typeId == 1) {
      if (gId != 0 || gId != null) {
        this.loadLedgerListByGroupId(gId);
      }
    }
  }
  getAllUserRole(){
    this._service.GetAllRole().subscribe((res) => {
      this.allbankusers2 = res;
    });
  }
  getAllBankUsers() {
    this._cashApprovalService.getUserlist().subscribe((res) => { 
      this.allbankusers = res;
    });
  }

  ChangeApprover(target) {
   
    this.selectedUserId = target.value;
    this.selectedUsername = target.selectedOptions[0].innerText;
  }

  ChangeApprover2(target) {
    this.selectedUserId2 = target.value;
    this.selectedUsername2 = target.selectedOptions[0].innerText;
  }
  AddApprover() {
    const newObject: Approver = {
      order: this.addedApprovers.length + 1,
      userId: this.selectedUserId,
      fullName: this.selectedUsername,
    };
    this.addedApprovers.push(newObject);
  }
  AddApprover2() {
    const newObject: any = {
      order: this.addedApprovers2.length + 1,
      roleId: this.selectedUserId2,
      roleName: this.selectedUsername2,
    };
    this.addedApprovers2.push(newObject);
  }
  getProductCode(typeId: number) {
    if (typeId > 0 && !this.isEdit) {
      this._loanProductService
        .getProductCodeByType(typeId)
        .subscribe((res) => {
          this.productCode = res;
          this.frmGroup.get('Code').setValue(res);
        });
    }
  }

  loadLedgerListByGroupId(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerListAcc = res;
    });
  }
  getLedgerListByGroupIdForProfit(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerList = res;
    });
  }
  loadIntProLedgerListByGroupId(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerIntProList = res;
    });
  }

   
  
  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      Code: [
        null,
        [Validators.required, Validators.minLength(2)],
        [this.customValidatorsService.DuplicateProductBuilderCode()],
        { updateOn: "blur" },
      ],
      Name: [null, [Validators.required, Validators.minLength(2)]],
      Version: [null,
        [Validators.required],
         
      ],
      Description: [""],
      interestRate: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]+$/)],
      ],
      Currency: [""],
      DaySizeId: [""],
      interestCalculationTypeSelect: [0],
      InterestCalculationFrequencyId: [""],
      BalanceTypeId: [0],
      loanTypeId: [0, [Validators.required]],
      paymentTypeId: ["", [Validators.required]],
       
       
      inputorId: [""],
      authorizerId: [""],
       status: ["1"],
      chargeId: [""],
      commissionId: [0],
       
      loanDurationId: [""],
      durationId: [""],
      plInterestGroupId: [""],
      interestProvisionGroupId: [""],
      ledgerId: [""],
      plInterestLedgerId: [""],
      ipLedgerId: [""],
      interestProvisionLedgerId: [0],
      isVatInclude: [0],
      productEventName: [0],
      glType: [""],
      eventGLId: [0],
      effectiveDate: [""],
      //maxwithdrawalLimit: [null, [Validators.required, Validators.pattern(/^[0-9]+$/)], ],
      //overdraftLimit: [null, [Validators.required, Validators.pattern(/^[0-9]+$/)], ],
      //transactionLimit: [null, [Validators.required, Validators.pattern(/^[0-9]+$/)], ],
      //transactionfrequencies: [ null, [Validators.required, Validators.pattern(/^[0-9]+$/)], ],
    });
  }

  onsubmit() {  
    this.submitted = true;  
     if (this.frmGroup.valid)
    {
        if (this.isEdit) 
         {
         this.model.id = this.frmGroup.value.id;
         }
         else
         {
            this.model.id=0;
         }
      //this.model.id=0;
      this.model.Code = this.frmGroup.value.Code;
      this.model.Name = this.frmGroup.value.Name;
      this.model.Version = this.frmGroup.value.Version;
      this.model.Description = this.frmGroup.value.Description;
      this.model.Currency = this.frmGroup.value.Currency;
      this.model.DaySizeId = this.frmGroup.value.DaySizeId;
      this.model.InterestCalculationFrequencyId = this.frmGroup.value.InterestCalculationFrequencyId;
      this.model.BalanceTypeId = this.frmGroup.value.BalanceTypeId;
      this.model.InterestCalculationTypeId = this.frmGroup.value.interestCalculationTypeSelect;
      this.model.selectedChargeId = this.selectedChargeList;      
      this.model.effectiveDate = this.frmGroup.value.effectiveDate;
      this.model.productGLMappings = this.mappedGLList;
      this.model.loanTypeId = this.frmGroup.value.loanTypeId;
      this.model.paymentTypeId = this.frmGroup.value.paymentTypeId;
      this.model.interestRate = this.frmGroup.value.interestRate;
      //this.model.maxwithdrawalLimit = this.frmGroup.value.maxwithdrawalLimit;
      //this.model.overdraftLimit = this.frmGroup.value.overdraftLimit;
      //this.model.transactionLimit = this.frmGroup.value.transactionLimit;
      //this.model.transactionfrequencies = this.frmGroup.value.transactionfrequencies;
      this.model.approvers = this.addedApprovers2;
      
       
      Swal.fire({
        title: 'Are you sure?',
        text: this.isEdit?'You want to update!':'You want to save!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.isEdit?'Update!':'Save!',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this._loanProductService
          .save(this.model)
          .pipe(
            catchError((error) => {  
              if(error.error.message.indexOf("UNIQUE KEY") !== -1){
                Swal.fire({
                  title: 'Failed?',
                  text: 'Version are already exist.',
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonText: 'Ok',
                  //cancelButtonText: 'No'
                })
              }            
              return throwError(error);
            })
          )
          .subscribe((res) => {
            Swal.fire({
              title: 'Success?',
              text: this.isEdit?'Update success!':'Save success!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((e) =>{
              this.router.navigate(["/product/deposit-product-builder-list"]);
            })
          })
        }
      });
       
    }
  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

  edit(item: any) {
    this.isEdit = true;
     this.frmGroup.patchValue({
      id: item.id,
      Code: item.code,
      Name: item.name,
      Version: item.version,
      Description: item.description,
      InterestRateMin: item.interestRateMin,
      InterestRateMax: item.interestRateMax,
      Currency: item.currency,
      DaySizeId: item.daySizeId,
       //exciseDutyId: item.exciseDutyId,
      InterestCalculationTypeId: item.interestCalculationTypeId,
      InterestCalculationFrequencyId: item.interestCalculationFrequencyId,
      CapitalizationRuleId: item.iapitalizationRuleId,
      BalanceTypeId: item.balanceTypeId,
      inputorId: item.inputorId,
      authorizerId: item.authorizerId,
       status: item.status,
      reducingMethodId: item.reducingMethodId,
      loanDurationId: item.loanDurationId,
     });
  }

   
  getSlabList(id) {
    //this._slabService.getlist().subscribe((res) => {
    this._slabService.getlistByProBuilderType(id).subscribe((res) => {
      this.allSlab = res;
    });
  }
  getAllLoanType() {
    this._loanTypeService.getLoanTypeList().subscribe((res) => {
      this.allLoanType = res;
    });
  }
  getAllPaymentType() {
    this._paymentTypeService.getPaymentTypeList().subscribe((res) => {
      this.allPaymentType = res;
    });
  }
  getBalanceType() {
    this._blanaceTypeService.getBalanceTypeListDataType().subscribe((res) => {       
      this.balanceTypeList = res;
    });
  }
  getExciseDuty(id:number) {
    this._exciseDutyService.getExciseDutyListByType(id).subscribe((res) => {
      for (const exDuty of res) {
        this.exciseDutyList.push(exDuty["exciseDuty"]);
        if (exDuty["exciseDutyDetails"].length > 0) {
          for (const dtt of exDuty["exciseDutyDetails"]) {
            this.exciseDutyDetails.push(dtt);
          }
        }
      }
    });
  }
   
  
  getCapitalizationFrequency(id:number) {
    this._capitaliseFrequencyService
      .getCapFreqListByProBuildeType(id)
      .subscribe((res) => {
        this.capFrequencyList = res;
      });
  }

   
  getAccured() {
    this._accuredService.getAccuredList().subscribe((res) => {
      this.accuredList = res;
    });
  }
   
  addMultipleCharge() {
  
    const check = this.selectedChargeList.find(
      (obj) => obj.id == this.frmGroup.value.chargeId
    );
 
    if (this.frmGroup.value.chargeId > 0 && check == undefined) {
      this.selectedChargeList.push(
        this.chargeList.find((obj) => obj.id == this.frmGroup.value.chargeId)
      );
    }
  }
  getInterestCalculationFrequency(id:number) {
    this._intCalculationService.getFrequencyListByProductType(id).subscribe((res) => {
      this.intCalculationFreqList = res;
    });
  }
  getInterestCalculation() {
    this._intCalculationService.getInterestCalculationType().subscribe((res) => {
      
      this.intCalculationList = res;
    });
  }
  getDaySize(id:number) {
    this._daySizeService.getDaySizeListByProductId(id).subscribe((res) => {
      this.daySizeList = res;
    });
  }
  getCountryList() {
    this._countryService.getAllGetCurrencyInfo().subscribe((res) => {
      this.currencyList = res;
      console.log("sadhs",this.currencyList);
    });
  }

  
  getAllGLList() {
    this._ledgerService
      .GetParticularLedgerForPaymentVoucher()
      .subscribe((res) => {
        this.glList = res;
        //this.vatGlList = res;
      });
  }
   

   

  openModal(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  openBuilderDetailsModal(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }
  
  onChargeChange() {
    this.charge = null;
    this.charge= this.chargeList.find(
        (obj) => obj.id == this.frmGroup.value.chargeId
      )
  }
  goBack() {
    this.location.back();
  }

  getAllProductCategory() {
    this._blanaceTypeService.getDepistCategorylist().subscribe((res) => {
      this.categoryList = res; 
      
      if (res && res.length > 0) {
        this.defaultCategoryId = res[0].id; 
        this.GetAllChargeList(this.defaultCategoryId);
        
      } else {
        console.warn("No categories found in the response.");
      }
    });
  }
  
  GetAllChargeList(id: number) {
    if (id) {
       this._chargeService.GetAllChargeListByProBuilderType(id).subscribe((res) => {
        this.chargeList = res;
      });
    } else {
      console.warn("Invalid ID passed to GetAllChargeList.");
    }
  }
  
  
 


  getProductEvents(categoryId: number) {
    this._productEventService.getProductEvent(categoryId).subscribe((res) => {
      this.productEventList = res;
     });
  }


  fnAddMappedGL() {
    debugger
     if (
      this.frmGroup.value.productEventName == 0 ||
      this.frmGroup.value.glType == 0 ||
      this.frmGroup.value.eventGLId == ""
    ) {
      return false;
    }

    const id = this.frmGroup.value.productEventName;
    const exists = this.mappedGLList.some((item) => item.eventId === id);
    if (exists) {
      alert("This item is already Added.");
      return false;
    }

    this.showGlTbl = true;

    var eventss = this.productEventList.find(
      (event) => String(event.id) === String(id)
    );

    const eventName = eventss ? eventss.eventName : null;
    const eventGLId = this.frmGroup.value.eventGLId;
    const selectedGL = this.glList.find((x) => x.id === eventGLId);
    const eventGLCode = selectedGL ? selectedGL.ledger?.accountCode : null;
    const eventGLName = selectedGL ? selectedGL.ledger?.accountName : null;
    const eventCode = eventss ? eventss.eventCode : null;
    const chargeTypeId = this.frmGroup.value.chargeTypeName;
    let chargeTypeName = "";
    switch (this.frmGroup.value.chargeTypeName) {
      case "1":
        chargeTypeName = "Fixed";
        break;
      case "2":
        chargeTypeName = "Percentage";
        break;
      default:
        chargeTypeName = "";
        break;
    }
    const chargeAmount = this.frmGroup.value.chargeAmount;
    const isVatInclude = this.frmGroup.value.isVatInclude;

    const vatAmount = this.frmGroup.value.vatAmount;
    const vatGLId = this.frmGroup.value.vatGLId;
    const glType = this.frmGroup.value.glType;

    this.mappedGLList.push(
      new ProductGLList(
        id,
        eventGLCode,
        eventGLName,
        eventCode,
        eventName,
        eventGLId,
        chargeAmount,
        chargeTypeName,
        chargeTypeId,
        isVatInclude,
        vatAmount,
        vatGLId,
        glType
      )
    );
    
  }

  fnRemoveGL(id: number): void {
    this.selectedProductGL = this.selectedProductGL.filter(
      (item) => item.chargeId !== id
    );
  }

  
  removeMappedGL(id: number) {
    this.mappedGLList = this.mappedGLList.filter((item) => item.eventId !== id);
    this.changeref.detectChanges();
  }
  fnAddSelectedGL(
    id: number,
    glcode: string,
    glname: string,
    eventName: string,
    eventCode: string,
    ledgerRelId: number
  ): void {
    const exists = this.selectedProductGL.some((item) => item.chargeId === id);

    if (exists) {
      alert("This item is already selected.");
      return;
    }

    this.selectedProductGL.push(
      new ProductMappingGL(
        id,
        glcode,
        glname,
        eventCode,
        eventName,
        ledgerRelId
      )
    );
  }

}
