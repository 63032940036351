<form [formGroup]="frmGroup">
    <!-- ... -->
    <div class="col-md-12">
      <div class="card" style="width: 100%;">
        <div class="row card-header  d-flex flex-row align-items-center">
          <div class="col-6">
            <h5 class="card-title" style="color: black">Account Closure</h5>
          </div>
          <div class="col-6">
            <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
              <!-- <i class="ft-chevrons-left"> Back</i> -->
              <i class="icon-arrow-left" style="font-size: 17px;"> </i>
            </button>
          </div>
        </div>
        
        <hr style="height: 10%;">
        <div class="card-body">
          <div>
            
            <div class="row">
  
              <div class="col-6">
  
                <div class="form-group row">
                  <label for="" class="col-sm-4 ">A/C No</label>
                  <!-- <label for="" class="col-sm-4 ">Received Account/BD GENERATE CODE</label> -->
                  <div class="col-sm-8">
                    <select class="form-control" formControlName="customerCode" [(ngModel)]="accountNo"
                      (change)="loadUserDataByAccountNoCashPayment($event.target.value)">
                      <option value="">Select</option>
                      <ng-container *ngFor="let item of AccountNoList | async">
                        <option [value]="item.id">{{item.customerAccountNo}} - {{item.accountType}}</option>
                      </ng-container>
                    </select> 
  
                    <input type="hidden" class="form-control" name="customerId" formControlName="customerId">
                    <input type="hidden" class="form-control" formControlName="gLGenerateId">
                  </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label"> Opening Date</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" formControlName="applyDate" value="{{applyDate}}"
                        style="text-align: right;" readonly>
                    </div>
                  </div>
              
                <div class="form-group row">
                  <label for="" class="col-sm-4 ">Account Name</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" id="AccountName" formControlName="AccountName"
                      value="{{glAccountName}} {{customerInfo_cashPayment}}" readonly>
                  </div>
                </div>
  
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label"> Lien Amount</label>
                  <div class="col-sm-8">
                    <input type="number" class="form-control" formControlName="lienAmount"
                    placeholder="0"
                      style="text-align: right;">
                  </div>
                </div>

<hr>

                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">Provisioned Profit</label>
                  <div class="col-sm-8">
                    <input type="number" class="form-control" formControlName="provisionedProfit"
                    placeholder="0"
                      style="text-align: right;">
                  </div>
                </div>
                
  
              </div>
              <div class="col-6">
                 
               

                <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label"> Balance</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" [value]="currentBalance | number:'1.0-2'"
                        style="text-align: right;" readonly>
                    </div>
                  </div>
  
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label"> Profit</label>
                    <div class="col-sm-8">
                      <input type="number" class="form-control"formControlName="profit"
                        style="text-align: right;" (input)="calculateNetPayable()">
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Tax on Profit</label>
                    <div class="col-sm-8">
                      <input type="number" class="form-control"formControlName="taxonprofit"
                        style="text-align: right;" (input)="calculateNetPayable()">
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Closing Charge</label>
                    <div class="col-sm-8">
                      <input type="number" class="form-control"formControlName="closingCharge"
                        style="text-align: right;" (input)="calculateNetPayable()">
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">VAT</label>
                    <div class="col-sm-8">
                      <input type="number" class="form-control"formControlName="VAT"
                        style="text-align: right;" (input)="calculateNetPayable()">
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Excise Duty</label>
                    <div class="col-sm-8">
                      <input type="number" class="form-control"formControlName="exciseDuty"
                        style="text-align: right;" (input)="calculateNetPayable()">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Net Payable</label>
                    <div class="col-sm-8">
                      <input type="number" class="form-control" formControlName="netPayable" [value]="netPayable | number:'1.0-2'"
                        style="text-align: right;" readonly>
                    </div>
                  </div>


              
                
  
              </div>
<hr>
              <div class="col-6">
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">Transaction Type</label>
                  <div class="col-sm-8">
                    <div class="d-flex">
                      <div class="form-check me-3">
                        <input class="form-check-input large-radio checked" type="radio" name="transactionType" formControlName="transactionType" id="cash" value="cash">
                        <label class="form-check-label" for="cash">
                          Cash
                        </label>
                      </div>
                      <div class="form-check me-3">
                        <input class="form-check-input large-radio" type="radio" name="transactionType" formControlName="transactionType" id="account" value="account">
                        <label class="form-check-label" for="account">
                          Account
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input large-radio" type="radio" name="transactionType" formControlName="transactionType" id="transfer" value="transfer">
                        <label class="form-check-label" for="transfer">
                          Transfer
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="form-group row">
                  <label for="" class="col-sm-4 col-form-label">Amount</label>
                  <div class="col-sm-8">
                    <input type="number" class="form-control"formControlName="amount"
                      style="text-align: right;" >
                  </div>
                </div>
                

              </div>





            </div>
          </div>
  
          <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button"
            class="btn btn-success" style="float:right; margin-top:5px;">Save </button>
  
          <button type="button" title="Refresh" class="btn btn-secondary"
            style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
        </div>
      </div>
    </div>
  
  </form>