import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';
import { th } from 'date-fns/locale';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  getCustomerByAccountNo(accountNo: string): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/get-customer-by-accountNo/" + accountNo
    );
  }
  GetTempUDFInfoById(id:number) {
    return this.http.get(dcbsUrl + "/api/LoanApplication/GetTempUDFInfoById?id=" + id);
  }
  GetCustomerGenerateCode(): Observable<any> {
    //alert(0)
    return this.http.get(dcbsUrl + "GetCustomerGenerateCode");
  }
  GetCustomerById(id: any): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Customers/GetCustomerById?id=" + id);
  }
  getlist(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Customers/GetAllCustomer");
  }
  getNomineeList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CustomerAccount/GetNomineeInfo");
  }
  GetNomineeById(Id: any): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/CustomerAccount/GetNomineeInfoById" + Id
    );
  }

  getCustomerListBySearchAndPagination(
    page: number,
    pageSize: number,
    search: any
  ) {
    return this.http.get(
      dcbsUrl +
        "/api/Ledger/GetCustomerListWithSearchAndPagination?page=" +
        page +
        "&pageSize=" +
        pageSize +
        "&search=" +
        search
    );
  }

  getTempCustomerListBySearchAndPagination(
    page: number,
    pageSize: number,
    search: any
  ) {
    return this.http.get(
      dcbsUrl +
        "/api/LoanApplication/GetTempCustomerSearchAndPagination?page=" +
        page +
        "&pageSize=" +
        pageSize +
        "&search=" +
        search
    );
  }

  // getCustomerListBySearchAndPagination(page: number, pageSize: number, search: any) {
  //   return this.http.get(dcbsUrl + '/api/Customers/GetCustomerListBySearchAndPagination?page=' + page + '&pageSize=' + pageSize + '&search=' + search);
  // }

  gettransactionList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Customers/GetAllCashTransaction");
  }

  getFundTransferList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Customers/GetFundTransferTransaction");
  }

  getCustomerByMobile(nid: string, mobile: string): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/api/Customers/GetCustomerByMobile?nid=" +
        nid +
        "&mobile=" +
        mobile
    );
  }

  allCashTransactionForAdminByAccNo(accountNo: string): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/GetAllCashTransactionForAdminByAccNo?accountNo=" + accountNo
    );
  }

  ///
  getAllCashTransactionForAdminByAccNoAndDate(
    accountNo: string,
    startDate: Date,
    EndDate: Date
  ): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/GetCashTransactionForAdminByAccNoDate?accountNo=" +
        accountNo +
        "&startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  getAllStatementViewByAccNoAndDate(
    accountNo: string,
    startDate: Date,
    EndDate: Date
  ): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/AccountStatementViewByAccNoDate?accountNo=" +
        accountNo +
        "&startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  GetGenderList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Gender/GetAllGenders");
  }
  GetDistrtList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/District/GetAllDistrict");
  }

  GetUpazilaList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Upazila/GetAllUpazila");
  }

  GetUpazilaListByDistrictId(dId: number) {
    return this.http.get(
      dcbsUrl + "/api/Upazila/GetUpazilaByDistrictId?dId=" + dId
    );
  }

  save(model: any) {
    // console.log('url: ' + dcbsUrl + '/api/Customer/ekyc/insertcustomer');

    return this.http.post(
      dcbsUrl + "/api/Customers/ekyc/insertcustomer",
      model
    );
  }

  saveCustomerInfo(model: any) {
    sessionStorage.removeItem("token");

    return this.http.post<any>(
      dcbsUrl + "/api/Customers/ekyc/insertcustomer",
      model
    );
  }

  update(model: any) {
    return this.http.post(
      dcbsUrl + "/api/Customers/ekyc/insertcustomer",
      model
    );
  }

  delete(model: any) {
    return this.http.delete(dcbsUrl + "DeleteCustomer" + id);
  }

  DeleteCustomerById(Id: any) {
    return this.http.delete(
      dcbsUrl + "/api/Customers/DeleteCustomerById?Id=" + Id
    );
  }

  GetCustomerAccountList(userName: string): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/GetCustermAccountListForCustomer/" + userName
    );
  }

  CustomerAccountListInfo(): Observable<any> {
    return this.http.get<any>(dcbsUrl + "/GetCustermAccountListForCustomer/");
  }

  GetContactByCustomerId(id: any): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/LoanApplication/GetContactByCustomerId?id=" + id
    );
  }
  GetCustomerDetails(id: any) {
    return this.http.get(
      dcbsUrl + "/api/Customers/GetCustomerDetails?id=" + id
    );
  }

  GetCIFDetails(id: any) {
    return this.http.get(
      dcbsUrl + "/api/LoanApplication/GetCIFDetails?id=" + id
    );
  }

  GetTempCustomerDetails(id: any) {
    return this.http.get(
      dcbsUrl + "/api/LoanApplication/GetTempCustomerDetails?id=" + id
    );
  }

  GetLoanDetails(id: any): Observable<any> {
    //alert(id);
    return this.http.get(
      dcbsUrl +
        "/api/CustomerAccount/GetCustomerAccountLoanByCustomerId?customerId=" +
        id
    );
  }

  GetDPSDetail(id: any): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/CustomerAccount/GetDPSDetailByCustomerId?customerId=" + id
    );
  }

  // GetCustomerById(id: any):Observable<any>{
  //   return this.http.get(dcbsUrl + '/api/Customers/GetCustomerById?id='+ id );
  // }

  GetCustomerLoanAccountList(Id: number): Observable<any> {
    return this.http.get<any>(
      dcbsUrl + "/GetCustomerLoanAccountListById?Id=" + Id
    );
  }
  //#region
  GetAllCustomerAccount(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Customers/GetAllCustomerAccountList");
  }
  //#endregion

  //region
  getCustomerInfoBySearch(
    customername: string,
    genderId: number,
    typeId: number,
    mobileNo: string,
    nidNo: string,
    sBirthDate: any,
    eBirthDate: any,
    sCreateDate: any,
    eCreateDate: any,
    sModifiedDate: any,
    eModifiedDate: any
  ): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/api/Customers/GetCustomerInformationBySearch?customerName=" +
        customername +
        "&genderId=" +
        genderId +
        "&typeId=" +
        typeId +
        "&mobileNo=" +
        mobileNo +
        "&nidNo=" +
        nidNo +
        "&sBirthDate=" +
        sBirthDate +
        "&eBirthDate=" +
        eBirthDate +
        "&sCreateDate=" +
        sCreateDate +
        "&eCreateDate=" +
        eCreateDate +
        "&sModifiedDate=" +
        sModifiedDate +
        "&eModifiedDate=" +
        eModifiedDate
    );
  }

  getAllLoanRelatedInfoBySearch(
    customername: string,
    accountNo: string,
    ProductId: number,
    typeId: number,
    sCreateDate: any,
    eCreateDate: any,
    sDisburseDate: any,
    eDisburseDate: any,
    sApplyDate: any,
    eApplyDate: any
  ): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/api/Customers/GetAllLoanRelatedInfoBySearch?customerName=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&ProductId=" +
        ProductId +
        "&typeId=" +
        typeId +
        "&sCreateDate=" +
        sCreateDate +
        "&eCreateDate=" +
        eCreateDate +
        "&sDisburseDate=" +
        sDisburseDate +
        "&eDisburseDate=" +
        eDisburseDate +
        "&sApplyDate=" +
        sApplyDate +
        "&eApplyDate=" +
        eApplyDate
    );
  }

  getLoanRelatedAllInfoBySearch(
    customername: string,
    customercode: string,
    accountNo: string,
    ProductId: number,
    typeId: number,
    mobileNo: any,
    nidNo: any,
    sDisburseDate: any,
    eDisburseDate: any,
    sApplyDate: any,
    eApplyDate: any
  ): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/api/Customers/GetLoanInfoBySearch?customerName=" +
        customername +
        "&customercode=" +
        customercode +
        "&accountNo=" +
        accountNo +
        "&ProductId=" +
        ProductId +
        "&typeId=" +
        typeId +
        "&mobileNo=" +
        mobileNo +
        "&nidNo=" +
        nidNo +
        "&sDisburseDate=" +
        sDisburseDate +
        "&eDisburseDate=" +
        eDisburseDate +
        "&sApplyDate=" +
        sApplyDate +
        "&eApplyDate=" +
        eApplyDate
    );
  }

  // getAllGuarantorBySearch(guarantorId: number, sCreateDate:any,eCreateDate:any, sModifiedDate:any,eModifiedDate:any): Observable<any> {
  //   return this.http.get<any>(dcbsUrl + '/api/Customers/GetAllGuarantorById?Id=' + guarantorId  + '&sCreateDate=' + sCreateDate + '&eCreateDate=' + eCreateDate + '&sModifiedDate=' + sModifiedDate + '&eModifiedDate=' + eModifiedDate );
  // }

  getAllGuarantorBySearch(nidNo: string, mobile: string): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/api/Customers/GetGuarantorListByMobileAndNid/" +
        nidNo +
        "/" +
        mobile
    );
  }

  getAllNomineeBySearch(nidNo: string, birthNo: string): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/api/Customers/GetNomineeListBybirthNoAndNid/" +
        nidNo +
        "/" +
        birthNo
    );
  }

  getTransactionInfoByDateSearch(
    accountNo: any,
    fromDate: any,
    toDate: any
  ): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/GetAllTransactionForAdminSearchByAccNoDate?accountNo=" +
        accountNo +
        "&startDate=" +
        fromDate +
        "&EndDate=" +
        toDate
    );
  }

  GetfilterList(): Observable<any> {
    return this.http.get(dcbsUrl + "/GetAllFilterType");
  }

  getBNPLStatementAllInfoBySearch(
    customername: string,
    customercode: string,
    accountNo: string,
    ProductId: number,
    typeId: number,
    mobileNo: any,
    nidNo: any,
    sDisburseDate: any,
    eDisburseDate: any,
    sApplyDate: any,
    eApplyDate: any
  ): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/api/Customers/GetBNPLStatementBySearch?customerName=" +
        customername +
        "&customercode=" +
        customercode +
        "&accountNo=" +
        accountNo +
        "&ProductId=" +
        ProductId +
        "&typeId=" +
        typeId +
        "&mobileNo=" +
        mobileNo +
        "&nidNo=" +
        nidNo +
        "&sDisburseDate=" +
        sDisburseDate +
        "&eDisburseDate=" +
        eDisburseDate +
        "&sApplyDate=" +
        sApplyDate +
        "&eApplyDate=" +
        eApplyDate
    );
  }

  getDpsStatementInfoBySearch(
    customername: string,
    customercode: string,
    accountNo: string,
    ProductId: number,
    typeId: number,
    mobileNo: any,
    nidNo: any,
    sApplyDate: any,
    eApplyDate: any
  ): Observable<any> {
    ProductId = 0;

    return this.http.get<any>(
      dcbsUrl +
        "/api/Customers/DpsStatementInfoBySearch?customerName=" +
        customername +
        "&customercode=" +
        customercode +
        "&accountNo=" +
        accountNo +
        "&ProductId=" +
        ProductId +
        "&typeId=" +
        typeId +
        "&mobileNo=" +
        mobileNo +
        "&nidNo=" +
        nidNo +
        "&sApplyDate=" +
        sApplyDate +
        "&eApplyDate=" +
        eApplyDate
    );
  }

  GetDpsInfoDetails(id: any): Observable<any> {
    //alert(id);
    return this.http.get(
      dcbsUrl +
        "/api/CustomerAccount/GetCustomerDPSByCustomerId?customerId=" +
        id
    );
  }

  //region
  getProfileUpdatePercentage(): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/Customers/GetCustomerProfileUpdatePercentage"
    );
  }

  //WhiteListSave
  saveWhiteListCustomerInfo(model: any) {
    return this.http.post<any>(
      dcbsUrl + "/api/CustomerAccount/SaveWhiteListCustomer",
      model
    );
  }

  SaveNewCustomerInfo(model: any) {
    return this.http.post<any>(
      dcbsUrl + "/api/CustomerAccount/SaveNewCustomer",
      model
    );
  }
  ///////////
  DeleteCustomerAllInfoByCustId(Id: any) {
    return this.http.delete(
      dcbsUrl + "/api/Customers/DeleteSingleCustomerAllInfoByCustId?Id=" + Id
    );
  }

  getParentChannellist(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Customers/GetParentChannellist");
  }

  GetCustomerForAccountOpening(
    type: string,
    searchValue: string
  ): Observable<any> {
    return this.http.get(
      cifUrl +
        "/api/GetCustomerForAccountOpening?type=" +
        type +
        "&searchValue=" +
        searchValue
    );
  }

  GetChargeMasters(typeId: number): Observable<any> {
    return this.http.get(cifUrl + "/api/GetChargeMasters?accTypeId=" + typeId);
  }

  GetChargeDetails(masterId: number): Observable<any> {
    return this.http.get(cifUrl + "/api/GetChargeDetails?masterId=" + masterId);
  }

  GenerateCustomerAccNo(): Observable<any> {
    return this.http.get<any>(cifUrl + "/api/GenerateCustomerAccNo");
  }

  OpenNewAccount(model: any) {
    console.log(model);
    return this.http.post<any>(cifUrl + "/api/OpenNewAccount", model);
  }

  GetPendingAccountOpening(): Observable<any> {
    return this.http.get(cifUrl + "/api/GetPendingAccountOpening");
  }

  GetAccountCharges(accId: number): Observable<any> {
    return this.http.get(cifUrl + "/api/GetAccountCharges?accId=" + accId);
  }
  GetCustomerAccountInfoByAccountId(accountId: number): Observable<any> {
    return this.http.get(
      cifUrl + "/api/GetCustomerAccInfoByAccountId?accountId=" + accountId
    );
  }

  RejectAccountOpeningRequest(accId: number): Observable<any> {
    return this.http.get(
      cifUrl + "/api/RejectAccountOpeningRequest?accId=" + accId
    );
  }

  ApproveAccountOpeningRequest(accId: number): Observable<any> {
    return this.http.get(
      cifUrl + "/api/ApproveAccountOpeningRequest?accId=" + accId
    );
  }

  GetRejectedAccounts(): Observable<any> {
    return this.http.get(cifUrl + "/api/GetRejectedAccounts");
  }

  GetApprovedAccounts(): Observable<any> {
    return this.http.get(cifUrl + "/api/GetApprovedAccounts");
  }

  
  getFundTransferPanddinfList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Customers/GetFundTransferTransactionPanding");
  }
  getFundTransferPanddinfListById(id: any): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/Customers/GetFundTransferTransactionPandingById/" + id
    );
  }

  
  AproveTransactionByUserRole(productId: number) {
    return this.http.post<number>(
      dcbsUrl + "/api/CashTransaction/saveApprovedTransfer",
      { id: productId } 
    );
  }

   
  RejectTransactionByUserRole(productId: number) {
    return this.http.post<number>(
      dcbsUrl + "/api/CashTransaction/RejectTransfer",
      { id: productId } 
    );
  }
  getFundTransferDataList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Customers/GetFundTransferTransactionDataList");
  }

  getDepositStatementInfoBySearch(
    customername: string,
    customercode: string,
    accountNo: string,
    ProductId: number,
    typeId: number,
    mobileNo: any,
    nidNo: any,
    sApplyDate: any,
    eApplyDate: any
  ): Observable<any> {
    ProductId = 0;

    return this.http.get<any>(
      dcbsUrl +
        "/api/Customers/DepositStatementInfoBySearch?customerName=" +
        customername +
        "&customercode=" +
        customercode +
        "&accountNo=" +
        accountNo +
        "&ProductId=" +
        ProductId +
        "&typeId=" +
        typeId +
        "&mobileNo=" +
        mobileNo +
        "&nidNo=" +
        nidNo +
        "&sApplyDate=" +
        sApplyDate +
        "&eApplyDate=" +
        eApplyDate
    );
  }
  SendDataStatementLog(
    accountNo: string,
    startDate: Date,
    EndDate: Date
  ): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "/SendDataStatementLog?accountNo=" +
        accountNo +
        "&startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  } 
  getStatementLogsList(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Customers/GetStatementLogsList");
  }

  GetBankingService(accId: number): Observable<any> {
    return this.http.get(cifUrl + "/api/GetBankingServiceList?accId=" + accId);
  }
  GetNomineeInfo(accId: number): Observable<any> {
    return this.http.get(cifUrl + "/api/GetNomineeInfoList?accId=" + accId);
  }
  GetAccountAllowedCurrency(accId: number): Observable<any> {
    return this.http.get(cifUrl + "/api/GetAccountAllowedCurrency?accId=" + accId);
  }
  GetCustomerSourceOfIncome(accId: number): Observable<any> {
    return this.http.get(cifUrl + "/api/GetCustomerSourceOfIncome?accId=" + accId);
  } 
   


  getCustomerDataInfoBySearch(
    customername: string,
    customercode: string, 
    mobileNo: any,
    nidNo: any,
    sApplyDate: any,
    eApplyDate: any
  ): Observable<any> { 

    return this.http.get<any>(
      cifUrl +
        "/api/Customers/CustomerSearch?customerName=" +
        customername +
        "&customercode=" +
        customercode + 
        "&mobileNo=" +
        mobileNo +
        "&nidNo=" +
        nidNo +
        "&sApplyDate=" +
        sApplyDate +
        "&eApplyDate=" +
        eApplyDate
    );
  }
  GetCustomerVerifiedNID(NidNo: string, DateOfBirth: string,MobileNo: string): Observable<any> {
    return this.http.get<any>(
      cifUrl +
        "/api/Customers/GetCustomerVerifiedNID?NidNo=" +
        NidNo +
        "&DateOfBirth=" +
        DateOfBirth +
        "&MobileNo=" +
        MobileNo
    );
  }

  getTempCustomerDataInfoBySearch(
    customername: string,
    customercode: string, 
    mobileNo: any,
    nidNo: any,
    sApplyDate: any,
    eApplyDate: any
  ): Observable<any> { 

    return this.http.get<any>(
      cifUrl +
        "/api/Customers/TempCustomerSearch?customerName=" +
        customername +
        "&customercode=" +
        customercode + 
        "&mobileNo=" +
        mobileNo +
        "&nidNo=" +
        nidNo +
        "&sApplyDate=" +
        sApplyDate +
        "&eApplyDate=" +
        eApplyDate
    );
  }
}
