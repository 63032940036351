import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
//const apiUrl=environment.baseUrl + '/api/Commission/'


const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class CommissionService {

  constructor(private http:HttpClient) { }

  //#region Commission
  GetAllCommissionList():Observable<any>{
  return this.http.get(dcbsUrl + '/api/Commission/GetAllCommissionList')
}
  //#region Commission
  GetAllCommissionListByProBuilderType(id:number):Observable<any>{
  return this.http.get(dcbsUrl + '/api/Commission/GetAllCommissionListByProBuilderType?id='+id)
  }

GetCommissionById(id:number){
  return this.http.get(dcbsUrl + '/api/Commission/GetCommissionById')
}

SaveCommission(model:any){
  return this.http.post(dcbsUrl + '/api/Commission/SaveCommission',model)
}

DeleteCommission(id:any){
  return this.http.delete(dcbsUrl + '/api/Commission/DeleteCommission/' + id)
}
getListWithSerchAndPagination(currentPage: any, itemsPerPage: any, searchValue: string) {
  return this.http.get(dcbsUrl + '/api/Commission/GetCommissinWithSerchAndPagination?page='+ currentPage + '&pageSize=' +itemsPerPage + '&search=' + searchValue);
}
//#endregion

}
