

<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}"
  style=" position: fixed;left: 0; right: 0;background-color: white;margin-bottom: 10%; z-index: 100;">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl"></div><span
                    class="text">Digital Banking</span>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div style="line-height: 1;margin-top: 6px;">
      <p style="font-size: 20px;font-weight: 700;color: #00ACE7;">{{orgName}}</p>
      <p style="text-align: center;" *ngIf="currentUser?.userTypeId !== 1">Business Date : <b>{{businessDate | date: dateFormat}} </b></p>
    </div>


    <div class="navbar-container">

      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">

          <li class="dropdown nav-item mr-1 no-arrow" placement="bottom-left" style="color: black;" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>

              <!-- <div *ngIf="currentUser">
                <span class="text-right mSize">{{ currentUser.userName }}</span><br>
                <span class="text-right text-muted font-small-3">{{ currentUser.fullName }}</span>
              </div> -->
              <img class="avatar" *ngIf="!profilePic" src="assets/img/gallery/11Icon.png" alt="avatar" height="35"
                width="35" />
              <img class="avatar" *ngIf="profilePic" src="data:image/png;base64,{{profilePic}}" alt="avatar" height="35"
                width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              style="width:350px; padding: 0;" ngbDropdownMenu>

              <div class="row">
                <form [formGroup]="frmGroupUser">
                  <div class="col-12">
                    <div class="card"
                      style="color: black;margin: 0 6px; padding: 0; border-left: 0;border-right: 0; margin-top: 0;margin: 2px 0;">
                      <div class="card-body row">
                        <div class="col-md-12">
                          <div class="form-group row"
                            style="display: flex; justify-content: center; align-items: center;">
                            <img class="avatar img-profile rounded-circle" *ngIf="!profilePic"
                              src="assets/img/gallery/11Icon.png" alt="avatar" height="60" width="60" />
                            <img class="avatar img-profile rounded-circle" *ngIf="profilePic"
                              src="data:image/png;base64,{{profilePic}}" alt="avatar" height="60" width="60" />
                            <div class="col-md-12 mt-2" style="text-align: center;">
                              <label><b>{{ currentUser?.userName }} </b></label>
                            </div>
                          </div>
                        </div>

                        <hr>

                        <div class="col-md-12">
                          <div class="form-group row">
                            <label for="desicode" class="col-sm-4 col-form-label" style="text-transform: capitalize; font-size: 15px;">{{ currentUser?.userTypeId=== 1 ? ' Code' : 'Employee Code' }}</label>
                            <div class="col-sm-8">
                              <p class="form-control-static m-0">: {{ currentUser?.userName }}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label" style="text-transform: capitalize; font-size: 15px;" >{{ currentUser?.userTypeId=== 1 ? ' Name' : 'Employee Name' }}</label>
                            <div class="col-sm-8 m-auto">
                              <p class="form-control-static m-0">: {{ currentUser?.fullName }}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label" style="text-transform: capitalize; font-size: 15px;">Email</label>
                            <div class="col-sm-8 m-auto">
                              <p class="form-control-static m-0">: {{ currentUser?.email }}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label" style="text-transform: capitalize; font-size: 15px;">NID No</label>
                            <div class="col-sm-8 m-auto">
                              <p class="form-control-static m-0">: {{ currentUser?.nationalIdentityNo }}</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group row">
                            <label for="sortorder" class="col-sm-4 col-form-label" style="text-transform: capitalize; font-size: 15px;">User Type</label>
                            <div class="col-sm-8 m-auto">
                              <p class="form-control-static m-0">: {{ currentUser?.userType?.typeName }}</p>
                            </div>
                          </div>
                        </div>

                        <hr>
                        <div class="col-md-12">
                          <div class="form-group row">
                            <a class="dropdown-item" style="background-color: white;" (click)="openChangepassword(resetcontent)">
                              <div class="d-flex align-items-center btn btn-primary"
                                style=" background-image: linear-gradient(#b4e092, #008641);color:white;font-size: 13px; width: 175px !important; margin-left: 50px; border-radius: 30px; margin-bottom: -10px; ">
                                <i class="ft-power fa-sm fa-fw mr-2"></i><span>Change password</span>
                              </div>
                            </a>
                            <!-- <div class="dropdown-divider"></div> -->
                            <a class="mt-2" (click)="logout();">
                              <div class="btn btn-primary"
                                style="background-image: linear-gradient(#b4e092, #008641);color:white;font-size: 13px; width: 175px !important; margin-left: 72px; border-radius: 30px;">
                                <i class="mr-2"></i><span>Logout</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

</nav>


<ng-template #resetcontent let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form [formGroup]="formGroupReset">
        <div class="modal-header">
          <h4 class="modal-title">Change Password</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="desicode" class="col-sm-4 col-form-label">User Name</label>
                <div class="col-sm-8">
                  <input style="font-size: 16px; font-weight: bold;" type="text" class="form-control" formControlName="userName" readonly>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Current Password</label>
                <div class="col-sm-8">
                  <input style="font-size: 18px; font-weight: bold;" type="password" class="form-control" formControlName="currentpassword">
                  <div *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</div>

                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">New Password</label>
                <div class="col-sm-8">
                  <input style="font-size: 18px; font-weight: bold;" type="password" (keyup)="ValidatePassword($event.target.value)" class="form-control" formControlName="password">

                  <ul>
                    <li *ngFor="let item of passwordValidate" class="text-danger" style="font-size: 12px; font-weight: bold;">{{ item }}</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Confirm Password</label>
                <div class="col-sm-8">
                  <input style="font-size: 18px; font-weight: bold;" type="password" class="form-control" formControlName="confirmPassword">
                </div>
              </div>
            </div>





          </div>


        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="ChangePasswordSubmit()">Submit</button>

          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
      </form>

    </div>
  </div>
</ng-template>


<ng-template #content let-c="close" let-d="dismiss">

  <div class="modal-content" style="width: 100%;">

    <form [formGroup]="frmGroupUser">
      <div class="modal-header">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="form-group row mx-auto" style="display: flex; justify-content: center; align-items: center;">
            <img class="avatar img-profile rounded-circle" *ngIf="!profilePic" src="assets/img/gallery/11Icon.png"
              alt="avatar" height="100" width="100" />
            <img class="avatar img-profile rounded-circle" *ngIf="profilePic" src="data:image/png;base64,{{profilePic}}"
              alt="avatar" height="100" width="100" />
            <div class="col-md-12 mt-2" style="text-align: center;">
              <label><b>{{ currentUser.userName }} </b></label>
            </div>
          </div>
        </div>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div class="modal-body" style="width: 100%;">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <label for="desicode" class="col-sm-6 col-form-label">Employee Code</label>
              <div class="col-sm-6 m-auto">
                <p class="form-control-static m-0">: {{ currentUser.userName }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group row">
              <label for="name" class="col-sm-6 col-form-label">Gender</label>
              <div class="col-sm-6 m-auto">
                <p class="form-control-static m-0">: {{ currentUser.gender.name }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group row">
              <label for="name" class="col-sm-6 col-form-label">Employee Name</label>
              <div class="col-sm-6 m-auto">
                <p class="form-control-static m-0">: {{ currentUser.fullName }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group row">
              <label for="sortorder" class="col-sm-6 col-form-label">Citizenship</label>
              <div class="col-sm-6 m-auto">
                <p class="form-control-static m-0">: {{ currentUser.citizenship }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group row">
              <label for="name" class="col-sm-6 col-form-label">Email</label>
              <div class="col-sm-6 m-auto">
                <p class="form-control-static m-0">: {{ currentUser.email }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group row">
              <label for="name" class="col-sm-6 col-form-label">NID No</label>
              <div class="col-sm-6 m-auto">
                <p class="form-control-static m-0">: {{ currentUser.nationalIdentityNo }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group row">
              <label for="sortorder" class="col-sm-6 col-form-label">User Type</label>
              <div class="col-sm-6 m-auto">
                <p class="form-control-static m-0">: {{ currentUser.userType.typeName }}</p>
              </div>
            </div>
          </div>

        </div>
        <div class="row">
          <button class="btn btn-primary" (click)="openChangepassword(resetcontent)">
            <div class="d-flex align-items-center">
              <i class="ft-power mr-2"></i><span>Change password</span>
            </div>
          </button>
        </div>
        <div class="row">
          <button class="btn btn-danger" (click)="logout();">
            <div class="d-flex align-items-center">
              <i class="ft-power mr-2"></i><span>Logout</span>
            </div>
          </button>
        </div>

      </div>
    </form>
  </div>

</ng-template>
<!--
<ng-template #contenttt let-c="close" let-d="dismiss" style="width: 100%;">
  <div class="modal-dialog  modal-lg">
    <div class="modal-content">
      <div class="col-12">
        <div class="form-group row" style="display: flex; justify-content: end; margin-right: 10px;">
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="frmGroupUser">
          <div class="row mt-2">

            <div class="col-md-4">

              <div class="form-group row" style="display: flex; justify-content: center; align-items: center;">
                <img class="avatar img-profile rounded-circle" *ngIf="!profilePic" src="assets/img/gallery/11Icon.png"
                  alt="avatar" height="100" width="100" />
                <img class="avatar img-profile rounded-circle" *ngIf="profilePic"
                  src="data:image/png;base64,{{profilePic}}" alt="avatar" height="100" width="100" />
                <div class="col-md-12 mt-2" style="text-align: center;">
                  <label><b>{{ currentUser.userName }} </b></label>
                </div>
              </div>

            </div>
            <div class="col-md-8">

              <div class="row mb-3">
                <h3 class="" style="margin-left: 190px;" ><b>Profile</b></h3>
              </div>

              <div class="form-group row">
                <label for="desicode" class="col-sm-6 col-form-label">Employee Code</label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.userName }}</p>
                </div>
              </div>
              <hr>

              <div class="form-group row">
                <label for="name" class="col-sm-6 col-form-label">Employee Name</label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.fullName }}</p>
                </div>
              </div>
              <hr>

              <div class="form-group row">
                <label for="name" class="col-sm-6 col-form-label">Email </label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.email }}</p>
                </div>
              </div>
              <hr>

              <div class="form-group row">
                <label for="name" class="col-sm-6 col-form-label">NID No</label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.nationalIdentityNo }}</p>
                </div>
              </div>
              <hr>

              <div class="form-group row">
                <label for="sortorder" class="col-sm-6 col-form-label">Status</label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.isActive===1?'Active':'Inactive' }}</p>
                </div>
              </div>
              <hr>

              <div class="form-group row">
                <label for="sortorder" class="col-sm-6 col-form-label">User Type</label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.userType.typeName }}</p>
                </div>
              </div>
              <hr>




            </div>
            <div class="row  mx-auto">
              <button class="btn btn-primary m-2" (click)="openChangepassword(resetcontent)">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Change password</span>
                </div>
              </button>
              <button class="btn btn-danger m-2" (click)="logout();">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template> -->


<!-- <ng-template #contentttt let-c="close" let-d="dismiss">
  <div class="modal-dialog  modal-lg">
    <div class="modal-content">
      <div class="col-md-6">
        <div class="form-group row" style="display: flex; justify-content: end; margin-right: 10px;">
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="frmGroupUser">
          <div class="row mt-2">

            <div class="col-md-12">

              <div class="form-group row" style="display: flex; justify-content: center; align-items: center;">
                <img class="avatar img-profile rounded-circle" *ngIf="!profilePic" src="assets/img/gallery/11Icon.png"
                  alt="avatar" height="100" width="100" />
                <img class="avatar img-profile rounded-circle" *ngIf="profilePic"
                  src="data:image/png;base64,{{profilePic}}" alt="avatar" height="100" width="100" />
                <div class="col-md-12 mt-2" style="text-align: center;">
                  <label><b>{{ currentUser.userName }} </b></label>
                </div>
              </div>

            </div>
            <div class="col-md-12">

              <div class="form-group row">
                <label for="desicode" class="col-sm-6 col-form-label">Employee Code</label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.userName }}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="name" class="col-sm-6 col-form-label">Employee Name</label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.fullName }}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="name" class="col-sm-6 col-form-label">Email </label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.email }}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="name" class="col-sm-6 col-form-label">NID No</label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.nationalIdentityNo }}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="sortorder" class="col-sm-6 col-form-label">Status</label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.isActive===1?'Active':'Inactive' }}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="sortorder" class="col-sm-6 col-form-label">User Type</label>
                <div class="col-sm-6 m-auto">
                  <p class="form-control-static m-0">: {{ currentUser.userType.typeName }}</p>
                </div>
              </div>

            </div>
            <div class="row  mx-auto">
              <button class="btn btn-primary m-2" (click)="openChangepassword(resetcontent)">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Change password</span>
                </div>
              </button>
              <button class="btn btn-danger m-2" (click)="logout();">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template> -->
