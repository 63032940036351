export class CashPosting {
    id: number;
    transactionType?: number; // FundTransfer=1, Payment=2, Received=3
    transactionDate?: Date;
    customerId?: number;
    customerCode?: string;
    customerAccountId?: number;
    customerAccountId2?: number;
    amount?: number;
    reverseCashTransId?: number;
    currencyId?: number;
    bankInfoId?: number;
    voucherNo?: string;
    refNo?: string;
    voucherDate?: Date;
    chequeDate?: Date;
    chequeNumber?: string;
    voucherTypeId?: number;
    remarks?: string;
    voucherAmount?: number;
    isPosted?: number;
    fiscalYearId?: number;
    taxYearId?: number;
    postingType?: string; // Auto/Manual
    narration?: string;
    cashTransactionId?: number;
    voucherId?: number;
    ledgerRelationId: number;
    transactionTypeId: number;
    gLGenerateId:any;
    businessDateId:any;
    glAccountName:any;
    leadId:any;
    collectionAmount:any;
    MobileNo: any;
    AccountName: string;
    BillNo:any;
    operatorCode?: string;
    BillPayDate?: Date;
    monthId?:number;
    chequeNo: string;
    exchangeAmount?: number;
}
