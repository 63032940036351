import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  constructor(private http: HttpClient) { }
  private toggleSidebar = new Subject<boolean>(); // small screen
  private overlaySidebarToggle = new Subject<boolean>();
  private toggleNotiSidebar = new Subject<boolean>();


  // Observable
  toggleSidebar$ = this.toggleSidebar.asObservable();
  overlaySidebarToggle$ = this.overlaySidebarToggle.asObservable();
  toggleNotiSidebar$ = this.toggleNotiSidebar.asObservable();

  toggleSidebarSmallScreen(toggle: boolean) {
    this.toggleSidebar.next(toggle);
  }

  overlaySidebartoggle(toggle: boolean) {
    this.overlaySidebarToggle.next(toggle);
  }

  toggleNotificationSidebar(toggle: boolean) {
    this.toggleNotiSidebar.next(toggle);
  }

  getCurrentUserName(): Observable<any> {
    return this.http.get<any>(dcbsUrl + '/api/Auth/current-username');
  }
  getTempCustomerByDobAndNid(dob: string, nid: string): Observable<any> {
    return this.http.get(`${cifUrl}/api/Customers/GetTempCustomerBynidanddob?nid=${nid}&dob=${dob}`);
  }
  

  getCustomerListForAdmin(id:number):Observable<any>{
    return this.http.get(dcbsUrl + '/api/Auth/GetCurrentUserNameByCustomerId?customerId=' + id);
  }

  CurrentUserInfo(): Observable<any> {
    return this.http.get<any>(dcbsUrl + '/api/Auth/CurrentUsernameInfo');
  }

  GetCustomerDetails(id: any){
    return this.http.get(dcbsUrl + '/api/Customers/GetCustomerDetails?id='+ id );
  }

  getlist(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/Customers/GetCustomerList');
  }

  getContactlist(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/LoanApplication/GetContactList');
  }

  getAddressList(){
    return this.http.get(dcbsUrl + '/api/LoanRegisters/GetAddressDetails');
  }

  getCurrentBusinessDate(): Observable<any> {
    return this.http.get(dcbsUrl + '/api/Ledger/GetLastActiveBusinessDate');
  }

}
