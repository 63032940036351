import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerAccountComponent } from './customer-account/customer-account.component';
import { RouterModule, Routes } from '@angular/router';
import { AccountDetailComponent } from './account-detail/account-detail.component';
import { CustomerAccountListComponent } from './customer-account-list/customer-account-list.component';
import { AdminCustomerAccountComponent } from './admin-customer-account/admin-customer-account.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { CustomerAccountDetailsComponent } from './customer-account-details/customer-account-details.component';
import { SavingAccountDetailsComponent } from './saving-account-details/saving-account-details.component';
import { CustomerChargeComponent } from './customer-charge/customer-charge.component';

const routes: Routes = [
  {
    path: "customeraccount",
    component: CustomerAccountComponent,
    data: {
      title: "Customer Account",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "customerAcclist",
    component: CustomerAccountListComponent,
    data: {
      title: "Customer List",
    },
  },
  {
    path: "accountDetail/:acc_id",
    component: AccountDetailComponent,
    data: {
      title: "Account Detail",
    },
  },
  {
    path: "admin-customer-account",
    component: AdminCustomerAccountComponent,
    data: {
      title: "admin-customer-account",
    },
  },
  {
    path: "customer-account-details",
    component: CustomerAccountDetailsComponent,
    data: {
      title: "customer-account-details",
    },
  },

  {
    path: "saving-account-details/:accId",
    component: SavingAccountDetailsComponent,
    data: {
      title: "saving-account-details",
    },
  },

  {
    path: "customer-charge",
    component: CustomerChargeComponent,
    data: {
      title: "customer-charge",
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerAccountRoutingModule { }
