import { HttpClient, HttpRequest, HttpEvent  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { of, Observable,  } from 'rxjs';

import { ApiResponse } from './response';
import { observable } from 'core-js/fn/symbol';

const apiUrl = environment.baseUrl;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  sendOTPForForgotPassWord(mobileNo: string, emailAddress: string, otyTypeId: number, otpCarrierId: number): Observable<ApiResponse> {
    //const url = `${dcbsUrl }`;
    const url = dcbsUrl  + '/api/Account/ekyc/SendOtpForForgot?mobileNo=' + mobileNo + '&emailAddress=' + emailAddress + '&otyTypeId=' + otyTypeId + '&otpCarrierId=' + otpCarrierId;
    return this.http.post<any>(url, null);
  }



  sendOTP(mobileNo: string, emailAddress: string, otyTypeId: number, otpCarrierId: number): Observable<ApiResponse> {
    //const url = `${dcbsUrl }`;
    const url = dcbsUrl  + '/api/Account/ekyc/sendtoken?mobileNo=' + mobileNo + '&emailAddress=' + emailAddress + '&otyTypeId=' + otyTypeId + '&otpCarrierId=' + otpCarrierId;
    return this.http.post<any>(url, null);
  }

  verifyOTP(mobileNo: string, emailAddress: string, otyTypeId: number, passcode: string): Observable<ApiResponse> {
    //const url = `${dcbsUrl }`;
    const url = dcbsUrl  + '/api/Account/ekyc/verifytoken?mobileNo=' + mobileNo + '&emailAddress=' + emailAddress + '&otyTypeId=' + otyTypeId + '&passcode=' + passcode;
    return this.http.post<any>(url, null);
  }

  // verifyNID(nid: string, dob: string): Observable<ApiResponse> {
  //   //const url = `${dcbsUrl }`;
  //   const url = dcbsUrl  + '/api/Account/ekyc/verifynid?nid=' + nid + '&dob=' + dob;
  //   return this.http.post<any>(url, null);
  // }

  verifyNID(model: any): Observable<ApiResponse> {

    //const url = `${dcbsUrl }`;
    //const url = dcbsUrl  + '/api/Account/ekyc/verifynid';
    const url = cifUrl  + '/ekyc/verifynid';
    return this.http.post<any>(url, model);
  }
  FinalApproveByCustomerId(id: number): Observable<any> {
    return this.http.get(
      cifUrl+ "/api/Customers/FinalApproveByCustomerId?id=" + id,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }

  // UpdateverifyNID(model: any): Observable<ApiResponse> {
  //   //const url = `${dcbsUrl }`;
  //   const url = dcbsUrl  + '/api/Account/ekyc/UpdateVerifyNIDData';
  //   return this.http.post<any>(url, model);
  // }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${dcbsUrl }/upload`, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${dcbsUrl }/files`);
  }

  logUser(browser: string, ip:string): Observable<any> {
    return this.http.get(`${dcbsUrl }/api/LogUser?browser=${browser}&ip=${ip}`);
  }

  changePassword(mobileNo: string, password1: string, password2: string): Observable<ApiResponse> {
    //const url = `${dcbsUrl }`;

    const url = dcbsUrl  + '/api/Auth/ekyc/SetNewPassword?mobileNo=' + mobileNo + '&password1=' + password1 + '&password2=' + password2;
    return this.http.post<any>(url, null);
  }

  checkCustomerBymobile(mobileNo: string): Observable<any> {

    return this.http.get(dcbsUrl  + '/api/CustomerAccount/CheckWhiteListByMobileNo?MobileNo=' + mobileNo);
  }




  SaveAutoCutomerFromWhiteList(mobileNo: string): Observable<any> {
    sessionStorage.removeItem('token');

    return this.http.get<any>(dcbsUrl  + '/api/CustomerAccount/SaveWhiteListCustomer?MobileNo=' + mobileNo);
  }

  verifyOTPAuth(mobileNo: string, emailAddress: string, otyTypeId: number, passcode: string): Observable<ApiResponse> {
    //const url = `${dcbsUrl }`;
    const url = dcbsUrl  + '/api/Account/ekyc/VerifyOtpAuth?mobileNo=' + mobileNo + '&emailAddress=' + emailAddress + '&otyTypeId=' + otyTypeId + '&passcode=' + passcode;
    return this.http.post<any>(url, null);
  }

  getOTPcode(mobileNo: string): Observable<any> {  
    return this.http.get<any>(cifUrl  + '/ekyc/getOTPcode?MobileNo=' + mobileNo); 
     
  }
  getNidNumber(mobileNo: string): Observable<any> {  
    return this.http.get<any>(cifUrl  + '/ekyc/getNidNumber?MobileNo=' + mobileNo); 
     
  }

  UploadNIDDocument(model: any): Observable<any> {
    const url = cifUrl + '/api/Customers/UploadNIDDocument';
    return this.http.post<any>(url, model);
  }


  

}


    // sendOTP(data:any): Observable<any> {
    // const url = `${dcbsUrl }`;
    // return this.http.post<any>(url, data);
    // }
