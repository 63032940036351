import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

// const apiUrl = environment.dcbsApi + '/api/Dashboard/';
// const apiUrl2  = environment.dcbsApi + '/api/CustomerAccount/';
// const apiLoan = environment.dcbsApi + '/api/LoanDisbursement/';

const apiUrl = environment.dcbsApi;
const dcbsUrl = environment.dcbsApi;
const cifUrl = environment.cifApi;
const authUrl = environment.authApi;

@Injectable({
  providedIn: "root",
})
export class DashboardCustomService {
  constructor(private http: HttpClient) {}

  getCountLoanInfo(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetTotalLoanInfoForClientDash");
  }

  // getCustomerAccountByCustomerId(): Observable<any>{
  //   return this.http.get(dcbsUrl+ 'GetAllCustomerAccountByCustomerId');
  // }

  getCustomerAccountByCustomerId(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CustomerAccount/GetustomerAccountBalanceByCustomerId");
  }

  getcCustomerDPSByDate(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    tenureId: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "GetCustomersAllDPSByDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&tenureId=" +
        tenureId +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  getCustomerAccountLoan(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CustomerAccount/GetCustomerAccountLoan");
  }

  getCustomerLoanAccountInfo(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CustomerAccount/GetCustomerAccountLoanForWeb");
  }

  getLoanCollection(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/LoanDisbursement/GetLoanCollectionByCustomerId");
  }

  getAllRole(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetAllRoleList");
  }

  getUserRole(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetAllUserRoleByUser");
  }

  getLoanLeadInfoForAdmin(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetLeadGenerationListForAdminDash");
  }

  getAppliedLoanLeadInfoForDisburse(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetAppliedLeadListForAdminDash");
  }

  getAppliedLoanLeadInfoForDisburseBYDate(
    startDate: any,
    EndDate: any
  ): Observable<any> {
    return this.http.get(
      apiUrl +
        "/api/Dashboard/GetAppliedLeadListForAdminDashByDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  GetLoanInfoForAdminDeshBoard(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetLoanInfoForAdminDeshBoard");
  }

  GetCashFlowAllForAdminDashBoard(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetCashFlowAllForAdminDashBoard");
  }

  GetMonthlyCashGoingandOut(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetMonthlyCashGoingandOut");
  }

  GetMonthlyProfitandLoss(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetMonthlyProfitandLoss");
  }

  GetAccountCountAdminDeshBoardOther(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetCustomerAccountForAdminDeshBoard");
  }

  GetAccountCountAdminDeshBoard(parentChannelId: any): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/Dashboard/GetCustomerAccountForAdminDeshBoard?parentChannelId=" +
        parentChannelId
    );
  }
  getDisbursLoan(status: number): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/LoanApplication/GetLeadGenerationByStatus?status=" +
        status
    );
  }
  getApprovedDisbursLoan(status: number): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/LoanApplication/GetLeadGenerationByStatus?status=" +
        status
    );
  }
  getFinalDisbursLoan(status: number): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/LoanApplication/GetLeadGenerationByStatus?status=" +
        status
    );
  }

  getFinalDisbursLoanByDate(startDate: any, EndDate: any): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/LoanApplication/GetFinalDisbursListLoanByDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  GetTodayCollectionList(): Observable<any> {
    return this.http.get(
      environment.dcbsApi + "/api/Dashboard/GetTodayCollectionList"
    );
  }

  getTodayDPSReceivableList(): Observable<any> {
    return this.http.get(
      environment.dcbsApi + "/api/Dashboard/GetTodayDPSReceivableList"
    );
  }

  TodayDPSReceivableList(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/Dashboard/GetTodayDPSReceivableList?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  TodayReceivedList(): Observable<any> {
    return this.http.get(
      environment.dcbsApi + "/api/Dashboard//api/Dashboard/GetTodayReceivedList"
    );
  }

  TodaySettlementList(): Observable<any> {
    return this.http.get(
      environment.dcbsApi + "/api/Dashboard//api/Dashboard/GetTodaySettlementList"
    );
  }

  AdvanceCollectionList(): Observable<any> {
    return this.http.get(
      environment.dcbsApi + "/api/Dashboard/GetAdvanceCollectionList"
    );
  }

  disbursLoan(id: number): Observable<any> {
    return this.http.get(
      environment.dcbsApi + "/api/LoanDisbursement/DisbursLoan/" + id
    );
  }

  disbursLoanByLeadCode(
    leadCode: number,
    cibSubjectCode: any
  ): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/LoanDisbursement/DisbursLoanByLeadCode/" +
        leadCode +
        "/" +
        cibSubjectCode
    );
  }

  getLeadStatusBYLeadCode(leadCode: number): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/LoanDisbursement/GetLeadInfoStatusCheckByLeadCode/" +
        leadCode
    );
  }

  /////
  RejectLoan(id: number): Observable<any> {
    return this.http.get(
      environment.dcbsApi + "/api/LoanDisbursement/RejectLoanByLeadId/" + id
    );
  }

  FinalDisbursLoan(id: number): Observable<any> {
    return this.http.get(
      environment.dcbsApi + "/api/LoanDisbursement/FinalDisbursLoan/" + id
    );
  }

  getListForCustomerDashboard(type: number): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/CustomerDashboardList/" + type);
  }
  getLoanCollectionByLeadId(type: number): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetLoanCollectionByLeadId/" + type);
  }
  getCustomerAccount(): Observable<any> {
    return this.http.get(
      environment.dcbsApi + "/api/LoanDisbursement/GetCustomerAccountByLeadId"
    );
  }

  GetOutStadingByLeadId(leadId: any) {
    return this.http.get(
      environment.dcbsApi +
        "/api/LoanDisbursement/GetOutStadingByLeadId/" +
        leadId
    );
  }

  GetSetteletementOutStandingByLeadId(leadId: any) {
    return this.http.get(
      environment.dcbsApi +
        "/api/LoanDisbursement/GetLoanSettleOutStadingByLeadId/" +
        leadId
    );
  }

  GetOutStadingCollectionByLeadId(leadId: any) {
    return this.http.get(
      environment.dcbsApi +
        "/api/LoanDisbursement/GetOutStadingCollectionByLeadId/" +
        leadId
    );
  }

  getClProcessListByClTypeWise(): Observable<any> {
    return this.http.get(
      environment.dcbsApi + "/api/Dashboard/api/COBProcess/GetCobLogHistoryByCLtype"
    );
  }

  getClProcessListByClTypeWiseByDate(
    startDate: any,
    EndDate: any
  ): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/Dashboard/api/COBProcess/GetCobLogHistoryByCLtypeByDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  getAllLoanStatus(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/GetAllLoanStatus");
  }

  getLoanListForTS(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/Dashboard/CustomerLoanList");
  }

  GetAllDPSEncashment(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CustomerAccount/GetAllDPSEncashment");
  }

  GetAllDPSEncashmentBySearch(
    accountNo: string,
    accountName: string,
    encashmentDate: any
  ): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "GetAllDPSEncashmentBySearch?accountName=" +
        accountName +
        "&accountNo=" +
        accountNo +
        "&encashmentDate=" +
        encashmentDate
    );
  }
  GetAlldormantAccount(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CustomerAccount/GetAlldormantAccount");
  }

  GetAlldormantAccountBySearch(
    accountNo: string,
    accountName: string,
    dormantDate: any
  ): Observable<any> {
    return this.http.get<any>(
      dcbsUrl +
        "GetAlldormantAccountBySearch?accountName=" +
        accountName +
        "&accountNo=" +
        accountNo +
        "&dormantDate=" +
        dormantDate
    );
  }

  GetAllDPSEncashmentByBusinessDate(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CustomerAccount/GetAllDPSEncashmentByBusinessDate");
  }

  GetAllDPSEncashmentAlertByBusinessDate(
    startDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/CustomerAccount/GetAllDPSEncashmentAlertByBusinessDate?startDate=" +
        startDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  EarlyEncashmentByBusinessDate(
    startDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: number
  ): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "GetAllEarlyEncashment?startDate=" +
        startDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  GetAllDPSMissedPaymentByBusinessDate(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId:any
  ): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "GetAllDPSMissedPaymentByBusinessDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  GetAllDPSCloseAccountsByBusinessDate(): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CustomerAccount/GetAllDPSCloseAccountsByBusinessDate");
  }
  ApproveDPSEncashment(id: number): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CustomerAccount/ApproveDPSEncashment?id=" + id);
  }
  ApproveDormantAccount(id: number): Observable<any> {
    return this.http.get(dcbsUrl + "/api/CustomerAccount/ApproveDormantAccount?id=" + id);
  }
  ApproveLeadByUnderwriting(leadId: number): Observable<any> {
    return this.http.get(
      environment.dcbsApi + "/api/ApproveLeadByUnderwriting?leadId=" + leadId
    );
  }

  GetDesignationwiseCustomeraccoount(parentChannelId: number): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "/api/CustomerAccount/GetDesignationwiseCustomeraccoount?parentChannelId=" +
        parentChannelId
    );
  }

  GetTenureCount(parentChannelId: number): Observable<any> {
    return this.http.get(
      dcbsUrl + "/api/CustomerAccount/GetTenureCount?parentChannelId=" + parentChannelId
    );
  }

  GetAllTenureAccountCount(parentChannelId: number): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/Dashboard/GetTenureWiseAccCountSumForAdminDash?parentChannelId=" +
        parentChannelId
    );
  }

  GetChannelAccountCount(parentChannelId: number): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/Dashboard/GetChannelWiseAccCountSumForAdminDash?parentChannelId=" +
        parentChannelId
    );
  }

  GetSevendaysPayReceivableAmountForAdminDash(
    parentChannelId: number
  ): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/Dashboard/SevendaysPayableReceivableAmountForAdminDash?parentChannelId=" +
        parentChannelId
    );
  }

  GetSevendaysOpenCloseAmountForAdminDash(
    parentChannelId: number
  ): Observable<any> {
    return this.http.get(
      environment.dcbsApi +
        "/api/Dashboard/SevendaysOpeningClosingAmountForAdminDash?parentChannelId=" +
        parentChannelId
    );
  }

  GetAllPayableDPSByTodayBusinessDate(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "GetTodaysPayableDPSbyBusinessDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  TotalPayableDPSByTodayBusinessDate(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "GetTotalPayableDPSbyDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  DPSCloseAccountsByBusinessDate(
    startDate: any,
    EndDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any,
    parentChannelId: any
  ): Observable<any> {
    return this.http.get(
      dcbsUrl +
        "GetAllDPSCloseAccountsByBusinessDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo +
        "&parentChannelId=" +
        parentChannelId
    );
  }

  private parentChannelIdSource = new BehaviorSubject<number>(100);
  parentChannelId$ = this.parentChannelIdSource.asObservable();
  setParentChannelId(id: number) {
    this.parentChannelIdSource.next(id);
  }
}
